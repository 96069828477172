import React, { useState, useEffect, useRef, useCallback } from 'react';
import words from './assets/words.json';
import { HAND_CONNECTIONS, Holistic } from '@mediapipe/holistic';
import { drawConnectors } from '@mediapipe/drawing_utils';
import {Camera} from "@mediapipe/camera_utils";
import './fingerspelling_match.css';

// Integrate ABC function from Fingerspelling.xyz
class Alphabet {
  constructor() {
      this.alphabetArray = [];
      this._helpTexts = { straight: 'Straigthen it', bend: 'Bend it' };
      this.getLetter = () => { };
      this.getRandomLetter = () => {
          var getRandom = Math.floor(Math.random() * this.alphabetArray.length);
          return this.alphabetArray[getRandom];
      };
      this.getSpecificLetter = findLetter => {
          var length = this.alphabetArray.length;
          for (var i = 0; i < length; i++) {
              var currentLetter = this.alphabetArray[i];
              var getLetterName = currentLetter.letter;
              if (getLetterName.toLowerCase() === findLetter.toLowerCase()) {
                  return currentLetter;
              }
          }
      };

      const noCurl = 180;
      const almostNoCurl = 170;
      const littleCurl = 150;
      const halfCurlMin = 130;
      const fullCurlMinMuchMoreForgiving = 110;
      const fullCurlMinMoreForgiving = 90;
      const fullCurlMin = 60;
      const fullCurlMax = 0;

      //const minFacingPitch = 80;
      //const maxFacingPitch = 310;
      //const minFacingRoll = 90;
      //const maxFacingRoll = 320;
      //const minFacingYaw = 120;
      //const maxFacingYaw = 250;
       
      const a = {
          letterNumber: 1,
          letter: 'a',

          //pitch: { minValue: minFacingPitch, maxValue: maxFacingPitch },
          //roll: { minValue: minFacingRoll, maxValue: maxFacingRoll },
          //yaw: { minValue: minFacingYaw, maxValue: maxFacingYaw },

          topthumb: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: 'Straigten it - and make sure it is close to index finger' },
          index: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          middle: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          ring: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          little: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend }
      };
      const b = {
          letterNumber: 2,
          letter: 'b',
          rotation: 'up',
          topthumb: { curlMin: almostNoCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'thumbBendOverOtherFingers', percentageCorrect: 0, currentAngle: null, helpText: 'Bend it so its inside your palm' },
          index: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          middle: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          ring: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          little: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight }
      };
      const c = {
          letterNumber: 3,
          letter: 'c',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: {
              curlMin: noCurl,
              curlMax: fullCurlMax,
              special: 'none',
              percentageCorrect: 0,
              currentAngle: null,
              helpText: "Bend it - but make sure it doesn't touch your pointer finger"
          },
          index: { curlMin: halfCurlMin + 15, curlMax: 100, special: 'distanceBetweenThumbAndPointer', fingerTop: 8, percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          middle: { curlMin: halfCurlMin + 15, curlMax: 100, special: 'distanceBetweenThumbAndPointer', fingerTop: 12, percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          ring: { curlMin: halfCurlMin + 15, curlMax: 100, special: 'distanceBetweenThumbAndPointer', fingerTop: 16, percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          little: { curlMin: almostNoCurl, curlMax: 100, special: 'distanceBetweenThumbAndPointer', fingerTop: 20, percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend }
      };
      const d = {
          letterNumber: 4,
          letter: 'd',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          index: { curlMin: noCurl, curlMax: almostNoCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          middle: { curlMin: noCurl, curlMax: fullCurlMax, special: 'touchFingertoThumb',  fingerTop: 12, percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          ring: { curlMin: littleCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          little: { curlMin: littleCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend }
      };
      /*
      changed this 'thumbBendOverOtherFingersAndUnderOtherFingers' to something else
       */
      const e = {
          letterNumber: 5,
          letter: 'e',
          rotation: 'up',
          topthumb: { curlMin: littleCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: 'Bend it so its placed below the other fingers' },
          index: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          little: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend }
      };
      const f = {
          letterNumber: 6,
          letter: 'f',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: 'Pinch the thumb and pointer finger together' },
          index: { curlMin: noCurl, curlMax: fullCurlMax, special: 'touchFingertoThumb', fingerTop: 8, percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          ring: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          little: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight }
      };
      // FIXME - Need to look for rotation
      /*
      Removed from thumb:
              special: 'thumbPointerAlignOnYAxis',
       */
      const g = {
          letterNumber: 7,
          letter: 'g',
          rotation: 'side',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, percentageCorrect: 0, special: 'none', currentAngle: null, helpText: 'Make sure its straight and aligns with the pointer finger' },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'indexBaseBending', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          middle: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend }
      };
      const h = {
          letterNumber: 8,
          letter: 'h',
          rotation: 'side',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: littleCurl, special: 'indexToMiddle', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const i = {
          letterNumber: 9,
          letter: 'i',
          rotation: 'up',
          topthumb: { curlMin: littleCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      // FIXME - add motion detection on the J
      const j = {
          letterNumber: 10,
          letter: 'j',
          rotation: 'up',
          special: 'motion_j',
          topthumb: { curlMin: littleCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const k = {
          letterNumber: 11,
          letter: 'k',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'distanceBetweenIndexandMiddle', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const l = {
          letterNumber: 12,
          letter: 'l',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const m = {
          letterNumber: 13,
          letter: 'm',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'betweenRingAndLittleBase', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const n = {
          letterNumber: 14,
          letter: 'n',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'betweenMiddleAndRing', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: almostNoCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const o = {
          letterNumber: 15,
          letter: 'o',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: halfCurlMin - 30, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: halfCurlMin, curlMax: 10, special: 'pinchThumbAndPointer', fingerTop: 8, percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: halfCurlMin, curlMax: 10, special: 'pinchThumbAndPointer', fingerTop: 12, percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: halfCurlMin, curlMax: 10, special: 'pinchThumbAndPointer', fingerTop: 16, percentageCorrect: 0, currentAngle: null },
          little: { curlMin: almostNoCurl, curlMax: 10, special: 'none', fingerTop: 20, percentageCorrect: 0, currentAngle: null }
      };
      const p = {
          letterNumber: 16,
          letter: 'p',
          rotation: 'down',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'distanceBetweenIndexandMiddle', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const q = {
          letterNumber: 17,
          letter: 'q',
          rotation: 'down',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'thumbPointerAlignOnYAxis', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: 100, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const r = {
          letterNumber: 17,
          letter: 'r',
          rotation: 'up',
          topthumb: { curlMin: littleCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'crossIndexAndMiddle', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const s = {
          letterNumber: 18,
          letter: 's',
          rotation: 'up',
          topthumb: { curlMin: littleCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'betweenIndexAndMiddleLetterS', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const t = {
          letterNumber: 18,
          letter: 't',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'betweenIndexAndMiddleLetterT', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const u = {
          letterNumber: 19,
          letter: 'u',
          rotation: 'up',
          topthumb: { curlMin: littleCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'indexToMiddle', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: almostNoCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const v = {
          letterNumber: 20,
          letter: 'v',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: almostNoCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'distanceBetweenIndexandMiddle', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const w = {
          letterNumber: 21,
          letter: 'w',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'thumbBendOverOtherFingers', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'indexAndMiddleAndRingMustBeApart', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'indexAndMiddleAndRingMustBeApart', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: noCurl, curlMax: halfCurlMin, special: 'indexAndMiddleAndRingMustBeApart', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const x = {
          letterNumber: 22,
          letter: 'x',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: halfCurlMin + 20, curlMax: fullCurlMin + 20, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const y = {
          letterNumber: 23,
          letter: 'y',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: noCurl - 30, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      // FIXME : Needs a movement in a Z shape
      const z = {
          letterNumber: 24,
          letter: 'z',
          rotation: 'up',
          special: 'motion_z',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: littleCurl+10, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: littleCurl+10, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: littleCurl+10, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      // Missing z,p,q,j,h
      this.alphabetArray = [a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z];
  }
}

const Fingerspelling = () => {
  // UseStates
  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState(null);
  const [recordingStatus, setRecordingStatus] = useState('inactive');
  const [videoChunks, setVideoChunks] = useState([]);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [readyForNext, setReadyForNext] = useState(null);

  const [cnnLoaded, setCNN_Load] = useState(false);
  console.log('CNN is loaded?: '+cnnLoaded);
  console.log('Next?: '+readyForNext);

  // useRef
  const mediaRecorderRef = useRef(null);
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const initialLetter = useRef(null);
  const initialAngle = useRef(null);
  const currentLetter = useRef('a');
  const currentList = useRef([]);
  const currentAngle = useRef(null);

  // Math functions
  function subtractVectors(v1, v2) {
    return { x: v1.x - v2.x, y: v1.y - v2.y, z: v1.z - v2.z };
  }

  function crossProduct(v1, v2) {
    return {
      x: v1.y * v2.z - v1.z * v2.y,
      y: v1.z * v2.x - v1.x * v2.z,
      z: v1.x * v2.y - v1.y * v2.x
    };
  }

  function dotProduct(v1, v2) {
    return v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
  }

  function normalizeVector(v) {
    const magnitude = Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z);
    return { x: v.x / magnitude, y: v.y / magnitude, z: v.z / magnitude };
  }

  const projectVectorOntoPlane = useCallback((vector, planeNormal) => {
    const normalizedPlaneNormal = normalizeVector(planeNormal);
    const dot = dotProduct(vector, normalizedPlaneNormal);
    return subtractVectors(vector, {
      x: normalizedPlaneNormal.x * dot,
      y: normalizedPlaneNormal.y * dot,
      z: normalizedPlaneNormal.z * dot
    });
  },[]);

  const angleBetweenVectors = useCallback((v1, v2) => {
    const dot = dotProduct(v1, v2);
    const magnitudeProduct = Math.sqrt(dotProduct(v1, v1) * dotProduct(v2, v2));
    const angleRadians = Math.acos(dot / magnitudeProduct);
    return (angleRadians * 180) / Math.PI;
  },[]);

  // Check type of video recording to support
  var video_type; 

  useEffect(() => {
    const types = [
      "video/mp4",
      "video/webm"
    ];
    
    for (const type of types) {
      if(MediaRecorder.isTypeSupported(type) === true){// eslint-disable-next-line
        video_type = type;
      }
    }
  },[]);

  useEffect(() => {
    if (webcamRef.current && stream) {
      webcamRef.current.srcObject = stream;
    }
  }, [stream]);

  // Video Recording Functions
  const getCameraPermission = async() => {
    if('MediaRecorder' in window){
      try{
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true,
        });
        setPermission(true);
        setStream(streamData);
      }catch(err){
        console.log(err.message);
      }
    }else{
      console.log('The MediaRecorder API is not supported in your browser.')
    }
  }

  const startRecording = async() => {
    setRecordingStatus('recording');
    const media = new MediaRecorder(stream, {mimeType: video_type});
    mediaRecorderRef.current = media;
    mediaRecorderRef.current.start();

    let localVideoChunks = [];
    mediaRecorderRef.current.ondataavailable = (event) => {
      if(typeof event.data === 'undefined') return;
      if (event.data.size === 0) return;
      localVideoChunks.push(event.data);
    }
    setVideoChunks(localVideoChunks);
  }

  const stopRecording = () => {
    setRecordingStatus('inactive');
    mediaRecorderRef.current.stop();

    mediaRecorderRef.current.onstop = () => {
      const videoBlob = new Blob(videoChunks, { type: 'video/webm' });
      const videoUrl = URL.createObjectURL(videoBlob);
      setRecordedVideo(videoUrl);
      setVideoChunks([]);
    }
  }

  // Implement Alphabet match
  
  // Function for handpose estimation
  /*function mergeObjects(originalObj, newObj) {
    return { ...originalObj, ...newObj };
  }

  const calculateRotation = (landmarks) => {
    const wrist = landmarks[0];
    const middleBase = landmarks[9];
    const indexBase = landmarks[5];
    const pinkyBase = landmarks[17];

    const wristToMiddle= {
      x: middleBase.x - wrist.x,
      y: middleBase.y - wrist.y,
      z: middleBase.z - wrist.z
    };

    const wristToIndex = {
      x: indexBase.x - wrist.x,
      y: indexBase.y - wrist.y,
      z: indexBase.z - wrist.z
    };
    const wristToPinky = {
      x: pinkyBase.x - wrist.x,
      y: pinkyBase.y - wrist.y,
      z: pinkyBase.z - wrist.z
    };
    
    // Calculate rotation angles
    let yaw = Math.atan2(wristToMiddle.x, wristToMiddle.z);
    let pitch = Math.atan2(wristToMiddle.y, Math.sqrt(wristToMiddle.x**2 + wristToMiddle.z**2));
    let roll = Math.atan2(wristToPinky.y - wristToIndex.y, wristToPinky.x - wristToIndex.x);

    yaw = (yaw * (180 / Math.PI) + 360) % 360;
    pitch = (pitch * (180 / Math.PI) + 360) % 360;
    roll = (roll * (180 / Math.PI) + 360) % 360;
    
    return {
      yaw: yaw,
      pitch: pitch,
      roll: roll
    };
  }*/

  const calculateDistance3D = useCallback((point1, point2) => {
    
    // Euclidean distance
    const dx = point1.x - point2.x;
    const dy = point1.y - point2.y;
    const dz = point1.z - point2.z;

    return Math.sqrt(dx*dx + dy*dy + dz*dz);
  },[])
  
  // Normalize values using wrist and index base
  const normalizeDistance3D = useCallback((point1, point2, normalizedValue) => {
    return calculateDistance3D(point1,point2)/normalizedValue
  },[calculateDistance3D])

  const calculateAngle = (landmark1,landmark2,landmark3) => {
    // Convert landmarks to vectors
    const v1 = {
      x: landmark1.x - landmark2.x,
      y: landmark1.y - landmark2.y,
      z: landmark1.z - landmark2.z
    };
    const v2 = {
      x: landmark3.x - landmark2.x,
      y: landmark3.y - landmark2.y,
      z: landmark3.z - landmark2.z
    };

    // Calculate dot product
    const dotProduct = v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;

    // Calculate magnitudes
    const v1Mag = Math.sqrt(v1.x * v1.x + v1.y * v1.y + v1.z * v1.z);
    const v2Mag = Math.sqrt(v2.x * v2.x + v2.y * v2.y + v2.z * v2.z);

    // Calculate angle in radians
    const angle = Math.acos(dotProduct / (v1Mag * v2Mag));

    // Convert to degrees
    return angle * (180 / Math.PI);
  }

  const calculateFingerAngles = useCallback((landmarks) => {
    // This is a simplified example. You'd need to implement actual angle calculations
    return {
      topthumb: calculateAngle(landmarks[4], landmarks[3], landmarks[2]),
      thumb: calculateAngle(landmarks[1], landmarks[2], landmarks[3]),
      index: calculateAngle(landmarks[5], landmarks[6], landmarks[7]),
      middle: calculateAngle(landmarks[9], landmarks[10], landmarks[11]),
      ring: calculateAngle(landmarks[13], landmarks[14], landmarks[15]),
      little: calculateAngle(landmarks[17], landmarks[18], landmarks[19])
    };
  },[])

  const motion_j_angle_calculator = useCallback((landmarks) => {
    // Define palm landmarks
    const wrist = landmarks[0];
    const indexBase = landmarks[5];
    const pinkyBase = landmarks[17];

    // Create vectors to define the palm plane
    const palmVector1 = subtractVectors(indexBase, wrist);
    const palmVector2 = subtractVectors(pinkyBase, wrist);

    // Calculate the normal vector of the palm plane
    const palmNormal = crossProduct(palmVector1, palmVector2);

    // Define the vector between landmarks[9] and landmarks[5]
    const vector95 = subtractVectors(landmarks[9], landmarks[5]);

    // Project vector95 onto the palm plane
    const projectedVector = projectVectorOntoPlane(vector95, palmNormal);

    // Use palmVector1 as the reference vector
    const referenceVector = normalizeVector(palmVector1);

    // Calculate the angle between the projected vector and the reference vector
    let angle = angleBetweenVectors(projectedVector, referenceVector);

    // Determine the direction of rotation
    const crossProductResult = crossProduct(referenceVector, projectedVector);
    if (dotProduct(crossProductResult, palmNormal) < 0) {
      angle = 360 - angle;
    }

    return angle;
  },[angleBetweenVectors,projectVectorOntoPlane]);

  const motion_z_angle_calculator = useCallback((landmarks) => {
    const topIndex = landmarks[8];
    const normalized = calculateDistance3D(landmarks[0],landmarks[5])
    if(currentList.current.length === 0){
      currentList.current.push(topIndex)
    }else if(currentList.current.length === 1){
      if(Math.abs((topIndex.x - currentList.current[0].x))/normalized > 0.5){
        currentList.current.push(topIndex)
      };
    }else if(currentList.current.length === 2){
      if(
        Math.abs((topIndex.x - currentList.current[1].x))/normalized > 0.5 & 
        Math.abs((topIndex.y - currentList.current[1].y))/normalized > 0.5
      ){
        currentList.current.push(topIndex)
      }
    }else if(currentList.current.length === 3){
      if(Math.abs((topIndex.x - currentList.current[2].x))/normalized > 0.5){
        setReadyForNext(true);
        console.log('Next!')
      };
    }
  },[calculateDistance3D])

  const isMatchingLetter = useCallback((angles, letterObj, landmarks) => {
    const fingers = ['topthumb','thumb', 'index', 'middle', 'ring', 'little'].reduce((result, finger) => {
      const letterAngle = letterObj[finger];
      letterAngle.currentAngle = angles[finger];
      if(letterAngle?.special === 'none'){
        result[finger] = letterAngle.currentAngle >= letterAngle.curlMax && letterAngle.currentAngle <= letterAngle.curlMin;
      }else if(letterAngle?.special === 'distanceBetweenThumbAndPointer'){
        const normalizedValue = calculateDistance3D(landmarks[0],landmarks[5]);
        const fingerDistance = normalizeDistance3D(landmarks[4],landmarks[letterAngle.fingerTop],normalizedValue);
        result[finger] = fingerDistance > 0.4 && letterAngle.currentAngle >= letterAngle.curlMax && letterAngle.currentAngle <= letterAngle.curlMin;
      }else if(letterAngle?.special === 'pinchThumbAndPointer'){
        const normalizedValue = calculateDistance3D(landmarks[0],landmarks[5]);
        const fingerDistance = normalizeDistance3D(landmarks[4],landmarks[letterAngle.fingerTop],normalizedValue);
        result[finger] = fingerDistance < 0.2 && letterAngle.currentAngle >= letterAngle.curlMax && letterAngle.currentAngle <= letterAngle.curlMin;
      }else if(letterAngle?.special === 'touchFingertoThumb'){
        const normalizedValue = calculateDistance3D(landmarks[0],landmarks[5]);
        const fingerDistance = normalizeDistance3D(landmarks[4],landmarks[letterAngle.fingerTop],normalizedValue);
        result[finger] = fingerDistance < 0.4;
      }else if(letterAngle?.special === 'indexBaseBending'){
        result[finger] = calculateAngle(landmarks[6],landmarks[5],landmarks[0]) < 140 &&
          letterAngle.currentAngle >= letterAngle.curlMax && 
          letterAngle.currentAngle <= letterAngle.curlMin
      }else if(letterAngle?.special === 'indexToMiddle'){
        const normalizedValue = calculateDistance3D(landmarks[0],landmarks[5]);
        const fingerDistance = normalizeDistance3D(landmarks[12],landmarks[8],normalizedValue);
        result[finger] = fingerDistance < 0.3 &&
          letterAngle.currentAngle >= letterAngle.curlMax && 
          letterAngle.currentAngle <= letterAngle.curlMin
      }else if(letterAngle?.special === 'distanceBetweenIndexandMiddle'){
        const normalizedValue = calculateDistance3D(landmarks[0],landmarks[5]);
        const fingerDistance = normalizeDistance3D(landmarks[8],landmarks[12],normalizedValue);
        result[finger] = fingerDistance >= 0.25;
      }else if(letterAngle?.special === 'crossIndexAndMiddle'){
        const normalizedValue = calculateDistance3D(landmarks[0],landmarks[5]);
        const upperFingerDistance = normalizeDistance3D(landmarks[8],landmarks[12],normalizedValue);
        const lowerFingerDistance = normalizeDistance3D(landmarks[7],landmarks[11],normalizedValue);
        result[finger] = upperFingerDistance <= 0.15 || lowerFingerDistance <= 0.15;
      }else{
        result[finger] = letterAngle.currentAngle >= letterAngle.curlMax && letterAngle.currentAngle <= letterAngle.curlMin;
      }
      
      return result;
    }, {});

    if(letterObj?.special === 'motion_j'){
      if((initialAngle.current === null | initialLetter.current !== currentLetter.current)){
        if(Object.values(fingers).every(x => x === true)){
          initialLetter.current = currentLetter.current;
          initialAngle.current = motion_j_angle_calculator(landmarks);
        }
      }else{
        if(Object.values(fingers).every(x => x === true)){
          currentAngle.current = motion_j_angle_calculator(landmarks);
          setReadyForNext(currentAngle.current - initialAngle.current > 10);
        }
      }
      return(fingers);
    }else if(letterObj?.special === 'motion_z'){
      if((initialAngle.current === null | initialLetter.current !== currentLetter.current)){
        if(Object.values(fingers).every(x => x === true)){
          initialLetter.current = currentLetter.current;
          initialAngle.current = motion_z_angle_calculator(landmarks);
        }
      }else{
        if(Object.values(fingers).every(x => x === true)){
          currentAngle.current = motion_z_angle_calculator(landmarks);
          setReadyForNext(currentAngle.current - initialAngle.current > 10);
        }
      }
      return(fingers);
    }else{
      setReadyForNext(true);
      return(fingers);
    }

    //const handRotations = ['yaw'].reduce((accumulator, rotation) => {
    //  const letterRotation = letterObj[rotation];
//
    //  const currentRotation = rotations[rotation];
    //  const isWithinRange = currentRotation <= letterRotation.maxValue && 
    //                        currentRotation >= letterRotation.minValue;
    //  return accumulator && isWithinRange;
    //}, true);
  },[normalizeDistance3D,calculateDistance3D,motion_j_angle_calculator,motion_z_angle_calculator]);

  const colorWhenFalse = '#f4f9f780';
  const colorWhenTrue = '#EFCA77'
  function getColorForConnection(i, j, fingerMatches) {
    // Define color groups for different parts of the hand
    const colorGroups = fingerMatches ? {
      thumb: fingerMatches.thumb && fingerMatches.topthumb ? colorWhenTrue : colorWhenFalse,
      indexFinger: fingerMatches.index ? colorWhenTrue : colorWhenFalse,
      middleFinger: fingerMatches.middle ? colorWhenTrue : colorWhenFalse,
      ringFinger: fingerMatches.ring ? colorWhenTrue : colorWhenFalse,
      pinky: fingerMatches.little ? colorWhenTrue : colorWhenFalse,
      palm: fingerMatches.thumb ? colorWhenTrue : colorWhenFalse,
    } : {
      thumb: colorWhenFalse,
      indexFinger:  colorWhenFalse,
      middleFinger: colorWhenFalse,
      ringFinger: colorWhenFalse,
      pinky: colorWhenFalse,
      palm: colorWhenFalse,
    };
  
    // Assign colors based on the landmark indices
    if (i <= 4 || j <= 4) return colorGroups.thumb;
    if (i >= 5 && i <= 8) return colorGroups.indexFinger;
    if (i >= 9 && i <= 12) return colorGroups.middleFinger;
    if (i >= 13 && i <= 16) return colorGroups.ringFinger;
    if (i >= 17 && i <= 20) return colorGroups.pinky;
    return colorGroups.palm;
  }

  // EXPERIMENT HERE WITH ABC
  const handleTextInput = (e) => {
    const inputValue = e.target.value;
    setReadyForNext(false);
    currentList.current = [];
    if(inputValue.length === 1){
      currentLetter.current = inputValue;
    }else{
      currentLetter.current = 'a';
    }
  }

  // Handpose estimation
  const onResults = useCallback((results) => {
    const abcDetect = new Alphabet(); 
    const webcam = webcamRef.current;
    const canvas = canvasRef.current;
    if (!webcam || !canvas) return;
  
    const canvasCtx = canvas.getContext("2d");
    if (!canvasCtx) throw new Error('Could not get context');
  
    const videoWidth = webcam.videoWidth;
    const videoHeight = webcam.videoHeight;
    canvas.width = videoWidth;
    canvas.height = videoHeight;
  
    canvasCtx.save();
    canvasCtx.scale(-1, 1);
    canvasCtx.translate(-videoWidth, 0);
    canvasCtx.clearRect(0, 0, videoWidth, videoHeight);
  
    // Simplified composite operations
    canvasCtx.globalCompositeOperation = 'source-over';
    canvasCtx.fillRect(0, 0, videoWidth, videoHeight);
    canvasCtx.drawImage(results.image, 0, 0, videoWidth, videoHeight);

    canvasCtx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Adjust opacity as needed
    canvasCtx.fillRect(0, 0, videoWidth, videoHeight);

    // Apply grayscale effect
    const imageData = canvasCtx.getImageData(0, 0, videoWidth, videoHeight);
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      const avg = (data[i] * 0.299 + data[i + 1] * 0.587 + data[i + 2] * 0.114);
      data[i] = avg;     // r
      data[i + 1] = avg; // g
      data[i + 2] = avg; // b
    }
    canvasCtx.putImageData(imageData, 0, 0);
    
    const drawHand = (hand) => {
      const landmarks = results[`${hand}Landmarks`];
      if (landmarks) {
        const angles = calculateFingerAngles(landmarks);
        //const handRotation = calculateRotation(landmarks);
        const letterObject = abcDetect.getSpecificLetter(currentLetter.current);
        const fingerMatches = isMatchingLetter(angles,letterObject,landmarks);

        HAND_CONNECTIONS.forEach((connection) => {
        const [i, j] = connection;
        const color = getColorForConnection(i, j, fingerMatches);
        drawConnectors(
          canvasCtx, 
          [landmarks[i], landmarks[j]], 
          [[0, 1]],
          {color: color, lineWidth: 5}
        );
      });
      }
    };
  
    drawHand('leftHand', '#EFCA77');
    drawHand('rightHand', '#EFCA77');
  
    canvasCtx.restore();
    setCNN_Load(true);
  },[calculateFingerAngles,isMatchingLetter]);
  
  useEffect(() => {
    if(permission){
      const holistic = new Holistic({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`;
        }
      });
      
      holistic.setOptions({
        selfieMode: true,
        modelComplexity: 1,
        smoothLandmarks: true,
        enableSegmentation: true,
        smoothSegmentation: true,
        refineFaceLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
      });
      holistic.onResults(onResults);
  
      if (
        typeof webcamRef.current !== "undefined" &&
        webcamRef.current !== null
      ) {
        if (!webcamRef.current) return
        const camera = new Camera(webcamRef.current, {
          onFrame: async () => {
            if (!webcamRef.current) return
            await holistic.send({image: webcamRef.current});
          },
        });
        camera.start();
      }
    }
  }, [permission,onResults]);

  return(
    <div className = 'battleship-background'>
      <p>{words[0]}</p><br/>
      {!permission ? (
        <button onClick={getCameraPermission}>Get Camera Permission</button>
      ) : null}
      {permission && recordingStatus === "inactive" ? (
        <button onClick={startRecording} style={{padding: '20px'}}>Start Recording</button>
      ) : null}
      {recordingStatus === "recording" ? (
        <button onClick={stopRecording}>Stop Recording</button>
      ) : null}

      {permission && stream ? (
        <div>
          <div className = 'video-container'>
            <video ref={webcamRef} autoPlay muted className = 'video-style'/>
            <canvas ref = {canvasRef} className = 'canvas-style'/>          
          </div>
          <div>
            <input
              type="text"
              id="textInput"
              onChange={handleTextInput}
              placeholder="a"
            />
          </div>
        </div>
      ) : null}

      {recordedVideo ? (
        <div>
          <video src={recordedVideo} autoPlay loop muted />
          <a download href={recordedVideo}>
            Download Recording
          </a>
        </div>
      ) : null}
    </div>
  )    
}

export default Fingerspelling