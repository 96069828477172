import { useEffect, useState } from "react";
import { ERROR_MESSAGES } from './constants.js';

async function byId(devices) {
  return devices.reduce((result, { deviceId, kind, label }) => {
    if (kind === "videoinput") {
      result[deviceId] = {
        label,
        type: kind
      }
    }
    return result
  }, {})
}

async function byType(devices) {
  return devices.reduce(
    (result, { deviceId, kind, label }) => {
      if (kind === "videoinput") {
        result.video.push({ label, deviceId })
      }
      return result
    },
    {
      video: []
    }
  )
}

async function getUserPermission() {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: { facingMode: "user" },
    })
    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
    stream.getTracks().forEach(track => {
      track.stop()
    })
    return mediaDevices
  } catch (error) {
    throw new Error(ERROR_MESSAGES.USER_PERMISSION);
  }
}

export function useDeviceInitialization() {
  const [devicesByType, setDevicesByType] = useState({
    video: []
  })
  const [devicesById, setDevicesById] = useState({})
  const [initialDevices, setInitialDevices] = useState({
    video: null
  })

  // Prevent from requesting user permission more than once
  const [askPermissionCount, setAskPermission] = useState(0);
  const [permissionError, setPermissionError] = useState(null)

  const initializeDevices = async () => {
    try {
      const mediaDevices = await getUserPermission();
      const [allById, allByType] = await Promise.all([
        byId(mediaDevices),
        byType(mediaDevices)
      ])
      setDevicesById(allById)
      setDevicesByType(allByType)
      setInitialDevices({
        video: {
          deviceId: allByType.video[0].deviceId,
          label: allByType.video[0].label
        }
      })
    } catch (error) {
      setPermissionError(error);
    }
  }

  useEffect(() => {
    if(askPermissionCount === 0){
      initializeDevices();
    };
    setAskPermission(prev => prev + 1);
    // eslint-disable-next-line
  }, []);


  return { devicesByType, devicesById, initialDevices, permissionError }
}
