import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

// Function to create subdirectory for bundling videos, voice, and text
import randomstring from 'randomstring';
import sha256 from 'crypto-js/sha256.js';

//import 'font-awesome/css/font-awesome.min.css';
import './everything.css';

export default function RecordList() {
  const [approxLoc, setApproxLoc] = useState({});
  const [linkToPage, setLinkToPage] = useState('/demographics');

  const getApproxLoc = useCallback(() => {
    if(Object.entries(approxLoc).length === 0){
      fetch('https://ipapi.co/json/')
        .then((response) => response.json())
        .then((data) => {
          setApproxLoc({
            'country': data.country_name,
            'region': data.region,
            'timezone': data.timezone
          })

          if(data.country_name !== 'United States'){
            setLinkToPage('vid');
          }
        })
      }
  },[approxLoc]);

  const [touchDetect, setTouchDetect] = useState({})

  const touchDetector = () => {
      if(('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0)){
        setTouchDetect({'useTouchDevice':'Yes'});
      }else{
        setTouchDetect({'useTouchDevice':'No'});
      }
  };

  const [userID, setUserID] = useState({});

  const createUserID = () => {
    const randomStr = randomstring.generate(8); // Generate a random string with 8 characters
    const hash = sha256(randomStr);
    setUserID({'userID':randomStr + hash});
  }; 

  useEffect(() => {
    createUserID();
    getApproxLoc();
    touchDetector();
    const demographics = localStorage.getItem('demographics');
    if(demographics !== null){
      const age = JSON.parse(demographics).Age;
      if(age > 12){
        setLinkToPage('/video');
      }else if(age < 13){
        setLinkToPage('/vid');
      }
    }
  },[getApproxLoc]);

  const saveGeo = () => {
    if(localStorage.getItem('user') === null){
      localStorage.setItem('user',JSON.stringify(userID));
    }
    localStorage.setItem('geolocation',JSON.stringify(approxLoc));
    localStorage.setItem('useTouchDevice',JSON.stringify(touchDetect));
  }

 return (
  <>
    <div className = 'gally-background'>
      <div className = 'gally-container'>
        <div className = 'gally-text-container-introduction'>
          <p/>
          <main>
            <h1>
              ASL Artificial Intelligence
            </h1>
          </main>
          <p>
            Can you help us with training AI to translate from ASL to English?
          </p>
          <p/>
          <Link className = 'your-options' onClick = {saveGeo} to={linkToPage} tabIndex = '0'>
            Let's begin!
          </Link>
        </div>
      </div>
    </div>
  </>
 );
}