import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function Create() {
  const [age, setAge] = useState(0);

  const onAgeChange = e => {
    const re = /^[0-9]*$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setAge(e.target.value);
    }
  }

  const [language, setLanguage] = useState('')

  const onLanguageChange = e => {
    setLanguage(e.target.value)
  }

  const [aud_status, setAudStatus] = useState('')

  const onAuditoryChange = e => {
    setAudStatus(e.target.value)
  }

  const [vision_status, setVisStatus] = useState('')

  const onVisionChange = e => {
    setVisStatus(e.target.value)
  }

  const [gender_status, setGenderStatus] = useState('')

  const onGenderChange = e => {
    setGenderStatus(e.target.value)
  }

  const [email_address, setEmailAddress] = useState('');
  const [goToVideo,setGoToVideo] = useState(false);

  const validateEmail = (email) => { // Solved by someone in StackOverFlow
   return String(email)
     .toLowerCase()
     .match(
       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
     );
   };

 const onEmailChange = e => {
   setEmailAddress(e.target.value);
   if(Array.isArray(validateEmail(e.target.value))){
     setGoToVideo(true);
   }else{
     setGoToVideo(false);
   }
 }

  const [demographics, setDemographics] = useState({});

  useEffect(() => {
    setDemographics({
      'Age': age,
      'ASLfirst': language,
      'auditoryStatus': aud_status,
      'blindOrLowVision': vision_status,
      'gender': gender_status,
      'email': email_address
    })
  },[age,language,aud_status,vision_status,gender_status,email_address])

   // Disable the router button if mediaBlod is undefined
   const [routeStyle, setRouteStyle] = useState('prevent-button')  
   const routeButton = (e) => {
    localStorage.setItem('demographics', JSON.stringify(demographics));
    localStorage.setItem('tour',true);
    if(age === 0 | language === '' | aud_status === '' | vision_status === '' | gender_status === ''){
      e.preventDefault();
     }else{
       return true;
     }  
   };

   const emailButton = (e) => {
    localStorage.setItem('demographics', JSON.stringify(demographics));
    localStorage.setItem('tour',true);
    if(!Array.isArray(validateEmail(email_address))){
      e.preventDefault();
    }else{
      setEmailOpen(true);
    }
   };

   useEffect(() => {
    if(age === '0' | age === '' | Number(age) > 120 | language === '' | aud_status === '' | vision_status === '' | gender_status === ''){
      setRouteStyle('prevent-button');
    }else{
      setRouteStyle('gally-right-arrow-button');
    }
  },[age,language,aud_status,vision_status,gender_status]);

   // Open email menu
   const [isEmailOpen, setEmailOpen] = useState(false);
   const openEmail = (e) => {
    if(age === 0 | language === '' | aud_status === '' | vision_status === '' | gender_status === ''){
      e.preventDefault();
    }else{
      setEmailOpen(true);
    }
   }

  // This following section will display the form that takes the input from the user.
 return (
  <div className = 'gally-background'>
  <div className = 'gally-container'>
    <div className = 'gally-text-container'>
      <main>
        <h1>
          Before we begin
        </h1>
      </main>
      <p/>
      <section>
        Your demographic information helps make AI fairer.
      </section>
      <p aria-label = 'There are total 5 questions, no more than that.'/>
      <div className = 'survey-container'>
        <p aria-label = 'Question 1 has two options - Yes and No.'/>
        <form onSubmit={e => { e.preventDefault(); }}>
          <fieldset className="survey-options">
            <legend>
              Question 1: <b>What is your age (0-120)?</b>
            </legend>
            <label htmlFor="yes">
              <input 
              type="number" 
              name="options"
              min='0'
              max='120'
              value={age}
              id="age" 
              onChange = {onAgeChange}
              tabIndex = '0'
              />
            </label>
            <input type = 'submit' style = {{display: 'none'}} tabIndex = '0'/>
          </fieldset>
        </form>
      </div>
      <div className = 'survey-container'>
        <p aria-label = 'Question 2 has two options - Yes and No.'/>
        <form onSubmit={e => { e.preventDefault(); }}>
          <fieldset className="survey-options">
            <legend>
              Question 2: <b>Is American Sign Language your primary language?</b>
            </legend>
            <label htmlFor="yes">
              <input 
              type="radio" 
              name="options" 
              value="Yes" 
              id="yes" 
              checked = {language === 'Yes'}
              onChange = {onLanguageChange}
              tabIndex = '0'
              />
              Yes
            </label>
            <label htmlFor="no">
              <input 
              type="radio" 
              name="options" 
              value="No" 
              id="no" 
              checked = {language === 'No'}
              onChange = {onLanguageChange}
              tabIndex = '0'
              />
              No
            </label>
            <input type = 'submit' style = {{display: 'none'}} tabIndex = '0'/>
          </fieldset>
        </form>
      </div>
      <div className = 'survey-container'>
        <p aria-label = 'Question 3 has six options - Deaf, deaf, Hard of Hearing, CODA, Hearing, and Prefer not to say.'/>
        <form onSubmit={e => { e.preventDefault(); }}>
          <fieldset className="survey-options">
            <legend>
              Question 2: <b>Which of the following do you identify as?</b>
            </legend>
            <label htmlFor="Deaf">
              <input 
              type="radio" 
              name="options" 
              value="Deaf" 
              id="Deaf" 
              checked = {aud_status === 'Deaf'}
              onChange = {onAuditoryChange}
              tabIndex = '0'
              />
              Deaf
            </label>
            <label htmlFor="deaf">
              <input 
              type="radio" 
              name="options" 
              value="deaf" 
              id="deaf" 
              checked = {aud_status === 'deaf'}
              onChange = {onAuditoryChange}
              tabIndex = '0'
              />
              deaf
            </label>
            <label htmlFor="hardofhearing">
              <input 
              type="radio" 
              name="options" 
              value="hard of hearing" 
              id="hardofhearing" 
              checked = {aud_status === 'hard of hearing'}
              onChange = {onAuditoryChange}
              tabIndex = '0'
              />
              Hard of Hearing
            </label>
            <label htmlFor="coda">
              <input 
              type="radio" 
              name="options" 
              value="CODA" 
              id="coda" 
              checked = {aud_status === 'CODA'}
              onChange = {onAuditoryChange}
              tabIndex = '0'
              />
              CODA
            </label>
            <label htmlFor="hearing">
              <input 
              type="radio" 
              name="options" 
              value="hearing" 
              id="hearing" 
              checked = {aud_status === 'hearing'}
              onChange = {onAuditoryChange}
              tabIndex = '0'
              />
              Hearing
            </label>
            <label htmlFor="prefernottosay">
              <input 
              type="radio" 
              name="options" 
              value="prefer not to say" 
              id="prefernottosay" 
              checked = {aud_status === 'prefer not to say'}
              onChange = {onAuditoryChange}
              tabIndex = '0'
              />
              Prefer not to say
            </label>
            <input type = 'submit' style = {{display: 'none'}} tabIndex = '0'/>
          </fieldset>
        </form>
      </div>
      <div className = 'survey-container'>
        <p aria-label = 'Question 4 has three options - Yes, No, and Prefer not to say.'/>
        <form onSubmit={e => { e.preventDefault(); }}>
          <fieldset className="survey-options">
            <legend>
              Question 4: <b>Are you blind or low vision?</b>
            </legend>
            <label htmlFor="yes1">
              <input 
              type="radio" 
              name="options" 
              value="Yes" 
              id="yes1" 
              checked = {vision_status === 'Yes'}
              onChange = {onVisionChange}
              tabIndex = '0'
              />
              Yes
            </label>
            <label htmlFor="no1">
              <input 
              type="radio" 
              name="options" 
              value="No" 
              id="no1" 
              checked = {vision_status === 'No'}
              onChange = {onVisionChange}
              tabIndex = '0'
              />
              No
            </label>
            <label htmlFor="prefernottosay1">
              <input 
              type="radio" 
              name="options" 
              value="prefer not to say" 
              id="prefernottosay1" 
              checked = {vision_status === 'prefer not to say'}
              onChange = {onVisionChange}
              tabIndex = '0'
              />
              Prefer not to say
            </label>
            <input type = 'submit' style = {{display: 'none'}} tabIndex = '0'/>
          </fieldset>
        </form>
      </div>
      <div className = 'survey-container'>
        <p aria-label = 'Question 5 has six options - Female, Male, Transgender female, Transgender male, Other (with text input), and Prefer not to say.'/>
        <form onSubmit={e => { e.preventDefault(); }}>
          <fieldset className="survey-options">
            <legend>
              Question 5: <b>To which gender identity do you most identify?</b>
            </legend>
            <label htmlFor="female">
              <input 
              type="radio" 
              name="options" 
              value="female" 
              id="female" 
              checked = {gender_status === 'female'}
              onChange = {onGenderChange}
              tabIndex = '0'
              />
              Female
            </label>
            <label htmlFor="male">
              <input 
              type="radio" 
              name="options" 
              value="male" 
              id="male" 
              checked = {gender_status === 'male'}
              onChange = {onGenderChange}
              tabIndex = '0'
              />
              Male
            </label>
            <label htmlFor="transgender female">
              <input 
              type="radio" 
              name="options" 
              value="transgender female" 
              id="transgender female" 
              checked = {gender_status === 'transgender female'}
              onChange = {onGenderChange}
              tabIndex = '0'
              />
              Transgender female
            </label>
            <label htmlFor="transgender male">
              <input 
              type="radio" 
              name="options" 
              value="transgender male" 
              id="transgender male" 
              checked = {gender_status === 'transgender male'}
              onChange = {onGenderChange}
              tabIndex = '0'
              />
              Transgender male
            </label>
            <label htmlFor="other">
              <input 
              type="radio" 
              name="options" 
              value="other" 
              id="other" 
              checked = {gender_status === 'other'}
              onChange = {onGenderChange}
              tabIndex = '0'
              />
              Other: <input type = 'text' aria-label ="Please type here to describe your gender identity." tabIndex = '0' autoComplete = "sex"/>
            </label>
            <label htmlFor="prefer not to say">
              <input 
              type="radio" 
              name="options" 
              value="prefer not to say" 
              id="prefer not to say" 
              checked = {gender_status === 'prefer not to say'}
              onChange = {onGenderChange}
              tabIndex = '0'
              />
              Prefer not to say
            </label>
            <input type = 'submit' style = {{display: 'none'}} tabIndex = '0'/>
          </fieldset>
        </form>
      </div>
    </div>
    {
      (() => {
        if(Number(age) < 13){
          return(
            <Link className={routeStyle} 
              to='/vid'
              aria-disabled = {routeStyle === 'prevent-button' ? true : false}
              onClick = {routeButton} 
              tabIndex = '0'
              aria-label={"Gallaudet's Logo: Video Recording page"+(routeStyle === 'prevent-button' ? '- But this button is not working because you need to fill out demographic survey.' : ' - This button is working right now!')}  
              type="submit"
            >
              Video
            </Link>
          )
        }else{
          return(
            <button className = {routeStyle}
              aria-disabled = {routeStyle === 'prevent-button' ? true : false}
              onClick = {openEmail} 
              tabIndex = '0'
              aria-label={"Gallaudet's Logo: Video Recording page"+(routeStyle === 'prevent-button' ? '- But this button is not working because you need to fill out demographic survey.' : ' - This button is working right now!')} 
            >
              Video
            </button>
          )
        }
      })()
    }
    <Link 
      className = 'gally-left-arrow-button' 
      to={'/'} 
      tabIndex = '0'
      aria-label="Gallaudet's Logo: Home page" 
      role='button'
    >
      Back
    </Link>
  </div>
  <div className = 'email-request-container'
    aria-disabled = {isEmailOpen}
  >
    <div className = 'email-request'>
      <h3>Control your data</h3>
      <p>Mind sharing your email with us? It will only be used for data removal requests if needed.</p>
      <form onSubmit = {e => { e.preventDefault(); }}>
        <label htmlFor="email">Email address</label><br/>
        <input type="email" placeholder="Enter your email" id="email" name="email" onChange = {onEmailChange}/><br/><br/>
        <Link id = 'submit' 
          to='/video'
          aria-disabled = {!goToVideo}
          onClick = {emailButton}
          tabIndex = '0'
          aria-label={"Gallaudet's Logo: Video Recording page"+(routeStyle === 'prevent-button' ? '- But this button is not working because you need to fill out demographic survey.' : ' - This button is working right now!')}  
          type="submit"
        >
          Submit
        </Link>
        <input type = 'submit' style = {{display: 'none'}} tabIndex = '0'/>
      </form>
    </div>
  </div>
 </div>
 );
}