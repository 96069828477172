import React from "react";
import './everything.css';
 // We import NavLink to utilize the react router.
import { Link } from "react-router-dom";
import GallyLogo from './assets/Gallaudet_University_logoForFooter.svg';

 // Here, we display our Navbar
export default function Footer() {
  return (
    <div>
      <footer className="footer-background">
        <div className = 'footer-container'>
          <div className = 'gally-grid'>
            <img aria-hidden = 'true' src = {GallyLogo} alt = "Gallaudet University's logo" height = '70px'/>
            <p>The Artificial Intelligence, Accessibility and Sign Language Center (AIASL) at Gallaudet University pioneers AI integration for accessibility and sign language, building inclusive solutions for tech accessibility, especially for sign language users.</p>
          </div>
          <div className = 'gally-grid' id = 'gally-copyright'>
            <p>Copyright © {new Date().getFullYear()} Gallaudet University. All rights reserved.</p>
          </div>
          <div className = 'gally-grid' id = 'last-gally-grid' style = {{textAlign: 'right'}}>
            <p>800 Florida Avenue NE, Washington, D.C. 20002</p>
          </div>
        </div>
        <div className = 'gally-grid-links'>
          <Link 
            className = 'gally-links-p' 
            to='/accessibility' 
            aria-label='Accessibility page' 
            role='button'
          >
            Accessibility
          </Link>
          <Link 
            className = 'gally-links-p' 
            to='/cookie-consent-notice' 
            aria-label='Cookie Consent Notice page' 
            role='button'
          >
            Cookie Consent Notice
          </Link>
          <Link 
            className = 'gally-links-p' 
            to='/privacy-policy' 
            aria-label='Privacy Policy page' 
            role='button'
          >
            Privacy Policy
          </Link>
          <Link 
            className = 'gally-links-p' 
            to='/data-request' 
            aria-label='Data Request page' 
            role='button'
          >
            Data Request
          </Link>
        </div>
     </footer>
   </div>
 );
}