import React, { useState, forwardRef, useCallback, useMemo } from "react";
import GameSetUp from "./battleship_draggable.js";



// Prevent state from the value returning to null
export class PersistentString {
  constructor() {
    this._value = null;
  }

  get value() {
    return this._value;
  }

  set value(newValue) {
    if (this._value === null && typeof newValue === 'string') {
      this._value = newValue;
    }
  }
}


export const Board = ({yourTurn,isDisabled,userID,getClickedArr,getFingerspellAccess,socket}) => {
  // Get attacked spots
  const [attackSpots,setAttackSpots] = useState([]);
  let anyArr = [];

  socket.on('shipSpots', shipSpots => {
    if(shipSpots['id'] !== userID){
      setAttackSpots(shipSpots['array'])
    }
  });
  
  const arr = Array.from(Array(90).keys());
  const abc = ['A','B','C','D','E','F','G','H','I','J'];
  const num = ['',1,2,3,4,5,6,7,8,9];

  const [color_arr, setColorArr] = useState(Array(90).fill('transparent'));
  const [aria_disabled, setAriaDisabled] = useState(Array(90).fill(false));

  const torpedo = (e) => {
    if(attackSpots.includes(e+1)){
      anyArr = [...color_arr];
      anyArr[e] = 'var(--attacked)';
      getFingerspellAccess(true);
      socket.emit('click', {arr: anyArr, loseExtraTurn: false});
      socket.emit('message', {message: 'Your ship is hitted!'});
      setColorArr(anyArr);
    }else{
      anyArr = [...color_arr];
      anyArr[e] = 'var(--darkgray)';
      getFingerspellAccess(false);
      socket.emit('click', {arr: anyArr, loseExtraTurn: true});
      setColorArr(anyArr);
    }

    anyArr = [...aria_disabled];
    anyArr[e] = true;
    getClickedArr(anyArr);
    setAriaDisabled(anyArr);
  }

  return(
    <div className='board-game-container'>
      <div>
        {num.map(num => (
          <p key={num}>{num}</p>
        ))}
      </div>
      <div className = 'grid-of-game'>
        {abc.map(letter => (
          <p key={letter}>{letter}</p>
        ))}
        {arr.map(number => (
          <button 
            key={number} 
            onClick={() => torpedo(number)} 
            disabled = {aria_disabled[number] | !isDisabled | !yourTurn | attackSpots.length === 0}
            style={{background: isDisabled ? 'var(--gamebox)' : 'var(--lightgray)'}}
            className='grid-boxes'
          >
            <div style = {{ background: color_arr[number] }}/>
          </button>
        ))}
      </div>
    </div>
  )
}

export const YourBoard = forwardRef(({territory,isOverlapped,isDisabled,getAttackSpots},ref) => {
  // Parameters  
  const arr = Array.from(Array(90).keys());
  const abc = useMemo(() => ['A','B','C','D','E','F','G','H','I','J'],[]);
  const num = useMemo(() => ['',1,2,3,4,5,6,7,8,9],[]);

  const array = useMemo(() => {
    let result = [];
    for(let i = 0; i < 90; i++) {
      result.push(abc[i % 10] + num[Math.floor(i / 10) + 1]);
    }
    return result;
  }, [abc, num]);
  
  const setShips = useMemo(() => [
    { id: "cruiser", content: "A", path: 'M 3.8 0 A 0.1 0.1 0 0 0 3.8 7.5 L 13 7.5 Q 17 7.5 20 3.75 Q 17 0 13 0 Z', size: 2},
    { id: "battleship", content: "B", path: 'M 3.8 0 A 0.1 0.1 0 0 0 3.8 7.5 L 33 7.5 Q 37 7.5 40 3.75 Q 37 0 33 0 Z', size: 4},
    { id: "submarine", content: "C", path: 'M 3.8 0 A 0.1 0.1 0 0 0 3.8 7.5 L 26.2 7.5 A 0.1 0.1 0 0 0 26.2 0 Z', size: 3},
    { id: "aircraft_carrier", content: "D", path: 'M 1 0 Q 0 0 0 1 L 0 6.5 Q 0 7.5 1 7.5 L 49 7.5 Q 50 7.5 50 6.5 L 50 1 Q 50 0 49 0 Z', size: 5},
    { id: "destroyer", content: "E", path: 'M 3.8 0 A 0.1 0.1 0 0 0 3.8 7.5 L 23 7.5 Q 27 7.5 30 3.75 Q 27 0 23 0 Z', size: 3},
  ],[]);

  // Game set up
  const handleOverlapInfo = (e) => {
    isOverlapped(e);
  }

  const handleAttackSpots = useCallback((e) => {
    getAttackSpots(e)
  },[getAttackSpots]);

  return(
    <div className='your-board-game-container'>
      <div>
        {num.map(num => (
          <p key={num}>{num}</p>
        ))}
      </div>
      <div className = 'your-grid-of-game'>
        {abc.map(letter => (
          <p key={letter}>{letter}</p>
        ))}
        {arr.map(number => (
          <div key={number} className = 'grid-boxes' style = {{ background: isDisabled ? 'var(--lightgray)' : 'var(--gamebox)' }}/>
        ))}
      </div>
      <GameSetUp 
        array = {array} 
        setOFships={setShips} 
        abc={abc} 
        num={num.slice(1)} 
        overlappedEvent={handleOverlapInfo} 
        isDisabled={isDisabled} 
        getAttackSpots={handleAttackSpots}
        ref={ref}
      >
        {arr.map(number => (
          <div key={number} style = {{ background: territory[number] }} />
        ))}
      </GameSetUp>
    </div>
  )
})