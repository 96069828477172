import React, { useState, useEffect, useRef, useCallback} from 'react';

// 3D animation
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OutlineEffect } from 'three/addons/effects/OutlineEffect.js';
import TWEEN from '@tweenjs/tween.js'
import handModel from './assets/hand.glb'; 

// Look at this for toon effect: https://github.com/Kirilbt/hand-armature/blob/main/main.js


const PARAMS = {
  bg: 0xffffff,
  hand: 0xE7A183,
  shirt: 0x0F4D81,
  vest: 0xE7D55C,
  wrist: 0.1,
  thumb: 0.25,
  index: 0.25,
  middle: 1.1,
  ring: 1.1,
  pinky: 0.25,
  thumbz: -0.15, 
  indexz: -0.3,
  middlez: -0.08,
  ringz: -0.22,
  pinkyz: -0.52
}

const angle10 = -0.174533;
const angle20 = -0.349066;
const angle30 = -0.523599;
const angle40 = -0.698132;
const angle50 = -0.872665;
const angle60 = -1.0472;
const angle80 = -1.39626;
const angle90 = -1.5708;
const angle100 = -1.74533;

const handMovements = {
  'original': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": 0.3542620038040601
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -0.11164087073484776
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": -0.21490532060154555,
      "y": 0.0002568275406612122,
      "z": -0.0012364130552036617
    },
    "indexMid": {
      "x": 0.0036309183554384707,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": -0.031390302921621746,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": -0.12435494054214595,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": -0.19287115715812556,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": -0.07048834033269637,
      "y": -0.003850539761388931,
      "z": 0.06783352120216708
    },
    "ringMid": {
      "x": -0.20052864919048807,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": -0.050945657493289304,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": -0.03955273281470167,
      "y": 0.005718873752241025,
      "z": -0.03255938149984805
    },
    "littleMid": {
      "x": -0.02304039923611267,
      "y": 0.00363578315581031,
      "z": -0.08001397222649159
    },
    "littleTop": {
      "x": -0.20126179531004806,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'original1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": -0.05,
      "y": -0.05,
      "z": 0.05
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434+0.01
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": 0.3542620038040601+0.05
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -0.11164087073484776
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": -0.21490532060154555,
      "y": 0.0002568275406612122,
      "z": -0.0012364130552036617-0.05
    },
    "indexMid": {
      "x": 0.0036309183554384707,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": -0.031390302921621746,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012-0.05
    },
    "middleMid": {
      "x": -0.12435494054214595,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": -0.19287115715812556,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": -0.07048834033269637,
      "y": -0.003850539761388931,
      "z": 0.06783352120216708-0.05
    },
    "ringMid": {
      "x": -0.20052864919048807,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": -0.050945657493289304,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": -0.03955273281470167,
      "y": 0.005718873752241025,
      "z": -0.03255938149984805-0.05
    },
    "littleMid": {
      "x": -0.02304039923611267,
      "y": 0.00363578315581031,
      "z": -0.08001397222649159
    },
    "littleTop": {
      "x": -0.20126179531004806,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'hello': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": 0.3542620038040601
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -0.11164087073484776
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": -0.21490532060154555,
      "y": 0.0002568275406612122,
      "z": -0.0012364130552036617
    },
    "indexMid": {
      "x": 0.0036309183554384707,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": -0.031390302921621746,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": -0.12435494054214595,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": -0.19287115715812556,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": -0.07048834033269637,
      "y": -0.003850539761388931,
      "z": 0.06783352120216708
    },
    "ringMid": {
      "x": -0.20052864919048807,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": -0.050945657493289304,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": -0.03955273281470167,
      "y": 0.005718873752241025,
      "z": -0.03255938149984805
    },
    "littleMid": {
      "x": -0.02304039923611267,
      "y": 0.00363578315581031,
      "z": -0.08001397222649159
    },
    "littleTop": {
      "x": -0.20126179531004806,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'hello1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": angle30
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": 0.3542620038040601
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -0.11164087073484776
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": -0.21490532060154555,
      "y": 0.0002568275406612122,
      "z": -0.0012364130552036617
    },
    "indexMid": {
      "x": 0.0036309183554384707,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": -0.031390302921621746,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": -0.12435494054214595,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": -0.19287115715812556,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": -0.07048834033269637,
      "y": -0.003850539761388931,
      "z": 0.06783352120216708
    },
    "ringMid": {
      "x": -0.20052864919048807,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": -0.050945657493289304,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": -0.03955273281470167,
      "y": 0.005718873752241025,
      "z": -0.03255938149984805
    },
    "littleMid": {
      "x": -0.02304039923611267,
      "y": 0.00363578315581031,
      "z": -0.08001397222649159
    },
    "littleTop": {
      "x": -0.20126179531004806,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'hello2': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": -angle30
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": 0.3542620038040601
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -0.11164087073484776
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": -0.21490532060154555,
      "y": 0.0002568275406612122,
      "z": -0.0012364130552036617
    },
    "indexMid": {
      "x": 0.0036309183554384707,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": -0.031390302921621746,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": -0.12435494054214595,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": -0.19287115715812556,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": -0.07048834033269637,
      "y": -0.003850539761388931,
      "z": 0.06783352120216708
    },
    "ringMid": {
      "x": -0.20052864919048807,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": -0.050945657493289304,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": -0.03955273281470167,
      "y": 0.005718873752241025,
      "z": -0.03255938149984805
    },
    "littleMid": {
      "x": -0.02304039923611267,
      "y": 0.00363578315581031,
      "z": -0.08001397222649159
    },
    "littleTop": {
      "x": -0.20126179531004806,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'helloR': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle20,
      "y": 0.0002568275406612122,
      "z": -angle30
    },
    "indexMid": {
      "x": 0.0036309183554384707,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": -angle10+0.06,
      "y": -0.0008645369447903341,
      "z": angle10+0.1
    },
    "middleMid": {
      "x": angle30,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle20,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'helloR1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": angle10
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": angle10
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle20,
      "y": 0.0002568275406612122,
      "z": -angle30
    },
    "indexMid": {
      "x": 0.0036309183554384707,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": -angle10+0.06,
      "y": -0.0008645369447903341,
      "z": angle10+0.1
    },
    "middleMid": {
      "x": angle30,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle20,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'helloR2': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": -angle10
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": -angle30
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle20,
      "y": 0.0002568275406612122,
      "z": -angle30
    },
    "indexMid": {
      "x": 0.0036309183554384707,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": -angle10+0.06,
      "y": -0.0008645369447903341,
      "z": angle10+0.1
    },
    "middleMid": {
      "x": angle30,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle20,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'helloX': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle40
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle10,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle10,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": angle10,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle60,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'helloX1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle40
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle40
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle10,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle30,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": angle10,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": -angle10-0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'helloX2': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle40
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle40+0.1
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle10,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle80,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80+0.05,
      "y": -0.003850539761388931,
      "z": -angle10-0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80+0.03,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": 0.05,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'a': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": -angle40
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle80,
      "y": -0.002625303160102763,
      "z": angle10
    },
    "indexTop": {
      "x": angle80,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'a1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0.1,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": -angle40-0.05
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle80,
      "y": -0.002625303160102763,
      "z": angle10
    },
    "indexTop": {
      "x": angle80,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'b': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle40
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle80
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0,
      "z": -angle20
    },
    "indexMid": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": 0,
      "y": 0,
      "z": -angle10-0.1
    },
    "middleMid": {
      "x": -0.12435494054214595,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": -0.19287115715812556,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": 0,
      "y": 0,
      "z": angle10
    },
    "ringMid": {
      "x": -0.20052864919048807,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": -0.050945657493289304,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": -0.03955273281470167,
      "y": 0.005718873752241025,
      "z": angle30+0.05
    },
    "littleMid": {
      "x": -0.02304039923611267,
      "y": 0.00363578315581031,
      "z": -0.08001397222649159
    },
    "littleTop": {
      "x": -0.20126179531004806,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'b1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0.05,
      "y": 0,
      "z": 0.05
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle40
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle80
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0,
      "z": -angle20
    },
    "indexMid": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": 0,
      "y": 0,
      "z": -angle10-0.1
    },
    "middleMid": {
      "x": -0.12435494054214595,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": -0.19287115715812556,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": 0,
      "y": 0,
      "z": angle10
    },
    "ringMid": {
      "x": -0.20052864919048807,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": -0.050945657493289304,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": -0.03955273281470167,
      "y": 0.005718873752241025,
      "z": angle30+0.07
    },
    "littleMid": {
      "x": -0.02304039923611267,
      "y": 0.00363578315581031,
      "z": -0.08001397222649159
    },
    "littleTop": {
      "x": -0.20126179531004806,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'c': {
    "wristBase": {
      "x": 0,
      "y": angle30,
      "z": 0
    },
    "wristTop": {
      "x": angle10,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle20,
      "y": 1,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": -angle10
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle10
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle40,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle40,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle40,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle30,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": -angle10-0.05
    },
    "ringBase": {
      "x": angle40,
      "y": -0.003850539761388931,
      "z": 0.06783352120216708
    },
    "ringMid": {
      "x": angle30-0.1,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": angle40,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle10+0.17
    },
    "littleBase": {
      "x": angle40,
      "y": 0.005718873752241025,
      "z": angle10
    },
    "littleMid": {
      "x": angle40,
      "y": 0.00363578315581031,
      "z": -0.08001397222649159
    },
    "littleTop": {
      "x": angle40,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'c1': {
    "wristBase": {
      "x": 0,
      "y": angle30,
      "z": 0
    },
    "wristTop": {
      "x": angle10,
      "y": 0.1,
      "z": 0
    },
    "wristThumb": {
      "x": angle20,
      "y": 1,
      "z": -0.8638881415362434+0.05
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": -angle10
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle10
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle40,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle40,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle40+0.05,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle30,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": -angle10-0.05
    },
    "ringBase": {
      "x": angle40,
      "y": -0.003850539761388931,
      "z": 0.06783352120216708
    },
    "ringMid": {
      "x": angle30-0.1,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": angle40,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle10+0.17
    },
    "littleBase": {
      "x": angle40,
      "y": 0.005718873752241025,
      "z": angle10
    },
    "littleMid": {
      "x": angle40,
      "y": 0.00363578315581031,
      "z": -0.08001397222649159
    },
    "littleTop": {
      "x": angle40,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'd': {
    "wristBase": {
      "x": 0,
      "y": -angle10,
      "z": 0
    },
    "wristTop": {
      "x": angle10,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle20,
      "y": 1,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": -angle40-0.05
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": angle10,
      "z": -angle20
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": -angle20
    },
    "indexMid": {
      "x": 0,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.1,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle50,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle50-0.1,
      "y": angle10,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": -angle10-0.05
    },
    "ringBase": {
      "x": angle60,
      "y": -0.003850539761388931,
      "z": 0.06783352120216708
    },
    "ringMid": {
      "x": angle50,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": angle50,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle10+0.17
    },
    "littleBase": {
      "x": angle80,
      "y": 0.005718873752241025,
      "z": angle10
    },
    "littleMid": {
      "x": angle60,
      "y": 0.00363578315581031,
      "z": -angle10
    },
    "littleTop": {
      "x": angle60,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'd1': {
    "wristBase": {
      "x": 0,
      "y": -angle10,
      "z": 0
    },
    "wristTop": {
      "x": angle10+0.05,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle20,
      "y": 1,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": -angle40-0.1
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": angle10,
      "z": -angle20
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": -angle20
    },
    "indexMid": {
      "x": -0.5,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.1,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle50,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle50-0.1,
      "y": angle10,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": -angle10-0.05
    },
    "ringBase": {
      "x": angle60,
      "y": -0.003850539761388931,
      "z": 0.06783352120216708
    },
    "ringMid": {
      "x": angle50,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": angle50,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle10+0.17
    },
    "littleBase": {
      "x": angle80,
      "y": 0.005718873752241025,
      "z": angle10
    },
    "littleMid": {
      "x": angle60,
      "y": 0.00363578315581031,
      "z": -angle10
    },
    "littleTop": {
      "x": angle60,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'e': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle80
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle30,
      "y": 0.0002568275406612122,
      "z": -angle20
    },
    "indexMid": {
      "x": angle100,
      "y": -0.002625303160102763,
      "z": angle10+0.1
    },
    "indexTop": {
      "x": angle90,
      "y": -0.0033181236619804154,
      "z": 0
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle30,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle100,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle90,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle30,
      "y": -0.003850539761388931,
      "z": angle10
    },
    "ringMid": {
      "x": angle100,
      "y": 0.006173124888024202,
      "z": 0
    },
    "ringTop": {
      "x": angle90,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle20+0.02
    },
    "littleBase": {
      "x": angle40,
      "y": 0.005718873752241025,
      "z": angle20
    },
    "littleMid": {
      "x": angle100,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": angle90,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'e1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0.1,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60+0.1
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle80
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle30,
      "y": 0.0002568275406612122,
      "z": -angle20
    },
    "indexMid": {
      "x": angle100,
      "y": -0.002625303160102763,
      "z": angle10+0.1
    },
    "indexTop": {
      "x": angle90,
      "y": -0.0033181236619804154,
      "z": 0
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle30,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle100,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle90,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle30,
      "y": -0.003850539761388931,
      "z": angle10
    },
    "ringMid": {
      "x": angle100,
      "y": 0.006173124888024202,
      "z": 0
    },
    "ringTop": {
      "x": angle90,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle20+0.02
    },
    "littleBase": {
      "x": angle40,
      "y": 0.005718873752241025,
      "z": angle20
    },
    "littleMid": {
      "x": angle100,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": angle90,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'f': {
    "wristBase": {
      "x": 0,
      "y": angle40,
      "z": 0
    },
    "wristTop": {
      "x": angle10,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle20,
      "y": 1,
      "z": angle50
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": -angle40-0.1
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": angle10,
      "z": -angle20
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": angle20+0.02
    },
    "indexBase": {
      "x": angle60-0.08,
      "y": 0.0002568275406612122,
      "z": -angle20
    },
    "indexMid": {
      "x": angle50,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.1,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle20,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle30,
      "y": angle10,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle20,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": -angle10-0.05
    },
    "ringBase": {
      "x": angle10,
      "y": -0.003850539761388931,
      "z": 0.06783352120216708
    },
    "ringMid": {
      "x": angle20,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": angle30,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle10+0.17
    },
    "littleBase": {
      "x": angle10,
      "y": 0.005718873752241025,
      "z": 0
    },
    "littleMid": {
      "x": angle10,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": angle10,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'f1': {
    "wristBase": {
      "x": 0,
      "y": angle40,
      "z": 0
    },
    "wristTop": {
      "x": angle10,
      "y": 0,
      "z": 0.05
    },
    "wristThumb": {
      "x": angle20,
      "y": 1,
      "z": angle50
    },
    "thumbBase": {
      "x": -0.2412986118047289-0.05,
      "y": -0.09114783535750028,
      "z": -angle40-0.05
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": angle10,
      "z": -angle20
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": angle20+0.02
    },
    "indexBase": {
      "x": angle60-0.08,
      "y": 0.0002568275406612122,
      "z": -angle20
    },
    "indexMid": {
      "x": angle50,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.1,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle20,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle30,
      "y": angle10,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle20,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": -angle10-0.05
    },
    "ringBase": {
      "x": angle10,
      "y": -0.003850539761388931,
      "z": 0.06783352120216708
    },
    "ringMid": {
      "x": angle20,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": angle30,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle10+0.17
    },
    "littleBase": {
      "x": angle10,
      "y": 0.005718873752241025,
      "z": 0
    },
    "littleMid": {
      "x": angle10,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": angle10,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'g': {
    "wristBase": {
      "x": angle10,
      "y": angle90+angle40,
      "z": -angle90
    },
    "wristTop": {
      "x": angle10,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle30,
      "y": -angle30,
      "z": angle20-0.07
    },
    "thumbBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "thumbTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": 0
    },
    "indexMid": {
      "x": 0,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'g1': {
    "wristBase": {
      "x": angle10,
      "y": angle90+angle40,
      "z": -angle90+0.1
    },
    "wristTop": {
      "x": angle10+0.1,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle30,
      "y": -angle30,
      "z": angle20-0.07
    },
    "thumbBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "thumbTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": 0
    },
    "indexMid": {
      "x": 0,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'h': {
    "wristBase": {
      "x": angle10,
      "y": angle90+angle30,
      "z": -angle90-angle10
    },
    "wristTop": {
      "x": angle60,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle80
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": -angle10-0.05
    },
    "indexMid": {
      "x": 0,
      "y": -0.002625303160102763,
      "z": -angle10-0.09
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": 0,
      "y": -0.0008645369447903341,
      "z": angle10+0.15
    },
    "middleMid": {
      "x": 0,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": 0,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'h1': {
    "wristBase": {
      "x": angle10,
      "y": angle90+angle30,
      "z": -angle90-angle10
    },
    "wristTop": {
      "x": angle60+0.1,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle80
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": -angle10-0.08
    },
    "indexMid": {
      "x": 0,
      "y": -0.002625303160102763,
      "z": -angle10-0.09
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": 0,
      "y": -0.0008645369447903341,
      "z": angle10+0.15
    },
    "middleMid": {
      "x": 0,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": 0,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'i': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle10,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": angle40,
      "y": angle30+0.1,
      "z": -angle50
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle50-0.1
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle90,
      "y": -0.002625303160102763,
      "z": angle10
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle90,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle90,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle40,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": 0,
      "y": 0.005718873752241025,
      "z": -0.3
    },
    "littleMid": {
      "x": 0,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": -0.1,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'i1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0.1,
      "y": 0,
      "z": 0.05
    },
    "wristThumb": {
      "x": angle10,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": angle40,
      "y": angle30+0.1,
      "z": -angle50
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle40
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle90,
      "y": -0.002625303160102763,
      "z": angle10
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle90,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle90,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle40,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": 0,
      "y": 0.005718873752241025,
      "z": -0.3
    },
    "littleMid": {
      "x": 0,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": -0.1,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'j': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle10,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": angle40,
      "y": angle30+0.1,
      "z": -angle50
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle50-0.1
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle90,
      "y": -0.002625303160102763,
      "z": angle10
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle90,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle90,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle40,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": 0,
      "y": 0.005718873752241025,
      "z": -0.3
    },
    "littleMid": {
      "x": 0,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": -0.1,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'j1': {
    "wristBase": {
      "x": angle10,
      "y": angle90+angle30,
      "z": -angle90-angle10
    },
    "wristTop": {
      "x": angle60,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle10,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": angle40,
      "y": angle30+0.1,
      "z": -angle50
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle50-0.1
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle90,
      "y": -0.002625303160102763,
      "z": angle10
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle90,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle90,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle40,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": 0,
      "y": 0.005718873752241025,
      "z": -0.3
    },
    "littleMid": {
      "x": 0,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": -0.1,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'j2': {
    "wristBase": {
      "x": angle60,
      "y": angle90+angle90,
      "z": -angle60
    },
    "wristTop": {
      "x": angle60,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle10,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": angle40,
      "y": angle30+0.1,
      "z": -angle50
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle50-0.1
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle90,
      "y": -0.002625303160102763,
      "z": angle10
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle90,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle90,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle40,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": 0,
      "y": 0.005718873752241025,
      "z": -0.3
    },
    "littleMid": {
      "x": 0,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": -0.1,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'j3': {
    "wristBase": {
      "x": angle60,
      "y": angle90+angle90,
      "z": -angle60
    },
    "wristTop": {
      "x": angle60,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle10,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": angle40,
      "y": angle30+0.1,
      "z": -angle50
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle90,
      "y": -0.002625303160102763,
      "z": angle10
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle90,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle90,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle40,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": 0,
      "y": 0.005718873752241025,
      "z": -0.3
    },
    "littleMid": {
      "x": angle10,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": -0.1,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'k': {
    "wristBase": {
      "x": 0,
      "y": -angle30,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle40
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle10,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle60,
      "y": -0.003850539761388931,
      "z": 0
    },
    "middleMid": {
      "x": 0,
      "y": -0.003850539761388931,
      "z": 0
    },
    "middleTop": {
      "x": 0,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'k1': {
    "wristBase": {
      "x": 0,
      "y": -angle30,
      "z": 0
    },
    "wristTop": {
      "x": 0.1,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle40+0.1
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle20,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle60,
      "y": -0.003850539761388931,
      "z": 0
    },
    "middleMid": {
      "x": angle10,
      "y": -0.003850539761388931,
      "z": 0
    },
    "middleTop": {
      "x": 0,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'l': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": angle10
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": -angle10+0.1
    },
    "indexMid": {
      "x": angle10,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'l1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0.05,
      "y": 0,
      "z": 0.05
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": angle10+0.1
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": -angle10+0.1
    },
    "indexMid": {
      "x": angle10,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'm': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": angle20,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle60
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle90,
      "y": 0.0002568275406612122,
      "z": -angle10-0.11
    },
    "indexMid": {
      "x": angle50,
      "y": -0.002625303160102763,
      "z": angle10+0.1
    },
    "indexTop": {
      "x": angle60,
      "y": -0.0033181236619804154,
      "z": 0
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle90,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle50,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle90,
      "y": -0.003850539761388931,
      "z": angle10+0.13
    },
    "ringMid": {
      "x": angle50,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle60,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle20+0.02
    },
    "littleBase": {
      "x": angle100-0.2,
      "y": 0.005718873752241025,
      "z": -angle10-0.1
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": angle10
    },
    "littleTop": {
      "x": angle20,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'm1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0.1,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": angle20,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle60
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle90,
      "y": 0.0002568275406612122,
      "z": -angle10-0.11
    },
    "indexMid": {
      "x": angle50,
      "y": -0.002625303160102763,
      "z": angle10+0.1
    },
    "indexTop": {
      "x": angle60,
      "y": -0.0033181236619804154,
      "z": 0
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle90,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle50,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle90,
      "y": -0.003850539761388931,
      "z": angle10+0.13
    },
    "ringMid": {
      "x": angle50,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle60,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle20+0.02
    },
    "littleBase": {
      "x": angle100-0.2,
      "y": 0.005718873752241025,
      "z": -angle10-0.1
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": angle10
    },
    "littleTop": {
      "x": angle20,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'n': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": angle20,
      "y": angle30+0.1,
      "z": -angle60-0.1
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle60
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle90,
      "y": 0.0002568275406612122,
      "z": -angle10-0.15
    },
    "indexMid": {
      "x": angle50,
      "y": -0.002625303160102763,
      "z": angle10+0.1
    },
    "indexTop": {
      "x": angle60,
      "y": -0.0033181236619804154,
      "z": 0
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle90,
      "y": -0.0008645369447903341,
      "z": 0.02
    },
    "middleMid": {
      "x": angle50,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle100,
      "y": -0.003850539761388931,
      "z": -angle10
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": angle10
    },
    "ringTop": {
      "x": angle20,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle20+0.02
    },
    "littleBase": {
      "x": angle100-0.2,
      "y": 0.005718873752241025,
      "z": -angle10-0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": angle10+0.1
    },
    "littleTop": {
      "x": angle20,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'n1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0.1,
      "y": 0.1,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": angle20,
      "y": angle30+0.1,
      "z": -angle60-0.1
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle60
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle90,
      "y": 0.0002568275406612122,
      "z": -angle10-0.15
    },
    "indexMid": {
      "x": angle50,
      "y": -0.002625303160102763,
      "z": angle10+0.1
    },
    "indexTop": {
      "x": angle60,
      "y": -0.0033181236619804154,
      "z": 0
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle90,
      "y": -0.0008645369447903341,
      "z": 0.02
    },
    "middleMid": {
      "x": angle50,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle100,
      "y": -0.003850539761388931,
      "z": -angle10
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": angle10
    },
    "ringTop": {
      "x": angle20,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle20+0.02
    },
    "littleBase": {
      "x": angle100-0.2,
      "y": 0.005718873752241025,
      "z": -angle10-0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": angle10+0.1
    },
    "littleTop": {
      "x": angle20,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'o': {
    "wristBase": {
      "x": 0,
      "y": angle40,
      "z": 0
    },
    "wristTop": {
      "x": angle10,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle10-0.05,
      "y": 1-angle10,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": -angle20
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle20+0.2
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle60-0.1,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle40,
      "y": -0.002625303160102763,
      "z": -0.03
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle60,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle40,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": -angle10-0.05
    },
    "ringBase": {
      "x": angle60,
      "y": -0.003850539761388931,
      "z": 0.05
    },
    "ringMid": {
      "x": angle30-0.1,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": angle40,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle10+0.17
    },
    "littleBase": {
      "x": angle60,
      "y": 0.005718873752241025,
      "z": -0.1
    },
    "littleMid": {
      "x": angle40,
      "y": 0.00363578315581031,
      "z": -0.08001397222649159
    },
    "littleTop": {
      "x": angle40,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'o1': {
    "wristBase": {
      "x": 0,
      "y": angle40,
      "z": 0
    },
    "wristTop": {
      "x": angle10+0.05,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle10-0.05,
      "y": 1-angle10,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": -angle20
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle20+0.2
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle60-0.1,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle40,
      "y": -0.002625303160102763,
      "z": -0.03
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle60,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle40,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": -angle10-0.05
    },
    "ringBase": {
      "x": angle60,
      "y": -0.003850539761388931,
      "z": 0.05
    },
    "ringMid": {
      "x": angle30-0.1,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": angle40,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": -angle10+0.17
    },
    "littleBase": {
      "x": angle60,
      "y": 0.005718873752241025,
      "z": -0.1
    },
    "littleMid": {
      "x": angle50,
      "y": 0.00363578315581031,
      "z": -0.08001397222649159
    },
    "littleTop": {
      "x": angle40,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'p': {
    "wristBase": {
      "x": angle60,
      "y": 0,
      "z": -angle50
    },
    "wristTop": {
      "x": angle40,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle40
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle10,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle60,
      "y": -0.003850539761388931,
      "z": 0
    },
    "middleMid": {
      "x": 0,
      "y": -0.003850539761388931,
      "z": 0
    },
    "middleTop": {
      "x": 0,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'p1': {
    "wristBase": {
      "x": angle60,
      "y": 0,
      "z": -angle50
    },
    "wristTop": {
      "x": angle40+0.05,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle40
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle30+0.1
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": -angle10+0.1
    },
    "indexMid": {
      "x": angle20,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle60+0.1,
      "y": -0.003850539761388931,
      "z": 0
    },
    "middleMid": {
      "x": 0,
      "y": -0.003850539761388931,
      "z": 0
    },
    "middleTop": {
      "x": 0,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'q': {
    "wristBase": {
      "x": angle60,
      "y": 0,
      "z": -angle60
    },
    "wristTop": {
      "x": angle40-0.1,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle40,
      "y": 0,
      "z": -0.8638881415362434-angle20
    },
    "thumbBase": {
      "x": angle10,
      "y": -angle30+0.1,
      "z": -angle30
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle10
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle30,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle10,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": 0
    },
    "middleMid": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": 0
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'q1': {
    "wristBase": {
      "x": angle60,
      "y": 0,
      "z": -angle60
    },
    "wristTop": {
      "x": angle40,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": angle40,
      "y": 0,
      "z": -0.8638881415362434-angle20
    },
    "thumbBase": {
      "x": angle10,
      "y": -angle30+0.15,
      "z": -angle30
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle10
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle30,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle10,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": 0
    },
    "middleMid": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": 0
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'r': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle20,
      "y": 0.0002568275406612122,
      "z": -angle30
    },
    "indexMid": {
      "x": 0.0036309183554384707,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": -angle10+0.06,
      "y": -0.0008645369447903341,
      "z": angle10+0.1
    },
    "middleMid": {
      "x": angle30,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle20,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'r1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0.05,
      "y": 0.03,
      "z": 0.02
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle20,
      "y": 0.0002568275406612122,
      "z": -angle30
    },
    "indexMid": {
      "x": 0.0036309183554384707,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": -angle10+0.06,
      "y": -0.0008645369447903341,
      "z": angle10+0.1
    },
    "middleMid": {
      "x": angle30,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle20,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  's': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": angle20,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": -angle10,
      "y": -angle50,
      "z": -0.8638881415362434+angle10
    },
    "thumbBase": {
      "x": angle40,
      "y": 0,
      "z": -angle20
    },
    "thumbTop": {
      "x": angle10,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": 0
    },
    "indexMid": {
      "x": angle80,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": angle60,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.05
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.05
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle60,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80,
      "y": 0.005718873752241025,
      "z": angle10-0.1
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle60,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  's1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0.05
    },
    "wristTop": {
      "x": angle20,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": -angle10,
      "y": -angle50,
      "z": -0.8638881415362434+angle10
    },
    "thumbBase": {
      "x": angle40,
      "y": 0,
      "z": -angle20
    },
    "thumbTop": {
      "x": angle10,
      "y": 0.009486827338147482,
      "z": -angle30+0.05
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": 0
    },
    "indexMid": {
      "x": angle80,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": angle60,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.05
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.05
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle60,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80,
      "y": 0.005718873752241025,
      "z": angle10-0.1
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle60,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  't': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": angle20,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": -angle10,
      "y": -angle50,
      "z": -0.8638881415362434+angle10
    },
    "thumbBase": {
      "x": -0.16,
      "y": 0,
      "z": -angle50
    },
    "thumbTop": {
      "x": 0,
      "y": 0.009486827338147482,
      "z": 0
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle60,
      "y": 0.0002568275406612122,
      "z": angle20
    },
    "indexMid": {
      "x": angle80,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": angle60,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.05
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.05
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle60,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80,
      "y": 0.005718873752241025,
      "z": angle10-0.1
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle60,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  't1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": angle20+0.1,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": -angle10,
      "y": -angle50,
      "z": -0.8638881415362434+angle10
    },
    "thumbBase": {
      "x": -0.16,
      "y": 0,
      "z": -angle50
    },
    "thumbTop": {
      "x": 0,
      "y": 0.009486827338147482,
      "z": 0
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle60,
      "y": 0.0002568275406612122,
      "z": angle20
    },
    "indexMid": {
      "x": angle80,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": angle60,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.05
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.05
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle60,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80,
      "y": 0.005718873752241025,
      "z": angle10-0.1
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle60,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'u': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle80
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": -angle10-0.05
    },
    "indexMid": {
      "x": 0,
      "y": -0.002625303160102763,
      "z": -angle10-0.09
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": 0,
      "y": -0.0008645369447903341,
      "z": angle10+0.15
    },
    "middleMid": {
      "x": 0,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": 0,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'u1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0.1,
      "z": 0.05
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle80+0.05
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": -angle10-0.05
    },
    "indexMid": {
      "x": 0,
      "y": -0.002625303160102763,
      "z": -angle10-0.09
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": 0,
      "y": -0.0008645369447903341,
      "z": angle10+0.17
    },
    "middleMid": {
      "x": 0,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": 0,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'v': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle60
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": angle10+0.1
    },
    "indexMid": {
      "x": 0,
      "y": -0.002625303160102763,
      "z": -angle10-0.09
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": 0,
      "y": -0.0008645369447903341,
      "z": -angle20
    },
    "middleMid": {
      "x": 0,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": 0,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'v1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0.05,
      "y": 0,
      "z": 0.05
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle60
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": 0,
      "y": 0.0002568275406612122,
      "z": angle10+0.1
    },
    "indexMid": {
      "x": 0,
      "y": -0.002625303160102763,
      "z": -angle10-0.09
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": 0,
      "y": -0.0008645369447903341,
      "z": -angle20+0.1
    },
    "middleMid": {
      "x": 0,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": 0,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'w': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle60
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle20,
      "y": 0.0002568275406612122,
      "z": angle10
    },
    "indexMid": {
      "x": 0,
      "y": -0.002625303160102763,
      "z": -angle10-0.09
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": 0,
      "y": -0.0008645369447903341,
      "z": 0.1
    },
    "middleMid": {
      "x": 0,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": 0,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle20,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": 0,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": 0,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle20+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'w1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": -0.1,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": angle60
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": angle30+0.1,
      "z": -angle60+0.05
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -angle60
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle20,
      "y": 0.0002568275406612122,
      "z": angle10
    },
    "indexMid": {
      "x": 0,
      "y": -0.002625303160102763,
      "z": -angle10-0.09
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": 0,
      "y": -0.0008645369447903341,
      "z": 0.1
    },
    "middleMid": {
      "x": 0,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": 0,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle20,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": 0,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": 0,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle20+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'x': {
    "wristBase": {
      "x": 0,
      "y": angle10,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": -angle10,
      "y": -angle50,
      "z": -0.8638881415362434+angle10
    },
    "thumbBase": {
      "x": angle40,
      "y": 0,
      "z": -angle20
    },
    "thumbTop": {
      "x": angle30,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle10,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle80,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80+0.05,
      "y": -0.003850539761388931,
      "z": -angle10-0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80+0.03,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": 0.05,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'x1': {
    "wristBase": {
      "x": -0.05,
      "y": angle10,
      "z": 0.01
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": -angle10,
      "y": -angle50,
      "z": -0.8638881415362434+angle10
    },
    "thumbBase": {
      "x": angle40+0.05,
      "y": 0,
      "z": -angle20
    },
    "thumbTop": {
      "x": angle30,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle10,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle80+0.1,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80+0.05,
      "y": -0.003850539761388931,
      "z": -angle10-0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "middleTop": {
      "x": angle80,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80+0.03,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle80,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": 0.05,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80-0.2,
      "y": 0.005718873752241025,
      "z": angle10+0.05
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle80,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'y': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": -angle10
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434+angle20
    },
    "thumbBase": {
      "x": angle10,
      "y": angle30+0.1,
      "z": -angle20
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": 0
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle90,
      "y": -0.002625303160102763,
      "z": angle10
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle90,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle90,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle40,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": 0,
      "y": 0.005718873752241025,
      "z": -angle10
    },
    "littleMid": {
      "x": 0,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": -0.1,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'y1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0.1,
      "y": 0,
      "z": -angle10+0.1
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434+angle20
    },
    "thumbBase": {
      "x": angle10,
      "y": angle30+0.1,
      "z": -angle20
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": 0
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle80,
      "y": 0.0002568275406612122,
      "z": -angle10
    },
    "indexMid": {
      "x": angle90,
      "y": -0.002625303160102763,
      "z": angle10
    },
    "indexTop": {
      "x": angle40,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": angle90,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": angle40,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.1
    },
    "ringMid": {
      "x": angle90,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle40,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": 0,
      "y": 0.005718873752241025,
      "z": -angle10
    },
    "littleMid": {
      "x": 0,
      "y": 0.00363578315581031,
      "z": 0
    },
    "littleTop": {
      "x": -0.1,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'z': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": angle30,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": -angle10,
      "y": -angle50,
      "z": -0.8638881415362434+angle10
    },
    "thumbBase": {
      "x": angle40,
      "y": 0,
      "z": -angle20
    },
    "thumbTop": {
      "x": angle10,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle20,
      "y": 0.0002568275406612122,
      "z": 0
    },
    "indexMid": {
      "x": angle10,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.05
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.05
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle60,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80,
      "y": 0.005718873752241025,
      "z": angle10-0.1
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle60,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'z1': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": angle30,
      "y": 0,
      "z": -angle30
    },
    "wristThumb": {
      "x": -angle10,
      "y": -angle50,
      "z": -0.8638881415362434+angle10
    },
    "thumbBase": {
      "x": angle40,
      "y": 0,
      "z": -angle20
    },
    "thumbTop": {
      "x": angle10,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle20,
      "y": 0.0002568275406612122,
      "z": 0
    },
    "indexMid": {
      "x": angle10,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.05
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.05
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle60,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80,
      "y": 0.005718873752241025,
      "z": angle10-0.1
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle60,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'z2': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": angle50,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": -angle10,
      "y": -angle50,
      "z": -0.8638881415362434+angle10
    },
    "thumbBase": {
      "x": angle40,
      "y": 0,
      "z": -angle20
    },
    "thumbTop": {
      "x": angle10,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle20,
      "y": 0.0002568275406612122,
      "z": 0
    },
    "indexMid": {
      "x": angle10,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.05
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.05
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle60,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80,
      "y": 0.005718873752241025,
      "z": angle10-0.1
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle60,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'z3': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": angle50,
      "y": 0,
      "z": -angle30
    },
    "wristThumb": {
      "x": -angle10,
      "y": -angle50,
      "z": -0.8638881415362434+angle10
    },
    "thumbBase": {
      "x": angle40,
      "y": 0,
      "z": -angle20
    },
    "thumbTop": {
      "x": angle10,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle20,
      "y": 0.0002568275406612122,
      "z": 0
    },
    "indexMid": {
      "x": angle10,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.1
    },
    "middleMid": {
      "x": angle80,
      "y": -0.000006686995635815641,
      "z": 0.05
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.05
    },
    "ringMid": {
      "x": angle80,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle60,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80,
      "y": 0.005718873752241025,
      "z": angle10-0.1
    },
    "littleMid": {
      "x": angle80,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle60,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  },
  'z4': {
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": angle50,
      "y": 0,
      "z": -angle30
    },
    "wristThumb": {
      "x": -angle10,
      "y": -angle50+0.1,
      "z": -0.8638881415362434+angle10
    },
    "thumbBase": {
      "x": angle40,
      "y": 0,
      "z": -angle20
    },
    "thumbTop": {
      "x": angle10,
      "y": 0.009486827338147482,
      "z": -angle30
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": angle20-0.1,
      "y": 0.0002568275406612122,
      "z": 0
    },
    "indexMid": {
      "x": angle10+0.1,
      "y": -0.002625303160102763,
      "z": 0
    },
    "indexTop": {
      "x": 0,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": angle10+0.08
    },
    "middleBase": {
      "x": angle80,
      "y": -0.0008645369447903341,
      "z": 0.1
    },
    "middleMid": {
      "x": angle80+0.1,
      "y": -0.000006686995635815641,
      "z": 0.05
    },
    "middleTop": {
      "x": angle60,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": angle80,
      "y": -0.003850539761388931,
      "z": angle10+0.05
    },
    "ringMid": {
      "x": angle80+0.1,
      "y": 0.006173124888024202,
      "z": -angle10
    },
    "ringTop": {
      "x": angle60,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": angle80,
      "y": 0.005718873752241025,
      "z": angle10-0.1
    },
    "littleMid": {
      "x": angle90,
      "y": 0.00363578315581031,
      "z": -angle10+0.1
    },
    "littleTop": {
      "x": angle60,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  }
}

const HandAnimation = ({currentLetter,width,height,isCNNloaded,isHandSeen}) => {
  // useStates
  const [handIntialize,setIntialize] = useState(true);
  const boneRotations = useRef({
    "wristBase": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristTop": {
      "x": 0,
      "y": 0,
      "z": 0
    },
    "wristThumb": {
      "x": 0,
      "y": 0,
      "z": -0.8638881415362434
    },
    "thumbBase": {
      "x": -0.2412986118047289,
      "y": -0.09114783535750028,
      "z": 0.3542620038040601
    },
    "thumbTop": {
      "x": 0.04336478555131234,
      "y": 0.009486827338147482,
      "z": -0.11164087073484776
    },
    "wristIndex": {
      "x": 0.019172564804894442,
      "y": -0.005488978793282911,
      "z": -0.27879492242054327
    },
    "indexBase": {
      "x": -0.21490532060154555,
      "y": 0.0002568275406612122,
      "z": -0.0012364130552036617
    },
    "indexMid": {
      "x": 0.0036309183554384707,
      "y": -0.002625303160102763,
      "z": 0.02789951829261999
    },
    "indexTop": {
      "x": -0.09408339373849464,
      "y": -0.0033181236619804154,
      "z": 0.016958418024545993
    },
    "wristMiddle": {
      "x": 0.031390209682875175,
      "y": -0.0017299774915746505,
      "z": -0.05503523530211633
    },
    "middleBase": {
      "x": -0.031390302921621746,
      "y": -0.0008645369447903341,
      "z": 0.05506240601868012
    },
    "middleMid": {
      "x": -0.12435494054214595,
      "y": -0.000006686995635815641,
      "z": 0.0001074093312455546
    },
    "middleTop": {
      "x": -0.19287115715812556,
      "y": 0.0037103417613650064,
      "z": -0.014013592548275859
    },
    "wristRing": {
      "x": 0.027361926525128154,
      "y": 0.004514052125154341,
      "z": 0.1631326427562704
    },
    "ringBase": {
      "x": -0.07048834033269637,
      "y": -0.003850539761388931,
      "z": 0.06783352120216708
    },
    "ringMid": {
      "x": -0.20052864919048807,
      "y": 0.006173124888024202,
      "z": -0.03251536787697237
    },
    "ringTop": {
      "x": -0.050945657493289304,
      "y": 0.004444632389056805,
      "z": -0.024751400987086423
    },
    "wristLittle": {
      "x": -0.047245602418898545,
      "y": -0.01945050137501128,
      "z": 0.3910022650801076
    },
    "littleBase": {
      "x": -0.03955273281470167,
      "y": 0.005718873752241025,
      "z": -0.03255938149984805
    },
    "littleMid": {
      "x": -0.02304039923611267,
      "y": 0.00363578315581031,
      "z": -0.08001397222649159
    },
    "littleTop": {
      "x": -0.20126179531004806,
      "y": -0.005563961116586401,
      "z": 0.026763820227170457
    }
  })

  // useRefs
  const containerRef = useRef(null);
  const rendererRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const outlineEffectRef = useRef(null);

  // Animation function
  const updateHandPosition = (newPosition) => {
    new TWEEN.Tween(sceneRef.current.position)
    .to(newPosition, 200) // 500ms duration
    .easing(TWEEN.Easing.Quadratic.Out)
    .start();
  }
  
  const tellToAnimate = useCallback(() => {
    const boneMap = {
      wristBase: ['x', 'y', 'z'],
      wristTop: ['x', 'y', 'z'],
  
      wristThumb: ['x', 'y', 'z'],
      wristIndex: ['x', 'y', 'z'],
      wristMiddle: ['x', 'y', 'z'],
      wristRing: ['x', 'y', 'z'],
      wristLittle: ['x', 'y', 'z'],
  
      thumbBase: ['x', 'y', 'z'],
      thumbTop: ['x','y','z'],

      indexBase: ['x','y','z'],
      indexMid: ['x','y','z'],
      indexTop: ['x','y','z'],

      middleBase: ['x','y','z'],
      middleMid: ['x','y','z'],
      middleTop: ['x','y','z'],

      ringBase: ['x','y','z'],
      ringMid: ['x','y','z'],
      ringTop: ['x','y','z'],

      littleBase: ['x','y','z'],
      littleMid: ['x','y','z'],
      littleTop: ['x','y','z']

      // Add more bones here, specifying the rotation axis for each
    };

    sceneRef.current.traverse((child) => {
      if (child.name in boneMap) {
        const axes = boneMap[child.name];
        axes.forEach(axis => {
          child.rotation[axis] = boneRotations.current[child.name][axis];
        });
      }
    }); 
  },[]);

  const getBoneRotations = useCallback(() => {
    TWEEN.removeAll();
    let newRotations;
    if(currentLetter === undefined || currentLetter === null){
      newRotations = handMovements['original'];
    }else{
      newRotations = handMovements[currentLetter.toLowerCase()];
    }

    // Cancel introduction if hand is seen
    if(isHandSeen){
      setIntialize(false);
    }

    if(handIntialize && isCNNloaded){
      const stepA = new TWEEN.Tween(boneRotations.current)
      .to(handMovements['hello'], 500) // 500ms duration
      .easing(TWEEN.Easing.Quadratic.In)

      const stepB = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['hello1'], 250) // 250ms duration
        .easing(TWEEN.Easing.Quadratic.InOut)

      const stepC = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['hello2'], 250) // 250ms duration
        .easing(TWEEN.Easing.Quadratic.InOut)

      const stepD = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['helloR'], 500) // 250ms duration
        .easing(TWEEN.Easing.Quadratic.Out)

      const stepE = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['helloR1'], 500) // 250ms duration
        .easing(TWEEN.Easing.Quadratic.Out)
      
      const stepF = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['helloR2'], 250) // 250ms duration
        .easing(TWEEN.Easing.Linear.None)

      const delayBeforeG = new TWEEN.Tween({})
        .to({}, 100) // 200 delay
        .onComplete(() => stepG.start());
      
      const stepG = new TWEEN.Tween(boneRotations.current)
      .to(handMovements['helloX'], 800) // 250ms duration
      .easing(TWEEN.Easing.Back.InOut)

      const stepH = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['helloX1'], 250) // 250ms duration
        .easing(TWEEN.Easing.Linear.None);

      const stepI = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['helloX2'], 250) // 250ms duration
        .easing(TWEEN.Easing.Linear.None);

      const stepJ = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['original'], 500) // 500ms duration
        .easing(TWEEN.Easing.Quadratic.In)

      let currentRepeat = 0;
      const repeatCount = 1; // This will result in 2 repetitions of B and C
      
      const repeatCount1 = 2; // This will result in 2 repetitions of B and C

      stepA
        .onUpdate(() => {
          tellToAnimate();
        })
        .chain(stepB);

      stepB
        .onUpdate(() => {
          tellToAnimate();
        })
        .onComplete(() => {
          stepC.start();
        });

      stepC
        .onUpdate(() => {
          tellToAnimate();
        })
        .onComplete(() => {
          currentRepeat++;
          if (currentRepeat <= repeatCount) {
            stepB
              .onUpdate(() => {
                tellToAnimate();
              })
              .start();
          } else {
            currentRepeat = 0
            stepD
              .onUpdate(() => {
                tellToAnimate();
              })
              .start();
          }
        });

      stepD
        .onUpdate(() => {
          tellToAnimate();
        })
        .chain(
          stepE
          .onUpdate(() => {
            tellToAnimate();
          })
        )
      
      stepE
        .onUpdate(() => {
          tellToAnimate();
        })
        .chain(
          stepF
          .onUpdate(() => {
            tellToAnimate();
          })
        )

      stepF
        .onUpdate(() => {
          tellToAnimate();
        })
        .chain(delayBeforeG)

      delayBeforeG
        .onUpdate(() => {
          tellToAnimate();
        })
        .chain(stepG)

      stepG
        .onUpdate(() => {
          tellToAnimate();
        })
        .chain(stepH)
      
      stepH
        .onUpdate(() => {
          tellToAnimate();
        })
        .onComplete(() => {
          stepI.start();
        });

      stepI
        .onUpdate(() => {
          tellToAnimate();
        })
        .onComplete(() => {
          currentRepeat++;
          if (currentRepeat <= repeatCount1) {
            stepH
              .onUpdate(() => {
                tellToAnimate();
              })
              .start();
          } else {
            currentRepeat = 0
            stepJ
              .onUpdate(() => {
                tellToAnimate();
                setIntialize(false);
              })
              .start();
          }
        });

      stepA.start();
    }else if(currentLetter === undefined || currentLetter === null){
      const stepA = new TWEEN.Tween(boneRotations.current)
      .to(newRotations, 500) // 500ms duration
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(tellToAnimate);

    const stepB = new TWEEN.Tween(boneRotations.current)
      .to(handMovements['original1'], 2000) // 2000ms duration
      .easing(TWEEN.Easing.Quadratic.InOut);

    const stepC = new TWEEN.Tween(boneRotations.current)
      .to(newRotations, 1500) // 1500ms duration
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(tellToAnimate);
    
    // Chain stepA and stepB
    stepA
        .onUpdate(() => {
          tellToAnimate();
        })
        .chain(stepB
          .onUpdate(() => {
            tellToAnimate();
          })
          .chain(stepC
            .onUpdate(() => {
              tellToAnimate();
            })
            .chain(stepA
              .onUpdate(() => {
                tellToAnimate();
              })
            )
          )
        ).start();
    }else if(currentLetter.toLowerCase() === 'j'){
      const stepA = new TWEEN.Tween(boneRotations.current)
        .to(newRotations, 500) // 500ms duration
        .easing(TWEEN.Easing.Quadratic.In)

      const stepB = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['j1'], 350) // 250ms duration
        .easing(TWEEN.Easing.Linear.None)

      const stepC = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['j2'], 250) // 250ms duration
        .easing(TWEEN.Easing.Quadratic.InOut)
      
      const stepD = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['j3'], 2000) // 1s duration
        .easing(TWEEN.Easing.Quadratic.InOut);

      const stepE = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['original'], 1000) // 1s duration
        .easing(TWEEN.Easing.Quadratic.InOut);

      stepA
        .onUpdate(() => {
          tellToAnimate();
        })
        .chain(stepB
          .onUpdate(() => {
            tellToAnimate();
          })
          .chain(stepC
            .onUpdate(() => {
              tellToAnimate();
            })
            .chain(stepD
              .onUpdate(() => {
                tellToAnimate();
              })
              .chain(
                stepE
                .onUpdate(() => {
                  tellToAnimate();
                })
                .chain(
                  stepA
                  .onUpdate(() => {
                    tellToAnimate();
                  })
                )
              )
            )
          )
        )
        .start();
    }else if(currentLetter.toLowerCase() === 'z'){
      const stepA = new TWEEN.Tween(boneRotations.current)
        .to(newRotations, 600) // 600ms duration
        .easing(TWEEN.Easing.Quadratic.InOut)

      const stepB = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['z1'], 350) // 350ms duration
        .easing(TWEEN.Easing.Quadratic.In)
      
      const stepC = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['z2'], 350) // 350ms duration
        .easing(TWEEN.Easing.Quadratic.In)
      
      const stepD = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['z3'], 350) // 350ms duration
        .easing(TWEEN.Easing.Quadratic.In)
      
      const stepE = new TWEEN.Tween(boneRotations.current)
        .to(handMovements['z4'], 2000) // 1s duration
        .easing(TWEEN.Easing.Quadratic.Out)

      stepA
        .onUpdate(() => {
          tellToAnimate();
        })
        .chain(stepB
          .onUpdate(() => {
            tellToAnimate();
          })
          .chain(stepC
            .onUpdate(() => {
              tellToAnimate();
            })
            .chain(stepD
              .onUpdate(() => {
                tellToAnimate();
              })
              .chain(stepE
                .onUpdate(() => {
                  tellToAnimate();
                })
                .chain(stepA
                  .onUpdate(() => {
                    tellToAnimate();
                  })
                )
              )
            )
          )
        )
        .start();
    }else{
      const stepA = new TWEEN.Tween(boneRotations.current)
      .to(newRotations, 500) // 500ms duration
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(tellToAnimate);

    const stepB = new TWEEN.Tween(boneRotations.current)
      .to(handMovements[currentLetter.toLowerCase()+'1'], 2000) // 2000ms duration
      .easing(TWEEN.Easing.Quadratic.InOut);

    const stepC = new TWEEN.Tween(boneRotations.current)
      .to(newRotations, 1500) // 1500ms duration
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(tellToAnimate);
    
    // Chain stepA and stepB
    stepA
        .onUpdate(() => {
          tellToAnimate();
        })
        .chain(stepB
          .onUpdate(() => {
            tellToAnimate();
          })
          .chain(stepC
            .onUpdate(() => {
              tellToAnimate();
            })
            .chain(stepA
              .onUpdate(() => {
                tellToAnimate();
              })
            )
          )
        ).start();
    }

    if(handIntialize){
      updateHandPosition({x: 0, y: 0, z: 0});
    }else if(currentLetter === undefined || currentLetter === null){
      updateHandPosition({x: 0, y: 0, z: 0});
    }else if(['g','h'].includes(currentLetter.toLowerCase())){
      updateHandPosition({x: 2, y: 3, z: 0});
    }else if(['p','q'].includes(currentLetter.toLowerCase())){
      updateHandPosition({x: -2, y: 3, z: 0});
    }else if(currentLetter.toLowerCase() === 'hello'){
    }else if(currentLetter.toLowerCase() === 'j'){
      const step1 = new TWEEN.Tween(sceneRef.current.position)
        .to({x: 0, y: 0, z: 0}, 500) // 500ms duration
        .easing(TWEEN.Easing.Quadratic.Out);
      
      const step2 = new TWEEN.Tween(sceneRef.current.position)
        .to({x: 2, y: 3, z: 0}, 350) // 500ms duration
        .easing(TWEEN.Easing.Quadratic.Out);

      const step3 = new TWEEN.Tween(sceneRef.current.position)
        .to({x: 2, y: 3, z: 0}, 2250) // 500ms duration
        .easing(TWEEN.Easing.Quadratic.Out);

      const step4 = new TWEEN.Tween(sceneRef.current.position)
        .to({x: 0, y: 0, z: 0}, 1000) // 500ms duration
        .easing(TWEEN.Easing.Quadratic.Out);

      step1
        .chain(step2
          .chain(step3
            .chain(step4
              .chain(step1)
            )
          )
        )
        .start();
    }else{
      updateHandPosition({x: 0, y: 0, z: 0});
    }
  },[currentLetter,handIntialize,tellToAnimate,isCNNloaded,isHandSeen]);

  useEffect(() => {
    if(sceneRef.current == null){return;}
    getBoneRotations();
  },[getBoneRotations]);

  useEffect(() => {
    // Scene
    const container = containerRef.current;
    const scene = new THREE.Scene();
    const bgColor = new THREE.Color(PARAMS.bg);
    scene.background = bgColor;
    sceneRef.current = scene;

    // Camera
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    camera.position.set(0, 0, 10);
    cameraRef.current = camera;

    // Renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(container.clientWidth, container.clientHeight);
    container.appendChild(renderer.domElement);
    rendererRef.current = renderer;
    renderer.setPixelRatio(window.devicePixelRatio);

    // Outline Effect
    const outlineEffect = new OutlineEffect(renderer, {
      defaultThickness: 0.0035,
      defaultColor: [0, 0, 0],
      defaultAlpha: 0.8,
      defaultKeepAlive: true
    });
    outlineEffectRef.current = outlineEffect;

    // Materials
    const handMaterial = new THREE.MeshToonMaterial();
    const shirtMaterial = new THREE.MeshToonMaterial();

    const getMaterials = () => {
      handMaterial.color = new THREE.Color(PARAMS.hand);
      handMaterial.roughness = 0.7;
      handMaterial.emissive = new THREE.Color(PARAMS.hand);
      handMaterial.emissiveIntensity = 0.5;
      scene.getObjectByName('hand').material = handMaterial;
    
      shirtMaterial.color = new THREE.Color(PARAMS.shirt);
      shirtMaterial.roughness = 0.9;
      shirtMaterial.emissive = new THREE.Color(PARAMS.shirt);
      shirtMaterial.emissiveIntensity = 0.7;
      scene.getObjectByName('shirt').material = shirtMaterial;
    };

    // Lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
    directionalLight.position.set(-7, 5, 5);
    directionalLight.scale.set(0.5, 0.5, 0.5);
    scene.add(directionalLight);

    // Model
    const loader = new GLTFLoader();
    
    loader.load(
      handModel,
      (gltf) => {
        scene.add(gltf.scene);
        getMaterials();

        // Accessing the armature (skeleton)
        gltf.scene.traverse((child) => {
          tellToAnimate(child);
        });
        
        // Apply outline effect to specific meshes
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            child.material.depthWrite = true;
            child.material.transparent = false;
          }
        });
      }
    );

    // Call to animate
    tellToAnimate();

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      if (outlineEffectRef.current) {
        outlineEffectRef.current.render(scene, camera);
      }

      TWEEN.update(); // Smooth transition
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      if (container && rendererRef.current && cameraRef.current) {
        const width = container.clientWidth;
        const height = container.clientHeight;
    
        // Update renderer size
        rendererRef.current.setSize(width, height);
    
        // Update camera aspect ratio
        cameraRef.current.aspect = width / height;
        cameraRef.current.updateProjectionMatrix();
    
        // Re-render the scene
        if (outlineEffectRef.current && sceneRef.current) {
          outlineEffectRef.current.render(sceneRef.current, cameraRef.current);
        }
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize); // eslint-disable-next-line
      if (container) {
        container.removeChild(renderer.domElement);
      } else {
        console.error('Container reference is null');
      }
    };
  }, [tellToAnimate,containerRef]);

  return (
    <div 
      ref={containerRef} 
      style={{
        width: width,
        height: height,
        position: 'absolute',
        marginRight: 'auto',
        marginLeft: 'auto',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    />
  );
};

export default HandAnimation;