// Develop the flexible way for recording to accept in more browsers
const types = [
  "video/mp4",
  "video/webm"
];

var options;

for (const type of types) {
  if(MediaRecorder.isTypeSupported(type) === true){
    options = type;
  }
}

export const DEFAULT_RECORDER_OPTIONS = {
  videoBitsPerSecond: 2500000,
  mimeType: options
}

export const ERROR_MESSAGES = {
  BY_ID_NOT_FOUND: "No recording by id found",
  SESSION_EXISTS: "Recording session already exists",
  USER_PERMISSION: "User did not grant the permission to record"
}

export const DEFAULT_CONSTRAINTS = {
  aspectRatio: 1.7,
  echoCancellation: true,
  height: 720,
  width: 1280
}