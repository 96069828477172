import React from "react";
import './everything.css';
 // We import NavLink to utilize the react router.
import { NavLink } from "react-router-dom";
// Import Gally logo
import GallyLogo from './assets/Gallaudet_University_logo.svg';
 // Here, we display our Navbar
export default function Navbar() {
 return (
   <div>
     <nav className="nav-background">
        <NavLink 
          className="navbar-brand" 
          to="/"
          aria-label="Gallaudet's Logo: Home page" 
          role='button'
        >
          <img aria-hidden = 'true' src = {GallyLogo} alt = "Gallaudet University's logo" height = '40px'/>
        </NavLink>
     </nav>
   </div>
 );
}