import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import './everything.css'

// All tools for Convolutional Neural Network
import Webcam from 'react-webcam';
import {Camera} from "@mediapipe/camera_utils";
import { HAND_CONNECTIONS, Holistic } from '@mediapipe/holistic';
import {drawConnectors, drawLandmarks} from '@mediapipe/drawing_utils';

// For kids
import kidsArray from './assets/arrayForKids.json'

const ErrorComponent = () => {
  return (
    <>
      <main>
        <h1>
          Shutter stuck in solitude.
        </h1>
      </main>
      <p/>
      <div className = 'ask-permission-denied'>
        <div className = 'gally-text-container'>
          <main className = 'camera-is-blocked'>Camera is blocked</main>
          <section className = 'camera-is-blocked-section'>The blocked camera may be due to no webcam, accidentally denied access, or another app or tab using the camera.</section>
        </div>
        <div className = 'permission-denied'>
          <svg viewBox = '-1 0 14 10' className = 'permission-denied-icon'>
            <path d = 'M 0 0 L 3 0 L 4 3 L 2 4 L 5 7 L 4 5 L 6 3 L 5 2 L 5 0 L 9 0 C 9 0 10 0 10 1 L 10 1 L 10 3 L 12 1 C 13 0.5 13 0.5 13 1 L 13 9 C 13 9.5 13 9.5 12 9 L 12 9 L 10 7 L 10 9 C 10 9 10 10 9 10 L 0 10 C 0 10 -1 10 -1 9 L -1 1 C -1 1 -1 0 0 0 Z'/>
          </svg>
          <p/>
        </div>
      </div>
    </>
  );
};

export default function VideoForKid() {
  // Webcam Record components
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  // Add Convolutional Neural Network detectors
  const [see_hands, setSeeHands] = useState(false);
  const [countupforunseen, setCountUpForUnseen] = useState(0);
  const [countupforseen, setCountUpForseen] = useState(0);
  const [cnnLoaded, setCNN_Load] = useState(false);

  const onResults = (results) => {
    if (!webcamRef.current?.video || !canvasRef.current) return
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");
    if (canvasCtx == null) throw new Error('Could not get context');
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    // Only overwrite existing pixels.
    canvasCtx.globalCompositeOperation = 'source-in';
    canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

    // Only overwrite missing pixels.
    canvasCtx.globalCompositeOperation = 'destination-atop';
    canvasCtx.drawImage(
      results.image, 0, 0, canvasElement.width, canvasElement.height);
    
    canvasCtx.fillStyle = '#fffdfdce';
    canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

    canvasCtx.globalCompositeOperation = 'source-over';
    drawConnectors(canvasCtx, results.leftHandLandmarks, HAND_CONNECTIONS,
      {color: '#EFCA77', lineWidth: 1.5});
    drawLandmarks(canvasCtx, results.leftHandLandmarks,
      {color: '#0F4D81', radius: 1});
    drawConnectors(canvasCtx, results.rightHandLandmarks, HAND_CONNECTIONS,
      {color: '#EFCA77', lineWidth: 1.5});
    drawLandmarks(canvasCtx, results.rightHandLandmarks,
      {color: '#0F4D81', radius: 1});

    canvasCtx.restore();
    if(typeof results.rightHandLandmarks !== "undefined" | typeof results.leftHandLandmarks !== "undefined"){
      setCountUpForseen(prev => prev + 1);
      setCountUpForUnseen(0);
    }else{
      setCountUpForseen(0);
      setCountUpForUnseen(prev => prev + 1);
    }

    setCNN_Load(true);
  }

  const [getShuffle, setShuffle] = useState(0);

  const shuffleTexts = (see_hands) => {
    if(see_hands){
      let i = 0;
      let value = 0;
      while(i === 0){
        value = Math.floor(Math.random()*kidsArray.length);
        if(value !== 0){
          i = 1;
          setShuffle(value);
        };
      };
    }
  };

  useEffect(() => {
    if(countupforseen > 1 & !see_hands & countupforseen){
      setSeeHands(true);
      shuffleTexts(true);
    }else if(countupforunseen > 5 & see_hands){
      setSeeHands(false);
    }
  },[countupforseen,countupforunseen,see_hands]);

  const [cameraError, setCameraError] = useState(false);
  const [firstClick, setFirstClick] = useState(true);
  const cameraStart = async() => {
    try{
    const holistic = new Holistic({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`;
      }
    });
    
    holistic.setOptions({
      selfieMode: true,
      modelComplexity: 1,
      smoothLandmarks: true,
      enableSegmentation: true,
      smoothSegmentation: true,
      refineFaceLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5
    });
    holistic.onResults(onResults);

    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null
    ) {
      if (!webcamRef.current?.video) return
      const camera = new Camera(webcamRef.current.video, {
        onFrame: async () => {
          if (!webcamRef.current?.video) return
          await holistic.send({image: webcamRef.current.video});
        },
      });
      await camera.start();
    }
    }catch(err){
      setCameraError(true);
    }finally{
      setFirstClick(false);
    }
  };

  console.log(cameraError)

  return (
    <div className = 'gally-background'>
      <div className = 'gally-container-for-video'>
        <p/>
        <div className = 'gally-video-container'>
        <main style = {{display: firstClick ? 'unset' : 'none'}}>
          <h1>
            Turn camera on
          </h1>
        </main>
        <div onClick = {cameraStart}
          style = {{display: firstClick ? 'unset' : 'none'}}
          className = 'ask-permission-for-video' 
          aria-label = 'This button asks you to turn camera on.' 
          role = 'button'
          tabIndex={1}
          title = 'This button contains a blue background with a yellow circle on it, and there is a blue camera icon in the yellow circle. Below this yellow circle, there is a text saying, "[Space Press]" to turn camera on.'
          alt=""
        >
          <div className = 'gally-flex'>
            <div className = 'gally-row'>
              <p/>
              <div 
                className = 'fake-button-symbol' 
                aria-hidden = "true"
              >
                <svg viewBox = '-1 0 14 10' className = 'camera-symbol'>
                  <path d = 'M 0 0 L 9 0 C 9 0 10 0 10 1 L 10 1 L 10 3 L 12 1 C 13 0.5 13 0.5 13 1 L 13 9 C 13 9.5 13 9.5 12 9 L 12 9 L 10 7 L 10 9 C 10 9 10 10 9 10 L 0 10 C 0 10 -1 10 -1 9 L -1 1 C -1 1 -1 0 0 0 Z'/>
                </svg>
              </div>
            </div>
          </div>
        </div>
        {
          (() => {
            if(cameraError){
              return <ErrorComponent/>
            }else{
              return(
                <>
                  <main style = {{display: firstClick ? 'none' : 'unset'}}>
                    <h1>
                      {!see_hands ? kidsArray[getShuffle] : 'I saw it!'}
                    </h1>
                  </main>
                  <div className = 'gally-video' style = {{display: firstClick ? 'none' : 'unset'}}>
                    <Webcam 
                      className = 'gally-video' 
                      ref={webcamRef}
                      style = {{display: 'none' }}
                    />
                    <div className = 'canvas-background'>
                      <div className = 'gally-flex'>
                        <div className = 'gally-row'>
                          <p/>
                          <div 
                            className = 'waiting-for-camera' 
                            aria-hidden = "true"
                          >
                            <svg viewBox="-3 -5.2 24 15.2" className = 'camera-symbol' aria-disabled = {cnnLoaded}>
                              <path d="M 2.5 5 a 2.5 2.5 0 1 1 5 0 a 2.5 2.5 0 1 1 -5 0 M 10.5 5 a 2.5 2.5 0 1 1 5 0 a 2.5 2.5 0 1 1 -5 0 
                                M 17 10 Q 20 10 20 7 L 20 4 Q 20 1 17 1 L 1 1 Q -2 1 -2 4 L -2 7 Q -2 10 1 10 Z
                                M 5 0.8 Q 5 0 6 0 L 12 0 Q 13 0 13 0.8 Z
                                M -2.2 4 Q -3 4 -3 4.5 L -3 6.5 Q -3 7 -2.2 7 Z
                                M 20.2 4 Q 21 4 21 4.5 L 21 6.5 Q 21 7 20.2 7 Z
                                M 9.2 -0.2 L 9.2 -4 L 9.8 -4.5 L 9 -5.2 L 8.2 -4.5 L 8.8 -4 L 8.8 -0.2 Z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <canvas className = 'gally-canvas' ref = {canvasRef}/>
                  </div>
                </>
              )
            }
          })()
        }
          <p/>
          <p className = 'note-to-users'>This site will be using your camera and artificial intelligence to play with. None of your data will be sent or stored anywhere.</p>                    
        </div>
        <Link 
          className = 'gally-left-arrow-button1' 
          to='/' 
          aria-label='Home page' 
          role='button'
        >
          Back
        </Link>
      </div>
    </div>
  );
}