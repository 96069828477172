import React, { useState, useEffect, useRef, useCallback} from 'react';

// 3D animation
import HandAnimation from './handAnimation.js';

import wordsToSelect from './assets/words.json';
import { HAND_CONNECTIONS, Holistic } from '@mediapipe/holistic';
import { drawConnectors } from '@mediapipe/drawing_utils';
import {Camera} from "@mediapipe/camera_utils";
import MatchingLetter from './classFingerMatch.js';
import './fingerspelling_match.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHand } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';

// Look at this for toon effect: https://github.com/Kirilbt/hand-armature/blob/main/main.js
const Fingerspelling = ({getOpenCloseMenu, isOpen, socket}) => {
  // UseStates
  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState(null);
  const [recordingStatus, setRecordingStatus] = useState('inactive');
  const [videoChunks, setVideoChunks] = useState([]);
  const [recordedVideo, setRecordedVideo] = useState(null);

  const [whichHand, setWhichHand] = useState([false,null]);
  const [seenHand, setSeenHand] = useState(false);
  
  const [isNext,setNext] = useState(false);
  const [letters, setLetters] = useState(['A']);
  const [letterID, setLetterID] = useState(0);
  const [handPercentage, setHandPercentage] = useState(0);
  const [shouldIncrement, setShouldIncrement] = useState(false);
  const [updateFingers,setUpdateFingers] = useState({
    topthumb: false,
    thumb: false,
    index: false,
    middle: false, 
    ring: false, 
    little: false
  });

  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isMatched, setMatched] = useState(false);

  const [cnnLoaded, setCNN_Load] = useState(false);

  const [reactiveHand,setReactiveHand] = useState(null);
  const [isShowCanvas,setShowCanvas] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);

  // useRef
  const mediaRecorderRef = useRef(null);
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const currentLetter = useRef('a');
  const currentList = useRef([]);
  const initialValue = useRef(null);
  const holisticRef = useRef(null);
  const cameraRef = useRef(null);

  // Check type of video recording to support
  var video_type; 

  useEffect(() => {
    const types = [
      "video/mp4",
      "video/webm"
    ];
    
    for (const type of types) {
      if(MediaRecorder.isTypeSupported(type) === true){// eslint-disable-next-line
        video_type = type;
      }
    }
  },[]);

  useEffect(() => {
    if (webcamRef.current && stream) {
      webcamRef.current.srcObject = stream;
    }
  }, [stream]);

  // Select Hand
  const clickLeftHand = () => {
    setWhichHand([true,'leftHand']) // Not typo!
  };

  const clickRightHand = () => {
    setWhichHand([true,'rightHand']) // Not typo!
  };

  // Implement Alphabet match
  const colorWhenFalse = '#f4f9f780';
  const colorWhenTrue = '#EFCA77'
  function getColorForConnection(i, j, fingerMatches) {
    // Define color groups for different parts of the hand
    const colorGroups = fingerMatches ? {
      thumb: fingerMatches.thumb && fingerMatches.topthumb ? colorWhenTrue : colorWhenFalse,
      indexFinger: fingerMatches.index ? colorWhenTrue : colorWhenFalse,
      middleFinger: fingerMatches.middle ? colorWhenTrue : colorWhenFalse,
      ringFinger: fingerMatches.ring ? colorWhenTrue : colorWhenFalse,
      pinky: fingerMatches.little ? colorWhenTrue : colorWhenFalse,
      palm: fingerMatches.thumb ? colorWhenTrue : colorWhenFalse,
    } : {
      thumb: colorWhenFalse,
      indexFinger:  colorWhenFalse,
      middleFinger: colorWhenFalse,
      ringFinger: colorWhenFalse,
      pinky: colorWhenFalse,
      palm: colorWhenFalse,
    };
  
    // Assign colors based on the landmark indices
    if (i <= 4 || j <= 4) return colorGroups.thumb;
    if (i >= 5 && i <= 8) return colorGroups.indexFinger;
    if (i >= 9 && i <= 12) return colorGroups.middleFinger;
    if (i >= 13 && i <= 16) return colorGroups.ringFinger;
    if (i >= 17 && i <= 20) return colorGroups.pinky;
    return colorGroups.palm;
  }

  function handleFingerMatch(finger) {
    const isLength = initialValue.current?.length ?? 0;

    if (currentLetter.current === 'j' && finger && finger.value !== undefined) {
      if (initialValue.current === null) {
        initialValue.current = finger.value;
      } else {
        // Determine if the task is complete
        const taskComplete = Math.abs(initialValue.current - finger.value) > 10;
        setNext(taskComplete);

        // Only reset initialValue.current if the task is complete
        if (taskComplete) {
          initialValue.current = null;
        }
      }
    } else if (currentLetter.current === 'z' && finger && finger.value !== undefined) {
      if (initialValue.current === null) {
        initialValue.current = [finger.value[0]];
      } else if (isLength === 1) {
        if (Object.values(finger).filter(x => typeof x === 'boolean').every(x => x === true)) {
          if (Math.abs((finger.value[0].x - initialValue.current[0].x)) / finger.value[1] > 1) {
            initialValue.current.push(finger.value[0]);
          }
        }
      } else if (isLength === 2) {
        if (
          Math.abs((finger.value[0].x - initialValue.current[0].x)) / finger.value[1] > 0.5 &&
          Math.abs((finger.value[0].y - initialValue.current[0].y)) / finger.value[1] > 0.5
        ) {
          initialValue.current.push(finger.value[0]);
        }
      } else if (isLength === 3) {
        if (Math.abs((finger.value[0].x - initialValue.current[0].x)) / finger.value[1] > 0.5) {
          setNext(true);
          initialValue.current = null;
        }
      }
    } else if (finger && Object.values(finger).filter(x => typeof x === 'boolean').every(x => x === true)) {
      setNext(true);
    }
  }

  // Reactive hand when hovering over buttons
  const getLeftHand = () => {
    setReactiveHand('l')
  }

  const getCHand = () => {
    setReactiveHand('c')
  }

  const getRightHand = () => {
    setReactiveHand('r')
  }

  const getOriginalHand = () => {
    setReactiveHand(null);
  }

  // Word to Select
  useEffect(() => {
    const randomChooseWord = () => {
      const index = Math.floor(Math.random()*wordsToSelect.length);
      const word = wordsToSelect[index];
      setLetters(word.toUpperCase().split(''));
      currentLetter.current = word[0];
    };
    if(isOpen){
      randomChooseWord();
    }
  },[isOpen])

  const clearInputAndReset = useCallback(() => {
    initialValue.current = null;
    currentList.current = [];
    setLetterID(0);
    setLetters([]);
    currentLetter.current = 'a';
  }, []);

  // Handpose estimation
  const onResults = useCallback((results) => {
    const spellDetect = new MatchingLetter();
    const webcam = webcamRef.current;
    const canvas = canvasRef.current;
    const aspectRatio = webcam.videoWidth / webcam.videoHeight;
    if (!webcam || !canvas) return;
  
    const canvasCtx = canvas.getContext("2d");
    if (!canvasCtx) throw new Error('Could not get context');
  
    const videoWidth = window.innerHeight*aspectRatio;
    const videoHeight = window.innerHeight;
    canvas.width = videoWidth;
    canvas.height = videoHeight;
  
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, videoWidth, videoHeight);
  
    // Simplified composite operations
    canvasCtx.globalCompositeOperation = 'source-over';
    canvasCtx.fillRect(0, 0, videoWidth, videoHeight);
    canvasCtx.drawImage(results.image, 0, 0, videoWidth, videoHeight);

    canvasCtx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Adjust opacity as needed
    canvasCtx.fillRect(0, 0, videoWidth, videoHeight);

    // Apply grayscale effect
    const imageData = canvasCtx.getImageData(0, 0, videoWidth, videoHeight);
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      // Calculate the weighted average
      const avg = (data[i] * 0.299 + data[i + 1] * 0.587 + data[i + 2] * 0.114);
      
      // Apply a darkening factor (adjust this value to control darkness)
      const darkeningFactor = 0.7; // Values less than 1 will darken the image
      const darkenedValue = Math.round(avg * darkeningFactor);
      
      // Ensure the value doesn't go below 0
      const finalValue = Math.max(0, darkenedValue);
      
      data[i] = finalValue;     // r
      data[i + 1] = finalValue; // g
      data[i + 2] = finalValue; // b
    }
    canvasCtx.putImageData(imageData, 0, 0);
    
    const drawHand = (hand) => {
      const landmarks = results[`${hand}Landmarks`];
      if (landmarks) {
        setSeenHand(true);
        const letterObject = spellDetect.getSpecificLetter(currentLetter.current);
        const fingerMatches = spellDetect.isMatchingLetter(letterObject,landmarks);
        setUpdateFingers(fingerMatches);

        HAND_CONNECTIONS.forEach((connection) => {
          const [i, j] = connection;
          const color = getColorForConnection(i, j, fingerMatches);
          drawConnectors(
            canvasCtx, 
            [landmarks[i], landmarks[j]], 
            [[0, 1]],
            {color: color, lineWidth: 5}
          );
        });
      }
    };
  
    drawHand(whichHand[1], '#EFCA77');
  
    canvasCtx.restore();
    setCNN_Load(true);
  },[whichHand]);

  const initializeHolistic = useCallback(async() => {
    if(whichHand[0]){
      //if (holisticRef.current) {
      //  await holisticRef.current.close();
      //  holisticRef.current = null; // Clear reference
      //}
      if(!holisticRef.current){
        holisticRef.current = new Holistic({
          locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`;
          }
        });
      }
      
      if (holisticRef.current) {
        // Reset parameters as needed
        await holisticRef.current.setOptions({
          modelComplexity: 0,
          smoothLandmarks: true,
          enableSegmentation: false,
          minDetectionConfidence: 0.5,
          minTrackingConfidence: 0.5,
        });
      }
      holisticRef.current.onResults(onResults);
    }
  },[onResults,whichHand]);



  // Video Recording Functions
  const getCameraPermission = async() => {
    if('MediaRecorder' in window){
      try{
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true,
        });
        setPermission(true);
        setStream(streamData);
      }catch(err){
        console.log(err.message);
      }
    }else{
      console.log('The MediaRecorder API is not supported in your browser.')
    }
  }

  const startRecording = async() => {
    setRecordingStatus('recording');
    const media = new MediaRecorder(stream, {mimeType: video_type});
    mediaRecorderRef.current = media;
    mediaRecorderRef.current.start();

    let localVideoChunks = [];
    mediaRecorderRef.current.ondataavailable = (event) => {
      if(typeof event.data === 'undefined') return;
      if (event.data.size === 0) return;
      localVideoChunks.push(event.data);
    }
    setVideoChunks(localVideoChunks);
  }

  const stopRecording = () => {
    setRecordingStatus('inactive');
    mediaRecorderRef.current.stop();

    mediaRecorderRef.current.onstop = () => {
      const videoBlob = new Blob(videoChunks, { type: 'video/webm' });
      const videoUrl = URL.createObjectURL(videoBlob);
      setRecordedVideo(videoUrl);
      setVideoChunks([]);
    }
  }

  const startCamera = useCallback(async () => {
    const constraints = {
      video: {
        width: { min: 640, ideal: 1280, max: 1920 },
        height: { min: 480, ideal: 720, max: 1080 },
        frameRate: { min: 10, ideal: 30, max: 60 },
        facingMode: "user"
      },
      audio: false
    }
    try { 
      const streamData = await navigator.mediaDevices.getUserMedia(constraints);
      setStream(streamData);
      if (webcamRef.current) {
        webcamRef.current.srcObject = streamData;
      }
      
      // Initialize Holistic
      await initializeHolistic();
      
      // Start camera
      if (webcamRef.current && holisticRef.current) {
        cameraRef.current = new Camera(webcamRef.current, {
          onFrame: async () => {
            if (!holisticRef.current) return;
            await holisticRef.current.send({image: webcamRef.current});
          },
        });
        await cameraRef.current.start();
      }
    } catch (error) {
      console.error('Error starting camera:', error);
    }
  }, [initializeHolistic]);

  const stopCamera = useCallback(async() => {
    try {
      if (cameraRef.current) {
        await cameraRef.current.stop();
        cameraRef.current = null; // Clear reference
      }

      if (stream) {
        stream.getTracks().forEach(track => track.stop());
        setStream(null);
      }
      if (webcamRef.current) {
        webcamRef.current.srcObject = null;
      }

      console.log('Camera and Holistic stopped successfully');
    } catch (error) {
      console.error('Error stopping camera and Holistic:', error);
    }
  }, [stream]);

  // Handle fingerspelling in each letter
  useEffect(() => {
    // Handle J or Z or not
    handleFingerMatch(updateFingers); 
  },[updateFingers]);
  
  const incrementLetter = useCallback(() => {
    setLetterID(prevID => {
      if (prevID < letters.length - 1) {
        const newID = prevID + 1;
        currentLetter.current = letters[newID];
        return newID;
      }else{
        clearInputAndReset();
      }
      return prevID; // Don't increment if we're at the end of the array
    });
    setNext(false);
  }, [letters,clearInputAndReset]);

  useEffect(() => {
    const array = Object.values(updateFingers).filter(x => typeof x === 'boolean');
    if (isNext & array.every(x => x === true)) {
      setShouldIncrement(true);
      if (!startTime) {
        setStartTime(Date.now());
      }
    }
    setHandPercentage(
      Math.round(
        array.reduce((acc, curr) => acc + Number(curr), 0)/array.length*100
      )
    );
  }, [isNext, updateFingers, startTime]);

  useEffect(() => {
    let intervalId;

    if (shouldIncrement) {
      if (!startTime) {
        setStartTime(Date.now());
      }

      intervalId = setInterval(() => {
        const currentTime = Date.now();
        const elapsedSeconds = (currentTime - startTime) / 1000; // Convert to seconds
        setElapsedTime(Number(elapsedSeconds.toFixed(2))); // Limit to 2 decimal places
      }, 10); // Update every 10ms for smooth display

      if(elapsedTime > 1){
        incrementLetter();
        setShouldIncrement(false);
        setMatched(false);
      }else if(elapsedTime > 0.4){
        setMatched(true)
      }
    } else {
      setStartTime(null);
      setElapsedTime(0);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [shouldIncrement, incrementLetter, startTime, elapsedTime]);

  useEffect(() => {
    if(cnnLoaded){
      setShowCanvas(true);
    }else{
      setShowCanvas(false);
    }
  },[cnnLoaded]);

  // Time Count Down
  const [startTimer,setStartTimer] = useState(null);
  useEffect(() => {
    if(seenHand){
      setStartTimer(Date.now());
    }
  },[seenHand])

  useEffect(() => {
    if (!seenHand) return;
    const endTime = startTimer + 30000;

    const intervalId = setInterval(() => {
      const now = Date.now();
      const remaining = endTime - now;

      if(remaining <= 0) {
        clearInterval(intervalId);
        setTimeLeft(30);
        setSeenHand(false);
      }else{
        setTimeLeft(prev => 
          prev < 10 ? 
            '0'+Math.floor(remaining/1000) :
            Math.floor(remaining/1000));
      }
    }, 1);

    return () => clearInterval(intervalId);
  }, [timeLeft,startTimer,seenHand]);

  // Get Extra Turn or Not
  useEffect(() => {
    if(timeLeft === '00'){
      socket.emit('fingerspelling', true);
      socket.emit('message', {message: 'Enemy failed to reload.'});
      getOpenCloseMenu(false);
      setSeenHand(false);
      setTimeLeft(30);
    }
  },[socket,getOpenCloseMenu,timeLeft])

  useEffect(() => {
    if(letters.length === 0 && seenHand){
      socket.emit('fingerspelling', false);
      socket.emit('message', {message: 'Enemy ready with extra strike!'});
      getOpenCloseMenu(false);
      setSeenHand(false);
      setTimeLeft(30);
    }
  },[timeLeft,letters,seenHand,getOpenCloseMenu,socket]);

  const loseExtraTurn = () => {
    socket.emit('fingerspelling', true);
    socket.emit('message', {message: 'Enemy skips the extra strike.'});
  }

  useEffect(() => {
    if(permission){
      startCamera();
    }
  },[startCamera,permission]);

  // If disconnected, stop camera
  socket.on('victory', () => {
    stopCamera();
  })

  socket.on('PlayerLeft', () => {
    stopCamera();
  })

  return(
    <div className = 'handAnimation-background' style = {{marginLeft: isOpen ? 0 : '200%'}}>
      <button onClick={() => [getOpenCloseMenu(false),loseExtraTurn()]} className = 'close-menu' style={{display: whichHand[0] ? 'none' : 'unset', right: 0}}>
        <FontAwesomeIcon icon = {faArrowLeft}/>
      </button>
      {!permission ? (
        <button onClick={getCameraPermission}>Get Camera Permission</button>
      ) : null}
      {permission && !whichHand[0] ?
        (
          <div>
            <button onClick={clickLeftHand}>Left</button>
            <button onClick={clickRightHand}>Right</button>
          </div>
        ) : null
      }
      {permission && whichHand[0] && recordingStatus === "inactive" ? (
        <button onClick={startRecording} style={{padding: '20px'}}>Start Recording</button>
      ) : null}
      {recordingStatus === "recording" ? (
        <button onClick={stopRecording}>Stop Recording</button>
      ) : null}

      {permission && whichHand[0] && stream ? (
        <>
        <div className = 'fingerspelling-video-container'
          style = {{
            width: webcamRef.current?.videoWidth === undefined ? 200 : webcamRef.current.videoWidth,
            height: webcamRef.current?.videoWidth === undefined ? 200 : webcamRef.current?.videoHeight
          }}
        >
        </div>
        </>
      ) : null}

      {recordedVideo ? (
        <div>
          <video src={recordedVideo} autoPlay loop muted />
          <a download href={recordedVideo}>
            Download Recording
          </a>
        </div>
      ) : null}
      <div className = 'word-to-spell'
        style = {{
          left: !whichHand[0] ? '25%' : whichHand[1] === 'rightHand' ? '50%' : '0%'
        }}
      >
        <button className = 'close-menu' style={{
            display: !cnnLoaded || !permission || !whichHand[0] ? 'none' : 'unset',
            left: whichHand[1] === 'rightHand' ? 0 : 'auto',
            right: whichHand[1] === 'rightHand' ? 'auto' : 0,
          }}
          onClick={() => [getOpenCloseMenu(false),loseExtraTurn()]} 
        >
          <FontAwesomeIcon icon = {faArrowLeft}/>
        </button>
        <div style = {{display: !cnnLoaded || !permission || !whichHand[0] ? 'none' : 'unset'}}>
          {letters.map((x,i) => (
            <span 
              key = {i} 
              className = 'letterToSpell' 
              style = {{opacity: i === letterID ? 1 : 0.3, position: 'relative'}}
            >
              {x}
            </span>
          ))}
        </div>
        <div id = 'coverHandAnimation' disabled = {!cnnLoaded || !permission || !whichHand[0]}>
          <HandAnimation className = 'hand-animation' 
            currentLetter={!cnnLoaded || !permission || !whichHand[0] ? reactiveHand : letters[letterID]} 
            width={'500px'} 
            height={'500px'}
            isCNNloaded={cnnLoaded && whichHand[0]}
            isHandSeen={seenHand}
          />
          <p><FontAwesomeIcon icon={faHourglassHalf}/>&nbsp;&nbsp;&nbsp;{`00:${timeLeft}`}</p>
        </div>
        <button 
          id = 'camera-on-button' 
          onClick={getCameraPermission} 
          disabled = {permission}
          onMouseEnter={getCHand}
          onMouseLeave={getOriginalHand}
        >
          Turn Camera On
        </button>
        <div id = 'leftRightHandWrap'>
          <button 
            id = 'leftRightButton' 
            onClick={clickLeftHand}
            onMouseEnter={getLeftHand}
            onMouseLeave={getOriginalHand}
            disabled = {!permission || whichHand[0]}
          >
            Left
          </button>
          <button 
            id = 'leftRightButton' 
            onClick={clickRightHand} 
            disabled = {!permission || whichHand[0]}
            onMouseEnter={getRightHand}
            onMouseLeave={getOriginalHand}
          >
            Right
          </button>
        </div>
      </div>
      <div id = 'hide-canvas' disabled={isShowCanvas}/>
      <div className = 'communicate-to-user'
        style = {{display: cnnLoaded && whichHand[0] ? 'flex' : 'none', left: whichHand[1] === 'rightHand' ? '0' : '50%'}}
        disabled = {!seenHand}
      >
        <div>
          <p>Show {whichHand[1] === 'rightHand' ? 'right' : 'left'} hand</p>
          <FontAwesomeIcon icon = {faHand} className = 'hand-wave'/>
        </div>
      </div>
      <div className = 'percentage-symbol'
        style = {{display: cnnLoaded ? 'flex' : 'none', left: whichHand[1] === 'rightHand' ? '0' : '50%'}}
        disabled = {!seenHand}
      >
        <p style = {{
          fontWeight: isMatched ? 900 : 300, 
          color: isMatched ? 'var(--gallyyellow)' : 'var(--whitebackground)'
          }}>
          {isMatched ? 'Matched' : handPercentage+'%'}
        </p>
      </div>
      <div className = 'video-container'>
        <video ref={webcamRef} autoPlay muted className = 'video-style'/>
        <div className='canvas-container' style = {{justifyContent: whichHand[1] === 'rightHand' ? 'start' : 'end'}}>
          <canvas 
            ref = {canvasRef} 
            style = {{
              display: !cnnLoaded ? 'none' : 'unset'
            }} 
            className = 'canvas-style'/>      
        </div>
      </div>
    </div>
  )    
}

export default Fingerspelling