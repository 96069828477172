// Tutorial for deployment to AWS Beanstalk: https://dev.to/itsrakesh/deploying-a-mern-app-to-aws-elastic-beanstalk-with-cicd-4f1j
import './App.css';

// We use Route in order to define the different routes of our application
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


 // We import all the components we need in our app
import Navbar from "./components/navbar.js";
import RecordList from "./components/recordList.js";
import VideoForKid from './components/limited-feature-for-kids.js';
import Video from "./components/video.js";
import DataRequest from './components/data-request.js';
import Create from "./components/demographics.js";
import Footer from "./components/footer.js";
import ScrollToTop from './components/scrollToTop.js';
import Fingerspell from "./components/fingerspelling_match.js";
import Battleship from './components/battleship.js';

import { Cookie, Privacy, Accessibility } from './components/policy-notices.js'; 

const App = () => {
  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Navbar/>
        <Routes>
          <Route exact path="/" element={<RecordList />} />
          <Route exact path='/video' element={<Video />} />
          <Route exact path='/vid' element={<VideoForKid/>} />
          <Route exact path="/demographics" element={<Create />} />
          <Route exact path="/fs" element={<Fingerspell />} />
          <Route exact path="/battleship" element={<Battleship />} />
          <Route exact path="/data-request" element={<DataRequest />} />
          <Route exact path="/cookie-consent-notice" element={<Cookie />} />
          <Route exact path="/accessibility" element={<Accessibility />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
