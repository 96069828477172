// https://github.com/rainmodred/react-battleship?tab=readme-ov-file

import React, { useState, useEffect, useCallback, useRef, forwardRef } from "react";
import './battleship_draggable.css';

const GameSetUp = forwardRef(({array, setOFships, abc, num, overlappedEvent,isDisabled,getAttackSpots,children},ref) => {
  // Ref from parents
  const {childButtonRef,attackSpotsRef} = ref;

  // Functions
  function extractFirstNumber(str) {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0]) : null;
  }
  
  function attackSpotsEvent(boolean,size,coord){
    // Get array for validation
    let specific_arr = [];
    if(boolean){
      specific_arr = Array.from(
        {length: size},
        (_,i) => coord[0] + i
      ).map(x => x+(coord[1]-1)*10)

      return(specific_arr)
    }else{
      specific_arr = Array.from(
        {length: size},
        (_,i) => coord[0] + i*10
      ).map(x => x+(coord[1]-1)*10)

      return(specific_arr)
    }
  }

  function attackSpotsForRotate(boolean,id,size,coord){
    // Get array for validation
    let specific_arr = [];
    if(boolean){
      specific_arr = Array.from(
        {length: size},
        (_,i) => coord[0] + i
      ).map(x => x+(coord[1]-1)*10)

      setShipData(prev => ({
        ...prev,
        attackSpots: {
          ...prev.attackSpots,
          [id]: specific_arr
        }
      }));
    }else{
      specific_arr = Array.from(
        {length: size},
        (_,i) => coord[0] + i*10
      ).map(x => x+(coord[1]-1)*10)

      setShipData(prev => ({
        ...prev,
        attackSpots: {
          ...prev.attackSpots,
          [id]: specific_arr
        }
      }));
    }
  }

  function areUnique(set1, set2) {
    return !set1.some(item => set2.includes(item));
  }
  
  // Size and coordinators of ships
  const [shipData,setShipData] = useState({
    rotate: {
      battleship: [],
      cruiser: [],
      submarine: [],
      aircraft_carrier: [],
      destroyer: [],
    },
    position: {
      battleship: [],
      cruiser: [],
      submarine: [],
      aircraft_carrier: [],
      destroyer: [],
    },
    attackSpots: {
      battleship: [],
      cruiser: [],
      submarine: [],
      aircraft_carrier: [],
      destroyer: [],
    },
    backUpPosition: []
  })

  // Random generator for battleship setting
  const randomGenerator = useCallback((shipId,arr) => {
    // Get ship size
    const shipSize = setOFships.filter(e => e.id === shipId).map(e => e.size)[0];

    // While loop until the ship position validated using these parameters
    let boolean = true;
    while (boolean) {
      // Parameters set or reset
      let colPosition = 0;
      let rowPosition = 0;
      // Implement random generator for battleships
      const isRotate =  Math.random() < 0.5;
      if(isRotate){
        colPosition = 1+Math.floor(Math.random()*(abc.length))
        rowPosition = 1+Math.floor(Math.random()*(num.length-shipSize))
      }else{
        colPosition = 1+Math.floor(Math.random()*(abc.length-shipSize))
        rowPosition = 1+Math.floor(Math.random()*(num.length))
      }

      const shipArr = attackSpotsEvent(!isRotate,shipSize,[colPosition,rowPosition]);

      // Validate if one array is unique of another arr
      if(areUnique(shipArr,arr)){
        // Add that values to useStates
        setShipData(prev => ({
          ...prev,
          attackSpots: {
            ...prev.attackSpots,
            [shipId]: shipArr
          },
          position: {
            ...prev.position,
            [shipId]: [colPosition,rowPosition]
          }
        }));

        if(isRotate){
          setShipData(prev => ({
            ...prev,
            rotate: {
              ...prev.rotate,
              [shipId]: [true,'rotate(90deg) translateY(44px)']
            }
          }));
        }else{
          setShipData(prev => ({
            ...prev,
            rotate: {
              ...prev.rotate,
              [shipId]: [false,'rotate(0deg) translateY(0)']
            }
          }))
        }
        boolean = false;
        // Send array to parent function
        return([...shipArr,...arr])
      }
    }
  // eslint-disable-next-line
  },[]);

  const randomAllShipGenerator = useCallback(() => {
    const shipIDs = setOFships.map(e => e.id)
    let arr = [];
    for(let i = 0; i < shipIDs.length; i++){
      arr = randomGenerator(shipIDs[i],arr)
    }
  // eslint-disable-next-line
  },[randomGenerator])

  useEffect(() => {
    randomAllShipGenerator();
  },[randomAllShipGenerator]);

  const handleRandomGenerator = () => {
    if(!isDisabled){
      randomAllShipGenerator();
    }
  };
  
  // Handle drag
  const [draggedID, setDraggedID] = useState(null);
  const [isDrag,setDrag] = useState(false);
  const [dropIndicator, setDropIndicator] = useState([]);
  const [isExecuting, setExecuting] = useState(false);
  const gridRef = useRef(null);

  const handleDragStart = (e, id, size) => {
    e.dataTransfer.setData("application/json", JSON.stringify({id: id.toString(), size: size}));
    setDrag(id);
    setDraggedID(id);
  };

  const handleDragOver = async(e) => {
    e.preventDefault();

    // Get parameters
    const shipSize = setOFships.filter(e => e.id === draggedID).map(e => e.size)[0];
    const rect = gridRef.current?.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    // Get column and row for grid
    const columnWidth = rect.width / extractFirstNumber(gridRef.current.style.gridTemplateColumns);
    const rowHeight = rect.height / extractFirstNumber(gridRef.current.style.gridTemplateRows);
    const column = Math.floor(x / columnWidth) + 1;
    const row = Math.floor(y / rowHeight) + 1;

    // Prevent from dragging ship beyond boundary
    if(
      (!shipData.rotate[draggedID][0] && 
        column+shipSize-1 <= abc.length && 
        row <= num.length && 
        column >= 0 && 
        row >= 0
      ) || (shipData.rotate[draggedID][0] && 
        row+shipSize-1 <= num.length && 
        column <= abc.length && 
        row >= 0 && 
        column >= 0
      )
    ){
      setShipData(prev => ({
        ...prev,
        backUpPosition: [column,row]
      }))
    }

    // Set array to highlight where to drop
    if(!shipData.rotate[draggedID][0]){
      setDropIndicator(Array.from(
        {length: shipSize},
        (_,i) => column + i
      ).map(x => abc[x-1]+row)); 
    }else{
      setDropIndicator(Array.from(
        {length: shipSize},
        (_,i) => row + i
      ).map(x => abc[column-1]+x)); 
    }
  }

  const handleDrop = async(e) => {
    // Instantiate and get id and size
    const taskId = await JSON.parse(e.dataTransfer.getData("application/json"));

    // Set the position based on dragover
    setShipData(prev => ({
      ...prev,
      position: {
        ...prev.position,
        [taskId.id]: shipData.backUpPosition
      }
    }));

    attackSpotsForRotate(!shipData.rotate[taskId.id][0],taskId.id,taskId.size,shipData.backUpPosition,false)
  }

  const handleDragEnd = (e) => {
    e.dataTransfer.clearData();
    setDrag(null);
    //setDraggedID(null);
    setDropIndicator([]);
  }

  // Handle Double and Right Click
  const rotateEvent = (id) => {
    // Get parameters
    const shipSize = setOFships.filter(e => e.id === id).map(e => e.size)[0];

    // useStates
    if(
      shipData.position[id][0]+shipSize-1 <= abc.length && 
      shipData.position[id][1]+shipSize-1 <= num.length
    ){
      setShipData(prev => ({
        ...prev,
        rotate: {
          ...prev.rotate,
          [id]: shipData.rotate[id][0] ? [false,'rotate(0deg) translateY(0)'] : [true,'rotate(90deg) translateY(44px)']
        }
      }));

      attackSpotsForRotate(shipData.rotate[id][0],id,shipSize,shipData.backUpPosition)
    }else{
      // Prevent from clicking more than once because users can click multiple to put battleships out of boundary
      if(isExecuting) return;
      setExecuting(true);

      setShipData(prev => ({
        ...prev,
        rotate: {
          ...prev.rotate,
          [id]: shipData.rotate[id][0] ? [false,'rotate(80deg) translateY(34px)'] : [true,'rotate(10deg) translateY(10px)']
        }
      }));

      setTimeout(()=>{
        setShipData(prev => ({
          ...prev,
          rotate: {
            ...prev.rotate,
            [id]: !shipData.rotate[id][0] ? [false,'rotate(0deg) translateY(0)'] : [true,'rotate(90deg) translateY(44px)']
          }
        }));

        setExecuting(false);
      },200)
    }
  }

  const handleDoubleClick = (id) => {if(!isDisabled){rotateEvent(id)}}
  const handleRightClick = (id) => {if(!isDisabled){rotateEvent(id)}}

  // Detect if battleship being overlapped
  useEffect(() => {
    let unique_arr = [];
    unique_arr = [...new Set(Object.values(shipData.attackSpots).flat())]
    overlappedEvent(unique_arr.length !== Object.values(shipData.attackSpots).flat().length);
  },[shipData.attackSpots,overlappedEvent]);

  // Pass attack array to parent and to grandparent
  const handleAttackSpots = () => {
    const attackArray = Object.values(shipData.attackSpots).flat()
    if(attackArray !== undefined){
      getAttackSpots(Object.values(shipData.attackSpots).flat());
    }
  };

  return(
    <>
      <button ref={childButtonRef} onClick={handleRandomGenerator} style = {{display: 'none' }}/>
      <button ref={attackSpotsRef} onClick={handleAttackSpots} style = {{display: 'none' }}/>
      <div className = 'battleship-drag-grid'>
        {array.map(x => {
          return(
            <div 
              key = {x} 
              id={x}
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e,x)}
              style={{background: dropIndicator.includes(x) ? 'var(--setuphover)' : 'transparent', borderRadius: '5px'}}
              className='dropIndicator'
            />
          )
        })}
      </div>
      <div className = 'battleship-draggable-grid' 
        style={{gridTemplateColumns: 'repeat(10,44px)', gridTemplateRows: 'repeat(9,44px)'}} 
        ref = {gridRef}
        onDrop={(e) => handleDrop(e)}
        onDragOver={handleDragOver}
      >
        {setOFships.map(x => {
          return(
            <div 
              className = 'set-of-ships' 
              id = {x.id} key = {x.id}
              style = {{ 
                gridColumn: shipData.position[x.id][0]+' / '+shipData.position[x.id][0]+x.size, 
                gridRow: shipData.position[x.id][1]+' / '+shipData.position[x.id][1]+1, 
                opacity: isDrag === x.id ? '50%' : '100%',
                cursor: isDisabled ? 'not-allowed' : isDrag === x.id ? 'grabbing' : 'grab',
                transform: shipData.rotate[x.id][1],
                transformOrigin: '44px 100%'
              }}
              draggable={!isDisabled}
              onDragStart={(e) => handleDragStart(e, x.id, x.size)}
              onDragEnd={handleDragEnd}
              onDoubleClick={() => handleDoubleClick(x.id)}
              onContextMenu={() => handleRightClick(x.id)}
              disabled = {isExecuting}
            />
          )
        })}
      </div>
      <div className='battleship-drag-grid-bullets' style = {{display: isDisabled ? 'grid' : 'none'}}>
        {children}
      </div>
    </>
  )
})

export default GameSetUp;
  