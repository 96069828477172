/*eslint-disable*/
import { createRef, useState, useCallback } from 'react';
import { ERROR_MESSAGES, DEFAULT_RECORDER_OPTIONS } from './constants.js';

export let STATUS;(function(STATUS) {
  STATUS["INITIAL"] = "INITIAL"
  STATUS["CLOSED"] = "CLOSED"
  STATUS["OPEN"] = "OPEN"
  STATUS["RECORDING"] = "RECORDING"
  STATUS["STOPPED"] = "STOPPED"
  STATUS["ERROR"] = "ERROR"
  STATUS["PAUSED"] = "PAUSED"
})(STATUS || (STATUS = {}));

export function createRecording({ videoId, videoLabel }) {
  // Increase flexibility in video recording
  var string_mimeType = DEFAULT_RECORDER_OPTIONS.mimeType.toString()

  const recordingId = `${videoId}`
  let onDataAvailableResolve = null
  const onDataAvailablePromise = new Promise(resolve => {
    onDataAvailableResolve = resolve
  })

  const recording = {
    id: recordingId,
    fileName: String(new Date().getTime()),
    fileType: string_mimeType,
    isMuted: false,
    mimeType: DEFAULT_RECORDER_OPTIONS.mimeType,
    objectURL: null,
    onDataAvailableResolve,
    onDataAvailablePromise,
    previewRef: createRef(),
    recorder: null,
    status: STATUS.INITIAL,
    videoId,
    videoLabel,
    webcamRef: createRef()
  }
  return recording;
}

const recordingMapRef = new Map();

export function useRecording(isDevMode) {
  const [activeRecordings, setActiveRecordings] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)

  const updateActiveRecordings = async () => {
    const recordings = Array.from(recordingMapRef.values());
    setActiveRecordings(recordings);
  }

  const isRecordingCreated = useCallback(
    recordingId => {
      const isCreated = recordingMapRef.get(recordingId)
      return Boolean(isCreated)
    },
    [recordingMapRef]
  )

  const getRecording = useCallback(
    recordingId => {
      const recording = recordingMapRef.get(recordingId)
      if (!recording) {
        throw new Error(ERROR_MESSAGES.BY_ID_NOT_FOUND)
      }
      return recording
    },
    [recordingMapRef]
  )


  const setRecording = useCallback(
    async params => {
      const recording = createRecording(params)
      recordingMapRef.set(recording.id, recording)
      await updateActiveRecordings()
      return recording
    },
    [recordingMapRef]
  )

  var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
      function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
      function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };

  const updateRecording = useCallback(
    (recordingId, updatedValues) => __awaiter(void 0, void 0, void 0, function* () {
      const recording = recordingMapRef.get(recordingId);
      recordingMapRef.set(recordingId, Object.assign(Object.assign({}, recording), updatedValues));
      yield updateActiveRecordings();
      return getRecording(recordingId);
    }), [recordingMapRef, setRecording, getRecording]
  );

  const deleteRecording = useCallback(
    async recordingId => {
      recordingMapRef.delete(recordingId)
      await updateActiveRecordings()
    },
    [recordingMapRef]
  );

  const clearAllRecordings = async () => {
    Array.from(recordingMapRef.values()).forEach((recording) => {
      const stream = recording.webcamRef.current?.srcObject;
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    });
    recordingMapRef.clear();
    setActiveRecordings([]);
  };

  const handleError = async (functionName, error, recordingId) => {
    if (isDevMode) {
      console.error(`@${functionName}: `, error)
    }
    if (recordingId) {
      const recording = getRecording(recordingId)
      setErrorMessage({ recordingId: recordingId, error })
      if (recording) recording.status = STATUS.ERROR
    }
    throw error
  }

  return {
    activeRecordings,
    clearAllRecordings,
    deleteRecording,
    errorMessage,
    getRecording,
    handleError,
    isRecordingCreated,
    setRecording,
    updateRecording,
  };
}
