// Integrate ABC function from Fingerspelling.xyz
class Alphabet {
  constructor() {
      this.alphabetArray = [];
      this._helpTexts = { straight: 'Straigthen it', bend: 'Bend it' };
      this.getLetter = () => { };
      this.getRandomLetter = () => {
          var getRandom = Math.floor(Math.random() * this.alphabetArray.length);
          return this.alphabetArray[getRandom];
      };
      this.getSpecificLetter = findLetter => {
          var length = this.alphabetArray.length;
          for (var i = 0; i < length; i++) {
            var currentLetter = this.alphabetArray[i];
            var getLetterName = currentLetter.letter;
            if (getLetterName.toLowerCase() === findLetter.toLowerCase()) {
              return currentLetter;
            }
          }
      };

      const noCurl = 180;
      const almostNoCurl = 170;
      const littleCurl = 150;
      const halfCurlMin = 130;
      const fullCurlMinMuchMoreForgiving = 110;
      const fullCurlMinMoreForgiving = 90;
      const fullCurlMin = 60;
      const fullCurlMax = 0;

      //const minFacingPitch = 80;
      //const maxFacingPitch = 310;
      //const minFacingRoll = 90;
      //const maxFacingRoll = 320;
      //const minFacingYaw = 120;
      //const maxFacingYaw = 250;
       
      const a = {
          letterNumber: 1,
          letter: 'a',

          //pitch: { minValue: minFacingPitch, maxValue: maxFacingPitch },
          //roll: { minValue: minFacingRoll, maxValue: maxFacingRoll },
          //yaw: { minValue: minFacingYaw, maxValue: maxFacingYaw },

          topthumb: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: 'Straigten it - and make sure it is close to index finger' },
          index: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          middle: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          ring: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          little: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend }
      };
      const b = {
          letterNumber: 2,
          letter: 'b',
          rotation: 'up',
          topthumb: { curlMin: almostNoCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'thumbBendOverOtherFingers', percentageCorrect: 0, currentAngle: null, helpText: 'Bend it so its inside your palm' },
          index: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          middle: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          ring: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          little: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight }
      };
      const c = {
          letterNumber: 3,
          letter: 'c',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: {
              curlMin: noCurl,
              curlMax: fullCurlMax,
              special: 'none',
              percentageCorrect: 0,
              currentAngle: null,
              helpText: "Bend it - but make sure it doesn't touch your pointer finger"
          },
          index: { curlMin: halfCurlMin + 15, curlMax: 100, special: 'distanceBetweenThumbAndPointer', fingerTop: 8, percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          middle: { curlMin: halfCurlMin + 15, curlMax: 100, special: 'distanceBetweenThumbAndPointer', fingerTop: 12, percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          ring: { curlMin: halfCurlMin + 15, curlMax: 100, special: 'distanceBetweenThumbAndPointer', fingerTop: 16, percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          little: { curlMin: almostNoCurl, curlMax: 100, special: 'distanceBetweenThumbAndPointer', fingerTop: 20, percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend }
      };
      const d = {
          letterNumber: 4,
          letter: 'd',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          index: { curlMin: noCurl, curlMax: almostNoCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          middle: { curlMin: noCurl, curlMax: fullCurlMax, special: 'touchFingertoThumb',  fingerTop: 12, percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          ring: { curlMin: littleCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          little: { curlMin: littleCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend }
      };
      /*
      changed this 'thumbBendOverOtherFingersAndUnderOtherFingers' to something else
       */
      const e = {
          letterNumber: 5,
          letter: 'e',
          rotation: 'up',
          topthumb: { curlMin: littleCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: 'Bend it so its placed below the other fingers' },
          index: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          little: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend }
      };
      const f = {
          letterNumber: 6,
          letter: 'f',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: 'Pinch the thumb and pointer finger together' },
          index: { curlMin: noCurl, curlMax: fullCurlMax, special: 'touchFingertoThumb', fingerTop: 8, percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          ring: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          little: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight }
      };
      // FIXME - Need to look for rotation
      /*
      Removed from thumb:
              special: 'thumbPointerAlignOnYAxis',
       */
      const g = {
          letterNumber: 7,
          letter: 'g',
          rotation: 'side',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, percentageCorrect: 0, special: 'none', currentAngle: null, helpText: 'Make sure its straight and aligns with the pointer finger' },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'indexBaseBending', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.straight },
          middle: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend }
      };
      const h = {
          letterNumber: 8,
          letter: 'h',
          rotation: 'side',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: littleCurl, special: 'indexToMiddle', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const i = {
          letterNumber: 9,
          letter: 'i',
          rotation: 'up',
          topthumb: { curlMin: littleCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      // FIXME - add motion detection on the J
      const j = {
          letterNumber: 10,
          letter: 'j',
          rotation: 'up',
          special: 'motion_j',
          topthumb: { curlMin: littleCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const k = {
          letterNumber: 11,
          letter: 'k',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'distanceBetweenIndexandMiddle', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const l = {
          letterNumber: 12,
          letter: 'l',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const m = {
          letterNumber: 13,
          letter: 'm',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'betweenRingAndLittleBase', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const n = {
          letterNumber: 14,
          letter: 'n',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'betweenMiddleAndRing', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: almostNoCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const o = {
          letterNumber: 15,
          letter: 'o',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: halfCurlMin - 30, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: halfCurlMin, curlMax: 10, special: 'pinchThumbAndPointer', fingerTop: 8, percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: halfCurlMin, curlMax: 10, special: 'pinchThumbAndPointer', fingerTop: 12, percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: halfCurlMin, curlMax: 10, special: 'pinchThumbAndPointer', fingerTop: 16, percentageCorrect: 0, currentAngle: null },
          little: { curlMin: almostNoCurl, curlMax: 10, special: 'none', fingerTop: 20, percentageCorrect: 0, currentAngle: null }
      };
      const p = {
          letterNumber: 16,
          letter: 'p',
          rotation: 'down',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'distanceBetweenIndexandMiddle', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const q = {
          letterNumber: 17,
          letter: 'q',
          rotation: 'down',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'thumbPointerAlignOnYAxis', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: 100, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const r = {
          letterNumber: 17,
          letter: 'r',
          rotation: 'up',
          topthumb: { curlMin: littleCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'crossIndexAndMiddle', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const s = {
          letterNumber: 18,
          letter: 's',
          rotation: 'up',
          topthumb: { curlMin: littleCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'betweenIndexAndMiddleLetterS', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const t = {
          letterNumber: 18,
          letter: 't',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'betweenIndexAndMiddleLetterT', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const u = {
          letterNumber: 19,
          letter: 'u',
          rotation: 'up',
          topthumb: { curlMin: littleCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'indexToMiddle', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: almostNoCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const v = {
          letterNumber: 20,
          letter: 'v',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: almostNoCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'distanceBetweenIndexandMiddle', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const w = {
          letterNumber: 21,
          letter: 'w',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'thumbBendOverOtherFingers', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'indexAndMiddleAndRingMustBeApart', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: noCurl, curlMax: halfCurlMin, special: 'indexAndMiddleAndRingMustBeApart', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: noCurl, curlMax: halfCurlMin, special: 'indexAndMiddleAndRingMustBeApart', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: halfCurlMin, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const x = {
          letterNumber: 22,
          letter: 'x',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: halfCurlMin + 20, curlMax: fullCurlMin + 20, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: fullCurlMinMuchMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      const y = {
          letterNumber: 23,
          letter: 'y',
          rotation: 'up',
          topthumb: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: noCurl - 30, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: fullCurlMinMoreForgiving, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: noCurl, curlMax: littleCurl, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      // FIXME : Needs a movement in a Z shape
      const z = {
          letterNumber: 24,
          letter: 'z',
          rotation: 'up',
          special: 'motion_z',
          topthumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null, helpText: this._helpTexts.bend },
          thumb: { curlMin: noCurl, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          index: { curlMin: noCurl, curlMax: halfCurlMin, special: 'none', percentageCorrect: 0, currentAngle: null },
          middle: { curlMin: littleCurl+10, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          ring: { curlMin: littleCurl+10, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null },
          little: { curlMin: littleCurl+10, curlMax: fullCurlMax, special: 'none', percentageCorrect: 0, currentAngle: null }
      };
      // Missing z,p,q,j,h
      this.alphabetArray = [a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z];
  }
}

export default class MatchingLetter extends Alphabet{
  constructor () {
    super(); // Call parent constructor

    // Instantiate
    this.initialAngle = null;
    this.currentAngle = null;
  }

  // Math functions
  subtractVectors(v1, v2) {
    return { x: v1.x - v2.x, y: v1.y - v2.y, z: v1.z - v2.z };
  }

  crossProduct(v1, v2) {
    return {
      x: v1.y * v2.z - v1.z * v2.y,
      y: v1.z * v2.x - v1.x * v2.z,
      z: v1.x * v2.y - v1.y * v2.x
    };
  }

  dotProduct(v1, v2) {
    return v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
  }

  normalizeVector(v) {
    const magnitude = Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z);
    return { x: v.x / magnitude, y: v.y / magnitude, z: v.z / magnitude };
  }

  projectVectorOntoPlane (vector, planeNormal) {
    const normalizedPlaneNormal = this.normalizeVector(planeNormal);
    const dot = this.dotProduct(vector, normalizedPlaneNormal);
    return this.subtractVectors(vector, {
      x: normalizedPlaneNormal.x * dot,
      y: normalizedPlaneNormal.y * dot,
      z: normalizedPlaneNormal.z * dot
    });
  };

  angleBetweenVectors (v1, v2) {
    const dot = this.dotProduct(v1, v2);
    const magnitudeProduct = Math.sqrt(this.dotProduct(v1, v1) * this.dotProduct(v2, v2));
    const angleRadians = Math.acos(dot / magnitudeProduct);
    return (angleRadians * 180) / Math.PI;
  };

  calculateDistance3D (point1, point2) {
  
    // Euclidean distance
    const dx = point1.x - point2.x;
    const dy = point1.y - point2.y;
    const dz = point1.z - point2.z;

    return Math.sqrt(dx*dx + dy*dy + dz*dz);
  }
  
  normalizeDistance3D (point1, point2, normalizedValue) { // Normalized Euclidean distance
    return this.calculateDistance3D(point1,point2)/normalizedValue
  }

  calculateAngle (landmark1,landmark2,landmark3) {
    // Convert landmarks to vectors
    const v1 = {
      x: landmark1.x - landmark2.x,
      y: landmark1.y - landmark2.y,
      z: landmark1.z - landmark2.z
    };
    const v2 = {
      x: landmark3.x - landmark2.x,
      y: landmark3.y - landmark2.y,
      z: landmark3.z - landmark2.z
    };

    // Calculate dot product
    const dotProduct = v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;

    // Calculate magnitudes
    const v1Mag = Math.sqrt(v1.x * v1.x + v1.y * v1.y + v1.z * v1.z);
    const v2Mag = Math.sqrt(v2.x * v2.x + v2.y * v2.y + v2.z * v2.z);

    // Calculate angle in radians
    const angle = Math.acos(dotProduct / (v1Mag * v2Mag));

    // Convert to degrees
    return angle * (180 / Math.PI);
  }

  // Another function support
  delay (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  // Calculate angles and angle motions of hand
  calculateFingerAngles (landmarks) {
    // This is a simplified example. You'd need to implement actual angle calculations
    return {
      topthumb: this.calculateAngle(landmarks[4], landmarks[3], landmarks[2]),
      thumb: this.calculateAngle(landmarks[1], landmarks[2], landmarks[3]),
      index: this.calculateAngle(landmarks[5], landmarks[6], landmarks[7]),
      middle: this.calculateAngle(landmarks[9], landmarks[10], landmarks[11]),
      ring: this.calculateAngle(landmarks[13], landmarks[14], landmarks[15]),
      little: this.calculateAngle(landmarks[17], landmarks[18], landmarks[19])
    };
  }

  motion_z_angle_calculator (landmarks) {
    const topIndex = landmarks[8];
    const normalized = this.calculateDistance3D(landmarks[0],landmarks[5]);
    return [topIndex,normalized]
  }

  motion_j_angle_calculator (landmarks) {
    // Define palm landmarks
    const wrist = landmarks[0];
    const indexBase = landmarks[5];
    const pinkyBase = landmarks[17];

    // Create vectors to define the palm plane
    const palmVector1 = this.subtractVectors(indexBase, wrist);
    const palmVector2 = this.subtractVectors(pinkyBase, wrist);

    // Calculate the normal vector of the palm plane
    const palmNormal = this.crossProduct(palmVector1, palmVector2);

    // Define the vector between landmarks[9] and landmarks[5]
    const vector95 = this.subtractVectors(landmarks[9], landmarks[5]);

    // Project vector95 onto the palm plane
    const projectedVector = this.projectVectorOntoPlane(vector95, palmNormal);

    // Use palmVector1 as the reference vector
    const referenceVector = this.normalizeVector(palmVector1);

    // Calculate the angle between the projected vector and the reference vector
    let angle = this.angleBetweenVectors(projectedVector, referenceVector);

    return angle;
  };
    
  motion_j(fingers, landmarks) {
    if (Object.values(fingers).filter(x => typeof x === 'boolean').every(x => x === true)) {
      this.currentAngle = this.motion_j_angle_calculator(landmarks);
      return this.currentAngle
    }
  }
  
  // Main function supported by above
  isMatchingLetter (letterObj, landmarks) {
      const angles = this.calculateFingerAngles(landmarks);
      let fingers = ['topthumb','thumb', 'index', 'middle', 'ring', 'little'].reduce((result, finger) => {
        const letterAngle = letterObj[finger];
        letterAngle.currentAngle = angles[finger];
        if(letterAngle?.special === 'none'){
          result[finger] = letterAngle.currentAngle >= letterAngle.curlMax && letterAngle.currentAngle <= letterAngle.curlMin;
        }else if(letterAngle?.special === 'distanceBetweenThumbAndPointer'){
          const normalizedValue = this.calculateDistance3D(landmarks[0],landmarks[5]);
          const fingerDistance = this.normalizeDistance3D(landmarks[4],landmarks[letterAngle.fingerTop],normalizedValue);
          result[finger] = fingerDistance > 0.4 && letterAngle.currentAngle >= letterAngle.curlMax && letterAngle.currentAngle <= letterAngle.curlMin;
        }else if(letterAngle?.special === 'pinchThumbAndPointer'){
          const normalizedValue = this.calculateDistance3D(landmarks[0],landmarks[5]);
          const fingerDistance = this.normalizeDistance3D(landmarks[4],landmarks[letterAngle.fingerTop],normalizedValue);
          result[finger] = fingerDistance < 0.2 && letterAngle.currentAngle >= letterAngle.curlMax && letterAngle.currentAngle <= letterAngle.curlMin;
        }else if(letterAngle?.special === 'touchFingertoThumb'){
          const normalizedValue = this.calculateDistance3D(landmarks[0],landmarks[5]);
          const fingerDistance = this.normalizeDistance3D(landmarks[4],landmarks[letterAngle.fingerTop],normalizedValue);
          result[finger] = fingerDistance < 0.4;
        }else if(letterAngle?.special === 'indexBaseBending'){
          result[finger] = this.calculateAngle(landmarks[6],landmarks[5],landmarks[0]) < 140 &&
            letterAngle.currentAngle >= letterAngle.curlMax && 
            letterAngle.currentAngle <= letterAngle.curlMin
        }else if(letterAngle?.special === 'indexToMiddle'){
          const normalizedValue = this.calculateDistance3D(landmarks[0],landmarks[5]);
          const fingerDistance = this.normalizeDistance3D(landmarks[12],landmarks[8],normalizedValue);
          result[finger] = fingerDistance < 0.3 &&
            letterAngle.currentAngle >= letterAngle.curlMax && 
            letterAngle.currentAngle <= letterAngle.curlMin
        }else if(letterAngle?.special === 'distanceBetweenIndexandMiddle'){
          const normalizedValue = this.calculateDistance3D(landmarks[0],landmarks[5]);
          const fingerDistance = this.normalizeDistance3D(landmarks[8],landmarks[12],normalizedValue);
          result[finger] = fingerDistance >= 0.25;
        }else if(letterAngle?.special === 'crossIndexAndMiddle'){
          const normalizedValue = this.calculateDistance3D(landmarks[0],landmarks[5]);
          const upperFingerDistance = this.normalizeDistance3D(landmarks[8],landmarks[12],normalizedValue);
          const lowerFingerDistance = this.normalizeDistance3D(landmarks[7],landmarks[11],normalizedValue);
          result[finger] = upperFingerDistance <= 0.15 || lowerFingerDistance <= 0.15;
        }else{
          result[finger] = letterAngle.currentAngle >= letterAngle.curlMax && letterAngle.currentAngle <= letterAngle.curlMin;
        }
        
        return result;
      }, {});
  
      if (letterObj?.special === 'motion_j') {
        fingers['value'] = this.motion_j(fingers, landmarks);
        return fingers;
      }else if(letterObj?.special === 'motion_z'){
        this.initialAngle = this.motion_z_angle_calculator(landmarks);
        fingers['value'] = this.initialAngle
        return(fingers); 
      }else{
        fingers['readyForNext'] = 'yes';
        return(fingers);
      }
  
      //const handRotations = ['yaw'].reduce((accumulator, rotation) => {
      //  const letterRotation = letterObj[rotation];
      //
      //  const currentRotation = rotations[rotation];
      //  const isWithinRange = currentRotation <= letterRotation.maxValue && 
      //                        currentRotation >= letterRotation.minValue;
      //  return accumulator && isWithinRange;
      //}, true);
  };
}