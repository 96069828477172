// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-container{
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
}

.video-style{
    display: none;
}

.canvas-style{
    z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/components/fingerspelling_match.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".video-container{\n    height: auto;\n    width: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: 1px solid black;\n}\n\n.video-style{\n    display: none;\n}\n\n.canvas-style{\n    z-index: 100;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
