import React, { useState, useEffect, useRef, useCallback } from "react";
import './battleship_game/battleship.css';
import { io } from 'socket.io-client';
import { PersistentString, Board, YourBoard } from './battleship_game/battle_board.js';
import Fingerspelling from './fingerspelling_match.js';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHand } from '@fortawesome/free-solid-svg-icons';

const socket = io(process.env.REACT_APP_WIZARD_OZ, {
  path: '/socket.io',
  transports: ['websocket'],
});

export default function  Battleship() {
  // Game Setup
  const [isDisabled,setDisabled] = useState(false);
  const childButtonRef = useRef(null);
  const handleRandomGenerator = () => {
    childButtonRef.current.click();
  };

  // Overlapped ship detector
  const [isOverlapped, setOverlapped] = useState(false);
  const handleOverlapInfo = useCallback((e) => {
    setOverlapped(e)
  },[])

  // Get array of hidden ships that can be attacked
  const attackSpotsRef = useRef(null);
  const [battleship, setBattleShip] = useState({
    arr: [],
    message: 'Ready to sail—awaiting another player!',
    yourTurn: false,
    person: null,
    room: null,
    people: []
  });

  socket.on('room_assigned', (event) => {
    setBattleShip({...battleship, room: event})
  })

  const handleAttackSpots = useCallback((e) => {
    attackSpotsRef.current.click();
    if(e !== undefined){
      socket.emit('ship_spots', e);
    }
  },[])

  // Launch game when player is ready
  const [isReady, setReady ] = useState({
    IamReady: false,
    EnemyReady: false
  });
  const handleStartGame = useCallback(() => {
    setDisabled(true);
    setReady(prev => ({
      ...prev,
      IamReady: true
    }));
    socket.emit('ReadyToPlay',true);
  },[]);

  socket.on('EnermyReady', e => {
    setReady(prev => ({
      ...prev,
      EnemyReady: e
    }));
  })

  // My/Your Turn function
  const [isConnected, setConnected] = useState(null);
  socket.on('who', (event) => {
    setConnected(event);
  })

  socket.on('turn', (event) => {
    setConnected(event);
  })

  // Socket functions and games
  const userID = useRef(new PersistentString());
  socket.on('socket_id', (event) => {
    userID.current.value = event;
  })

  socket.on('room_sockets', (event) => {
    setBattleShip({
      ...battleship,
      people: event
    })
  })

  const [territory, setTerritory] = useState([]);

  // Highlight the color of attacked
  socket.on('territory', (territory) => {
    setTerritory(territory)
  });

  // Victory function
  const [victory,setVictory] = useState(null);
  socket.on('victory', isWin => { 
    setVictory(isWin);   
  });

  useEffect(() => {
    const { IamReady, EnemyReady } = isReady;
    if(EnemyReady && IamReady){
      attackSpotsRef.current.click();
      handleAttackSpots();
      setBattleShip((prev) => ({
        ...prev,
        message: 'Enemy fleet is in position, await their move!'
      }))
    }else if(EnemyReady){
      setBattleShip((prev) => ({
        ...prev,
        message: 'Enemy fleet is in position, await their move!'
      }))
    }else if(IamReady){
      setBattleShip((prev) => ({
        ...prev,
        message: "Enemy fleet isn't ready, hold on!"
      }))
    }else if(isConnected !== null & victory === null){
      setBattleShip((prev) => ({
        ...prev,
        message: "Connection confirmed, prepare for battle!"
      }))
    }
  },[isReady,isConnected,victory,handleAttackSpots]);

  useEffect(() => {
    const { IamReady, EnemyReady } = isReady;
    if(EnemyReady && IamReady && victory !== null){
      let newState = '';
      if (victory) {
        newState = 'You won the battle!';
      } else {
        newState = 'Battle lost.';
      }
      setBattleShip((prev) => ({
        ...prev,
        message: newState,
        yourTurn: false
      }));
    }else if(EnemyReady && IamReady && isConnected){
      setBattleShip((prev) => ({
        ...prev,
        yourTurn: true,
        message: 'Your turn to fire!'
      }))
    }else if(EnemyReady && IamReady && isConnected !== null){
      setBattleShip((prev) => ({
        ...prev,
        yourTurn: false,
        message: "Enemy's turn. Stay sharp!"
      }))
    }
  },[isConnected,isReady,victory])

  // Restart or Exit game
  const [isGameRestart, setGameRestart] = useState(false);
  useEffect(() => {
    const regex = /won|lost/
    if(regex.test(battleship.message)){
      setGameRestart(true)
    }
  },[battleship])

  const handleGameRestart = () => {
    window.location.reload();
  }

  // Communicate players
  socket.on('getMessage', (e) => {
    setBattleShip((prev) => ({
      ...prev,
      message: e.message
    }));
  })

  const informOtherPlayer = (e) => {
    socket.emit('message', e);
  }

  // When one player left
  const [isClicked,setClicked] = useState(false);
  const [message,setMessage] = useState('');
  const handleClickedArr = (e) => {
    if(e !== null){
      setClicked(true);
    }
  };

  socket.on('PlayerLeft', () => {
    if(isClicked){
      setMessage('Enemy surrendered!');
    }else{
      setMessage('Enemy offline!');
    }
    setGameRestart(true);
  })

  // Waiting List
  const [timeLimit,setTimeLimit] = useState(0);
  const [time, setTime] = useState(60);
  const [waiting, setWaiting] = useState(false);
  socket.on('waiting_list', e => {
    setTimeLimit(e.timeout);
    setWaiting(true);
  });

  socket.on('refresh_for_waiting', () => {
    window.location.reload();
  })

  useEffect(() => {
    if (time > 0 & timeLimit > 0) {
      const timerId = setTimeout(() => {
        setTime(time - 1);
      }, 1000);

      setBattleShip((prev) => ({
        ...prev,
        yourTurn: false,
        message: "You are on standby. Remaining time: "+time
      }));

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timerId);
    } else if(waiting){
      setBattleShip((prev) => ({
        ...prev,
        yourTurn: false,
        message: 'The countdown has ended. Try again later to reconnect.'
      }));
    }
  }, [time, timeLimit, waiting]);

  // Server is too busy, sorry.
  socket.on('server_full', () => {
    setBattleShip((prev) => ({
      ...prev,
      yourTurn: false,
      message: "The server is in the middle of a sea battle. Try again shortly!"
    }));
  });

  // Check if svg paths belong to battleship.message
  const surrendered = /surrendered/
  const offline = /offline/

  // Open the fingerspelling menu with extra Turn
  const [accessFingerspell,setAccessFingerspell] = useState(false);
  const handleFingerspellingAccess = (e) => {
    if(e != null){
      setAccessFingerspell(e);
    }
  };


  const [openFingerSpelling, setOpenFingerSpelling] = useState(false);
  const openFingerspellingMenu = (e) => {
    setOpenFingerSpelling(e);
  }

  useEffect(() => {
    setAccessFingerspell(false);
  },[openFingerSpelling]);

  return(
    <div style = {{position: 'relative', height: '100%', width: '100%', overflow: 'hidden'}}>
      <Fingerspelling 
        getOpenCloseMenu={openFingerspellingMenu} 
        isOpen={openFingerSpelling}
        socket={socket}
      />
      <div className = 'battleship-background'>
        <div className = 'restart-game' disabled = {!isGameRestart}>
          <div>
            <h2>{offline.test(message) | surrendered.test(message) ? message : battleship.message}</h2>
            <svg viewBox="2.7 -5.5 4.5 6.5" className='battleship-icon'>
            {
              offline.test(message) ?
              <>
                <path
                  fill = 'var(--whitebackground)'
                  stroke="var(--darkergray)"
                  strokeWidth='0.3px'
                  id = 'plug'
                  d='M 3 -3 L 5 -1 A 0.1 0.1 0 0 1 3 -3 Z M 3 -1 L 2 0 L 3 -1 Z M 3.5 -2.5 L 4.1 -3.1 L 3.5 -2.5 Z M 4.5 -1.5 L 5.1 -2.1 L 4.5 -1.5 Z'
                />
                <path
                  fill = 'var(--whitebackground)'
                  stroke="var(--darkergray)"
                  strokeWidth='0.3px'
                  d='M 4 -4 L 6 -2 A 0.1 0.1 0 0 0 4 -4 Z M 6 -4 L 7 -5 L 6 -4 Z'
                />
                <path
                  fill = 'var(--whitebackground)'
                  stroke="var(--darkergray)"
                  strokeWidth='0.3px'
                  id='crossed-symbol'
                  d='M 7 -3 L 8 -4 Z M 7 -4 L 8 -3 Z'
                />
              </> : surrendered.test(message) ?
              <>
                <path
                  fill = 'var(--whitebackground)'
                  stroke="var(--darkergray)"
                  strokeWidth='0.3px'
                >
                  <animate 
                    attributeName="d" 
                    dur="0.8s"
                    repeatCount="indefinite"
                    values="
                      M 7.2 -3.85 Q 8 -3.6 8.9 -3.85 L 8.95 -2.8 Q 8 -2.6 7.2 -2.8 Z M 7.2 -3.9 L 7.2 -1.5 Z;
                      M 7.2 -3.85 Q 8 -4 9 -3.85 L 9 -2.8 Q 8 -3 7.2 -2.8 Z M 7.2 -3.9 L 7.2 -1.5 Z;
                      M 7.2 -3.85 Q 8 -3.6 8.9 -3.85 L 8.95 -2.8 Q 8 -2.6 7.2 -2.8 Z M 7.2 -3.9 L 7.2 -1.5 Z
                    "
                    calcMode="spline"
                    keySplines="0.5 0 0.5 1; 0.5 0 0.5 1"
                  />
                </path>
                <path
                  fill = 'var(--whitebackground)'
                  stroke="var(--darkergray)"
                  strokeWidth='0.3px'
                  d='M 2 0.2 L 7.7 0.2 L 9.5 -1.5 L 8.6 -1.5 L 5.5 -1.5 L 4.6 -1 L 2.9 -1 A 0.1 0.1 0 0 0 1.9 -1 L 1 -1 Z M 3.6 -1 L 3.6 -3 L 6.4 -3 L 6.4 -1.5 L 5.5 -1.5 L 4.6 -1 Z M 4.2 -3 L 4.2 -4 L 5.8 -4 L 5.8 -3 Z M 5 -4 L 5 -5.2 L 5 -4 Z M 1.9 -1 L 2.9 -1 Z M 2 -1.3 L 1 -1.9 L 2 -1.3 Z M 3.5 -2.25 L 6.5 -2.25 M 5 -3.5 L 5.4 -3.5 L 5 -3.5 Z'
                />
                <path
                  fill = 'var(--whitebackground)'
                  stroke="var(--darkergray)"
                  strokeWidth='0'
                  id='wavy-motion'
                  d='M 0 0 A 1 1 0 0 1 1 0 A 1 1 0 0 0 2 0 A 1 1 0 0 1 3 0 A 1 1 0 0 0 4 0 A 1 1 0 0 1 5 0 A 1 1 0 0 0 6 0 A 1 1 0 0 1 7 0 A 1 1 0 0 0 8 0 A 1 1 0 0 1 9 0 A 1 1 0 0 0 10 0 L 10 3 L 0 3 Z'
                />
                <path
                  fill = 'var(--whitebackground)'
                  stroke="var(--darkergray)"
                  strokeWidth='0.45px'
                  id='wavy-motion'
                  d='M 0 0 A 1 1 0 0 1 1 0 A 1 1 0 0 0 2 0 A 1 1 0 0 1 3 0 A 1 1 0 0 0 4 0 A 1 1 0 0 1 5 0 A 1 1 0 0 0 6 0 A 1 1 0 0 1 7 0 A 1 1 0 0 0 8 0 A 1 1 0 0 1 9 0 A 1 1 0 0 0 10 0 A 1 1 0 0 1 9 0 A 1 1 0 0 0 8 0 A 1 1 0 0 1 7 0 A 1 1 0 0 0 6 0 A 1 1 0 0 1 5 0 A 1 1 0 0 0 4 0 A 1 1 0 0 1 3 0 A 1 1 0 0 0 2 0 A 1 1 0 0 1 1 0 A 1 1 0 0 0 0 0 Z'
                />
              </> :
              <>
                <path
                  fill = 'var(--whitebackground)'
                  stroke="var(--darkergray)"
                  strokeWidth='0.3px'
                  disabled={battleship.message.includes('Battle lost.')}
                  id='sunk-ship'
                  d='M 2 0.2 L 7.7 0.2 L 9.5 -1.5 L 8.6 -1.5 A 0.1 0.1 0 0 0 7.6 -1.5 L 5.5 -1.5 L 4.6 -1 L 2.9 -1 A 0.1 0.1 0 0 0 1.9 -1 L 1 -1 Z M 3.6 -1 L 3.6 -3 L 6.4 -3 L 6.4 -1.5 L 5.5 -1.5 L 4.6 -1 Z M 4.2 -3 L 4.2 -4 L 5.8 -4 L 5.8 -3 Z M 5 -4 L 5 -5.2 L 5 -4 Z M 1.9 -1 L 2.9 -1 Z M 7.6 -1.5 L 8.6 -1.5 Z M 2 -1.3 L 1 -1.9 L 2 -1.3 Z M 8.5 -1.8 L 9.5 -2.4 L 8.5 -1.8 Z M 3.5 -2.25 L 6.5 -2.25 M 5 -3.5 L 5.4 -3.5 L 5 -3.5 Z M 5.5 -0.7 L 8.5 -0.7 Z'
                />
                <path
                  fill = 'var(--whitebackground)'
                  stroke="var(--darkergray)"
                  strokeWidth='0'
                  id='wavy-motion'
                  d='M 0 0 A 1 1 0 0 1 1 0 A 1 1 0 0 0 2 0 A 1 1 0 0 1 3 0 A 1 1 0 0 0 4 0 A 1 1 0 0 1 5 0 A 1 1 0 0 0 6 0 A 1 1 0 0 1 7 0 A 1 1 0 0 0 8 0 A 1 1 0 0 1 9 0 A 1 1 0 0 0 10 0 L 10 3 L 0 3 Z'
                />
                <path
                  fill = 'var(--whitebackground)'
                  stroke="var(--darkergray)"
                  strokeWidth='0.45px'
                  id='wavy-motion'
                  d='M 0 0 A 1 1 0 0 1 1 0 A 1 1 0 0 0 2 0 A 1 1 0 0 1 3 0 A 1 1 0 0 0 4 0 A 1 1 0 0 1 5 0 A 1 1 0 0 0 6 0 A 1 1 0 0 1 7 0 A 1 1 0 0 0 8 0 A 1 1 0 0 1 9 0 A 1 1 0 0 0 10 0 A 1 1 0 0 1 9 0 A 1 1 0 0 0 8 0 A 1 1 0 0 1 7 0 A 1 1 0 0 0 6 0 A 1 1 0 0 1 5 0 A 1 1 0 0 0 4 0 A 1 1 0 0 1 3 0 A 1 1 0 0 0 2 0 A 1 1 0 0 1 1 0 A 1 1 0 0 0 0 0 Z'
                />
              </>
            }
            </svg>
            <button 
              className = 'restart-button' 
              id = 'restart'
              onClick = {handleGameRestart}
            >
              Restart
            </button>
            <Link
              className = 'restart-button' 
              id = 'dock'
              to='/' 
              aria-label='Dock page' 
              role='button'
            >
              Dock
            </Link>
          </div>
        </div>
        <div className = 'battleship-container' onContextMenu={(e)=>e.preventDefault()}>
          <div id = 'battleship-title'>
            {battleship.message}
            <button id='fingerspelling-button' disabled={!accessFingerspell} onClick={() => [openFingerspellingMenu(true),informOtherPlayer({message: 'Enemy engages for extra strike.'})]}><FontAwesomeIcon icon={faHand} id='hand-button'/><div id='not-allow-click'/></button>
          </div>
          <div className = 'battleship-container-wrap'>
            <div>        
              <h1
                style={{borderBottom: isDisabled ? '5px solid  var(--gameBackground)' : '5px solid transparent'}}
                className='grid-boxes'
              >
                OPPONENT
              </h1>
              <Board 
                yourTurn = {battleship.yourTurn && !accessFingerspell}
                isDisabled={isDisabled}
                userID = {userID.current.value}
                getClickedArr = {handleClickedArr}
                getFingerspellAccess={handleFingerspellingAccess}
                socket={socket}
              />
            </div>
            <div id = 'separator'/>
            <div>
              <h1
                style={{borderBottom: !isDisabled ? '5px solid  var(--gameBackground)' : '5px solid transparent'}}
                className='grid-boxes'
              >
                YOUR FLEET
              </h1>
              <YourBoard 
                territory = {territory} 
                isOverlapped = {handleOverlapInfo}
                isDisabled={isDisabled}
                getAttackSpots={handleAttackSpots}
                ref={{childButtonRef,attackSpotsRef}}
              />
            </div>
          </div>
          <br/>
          <button 
            className = 'battle-button' 
            id = 'shuffle' 
            disabled={isDisabled} 
            aria-disabled={isDisabled}
            onClick={handleRandomGenerator}
            style={{cursor: isDisabled ? 'not-allowed' : 'pointer'}}
          >
            SHIP SHUFFLE
          </button>
          <button 
            className = 'battle-button' 
            id = 'battle' disabled={isOverlapped || isDisabled || isConnected === null} 
            aria-disabled={isOverlapped || isDisabled || isConnected === null} 
            onClick={handleStartGame}
            style={{cursor: isOverlapped || isDisabled ? 'not-allowed' : 'pointer'}}
          >
            LET'S BATTLE!
          </button>
        </div>
      </div>
    </div>
  )
};