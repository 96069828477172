import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import videoSVG from './assets/video-timer.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faShuffle, faRotateRight } from '@fortawesome/free-solid-svg-icons';

// All tools for Convolutional Neural Network
import Webcam from 'react-webcam';
import {Camera} from "@mediapipe/camera_utils";
import { HAND_CONNECTIONS, Holistic } from '@mediapipe/holistic';
import {drawConnectors, drawLandmarks} from '@mediapipe/drawing_utils';

// Tour
import Joyride from 'react-joyride';
import { styled, keyframes } from 'styled-components';

// Get JSON of English for users to translate
import getTexts from './assets/EnglishToTranslate.json';

// The react-record-webcam is NOT developed by Jonah Winninghoff. It is cloned from https://github.com/samuelweckstrom/react-record-webcam
// Some refactors are made in these files due to ENOENT errors (not found directories).
import { useRecordWebcam } from './react-record-webcam/index.js';

// Function to create subdirectory for bundling videos, voice, and text
import randomstring from 'randomstring';
import sha256 from 'crypto-js/sha256.js';

// Check if users already toured and stylize tour
const isTour = localStorage.getItem('tour');

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 #ff0000;
  }

  100% {
    box-shadow: 0 0 0 14px #ff000000;
  }
`;

const Beacon = styled.span`
  animation: ${pulse} 1s ease-in-out infinite;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

function createDirectory() {
  const randomStr = randomstring.generate(8); // Generate a random string with 8 characters
  const hash = sha256(randomStr);
  return randomStr + hash;
}

export default function RecordView() {
  // Choose your parameter for video timer
  const parameter = 1000*60 // I pick 30 seconds

  // Webcam Record components
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const {
    activeRecordings,
    createRecording,
    openCamera,
    startRecording,
    stopRecording,
    getRecording,
    permissionError
  } = useRecordWebcam();

  // Tour Function
  const steps = [
    {
      target: '.gally-video-container',
      content: "Let's start our tour.",
      disableBeacon: true
    },
    {
      target: '.gally-video',
      content: "Your video trains AI."
    },
    {
      target: '.translate-text-to-asl',
      content: "Translate this word into ASL."
    },
    {
      target: '.count-up-b',
      content: "Repeat to sign for 10 times."
    },
    {
      target: '#shuffle-text',
      content: "Click for word change."
    },
    {
      target: '#upload-video',
      content: 'Once finished, upload video.'
    },
    {
      target: '.gally-video-container',
      content: 'Upload as many videos as you want. More data, better AI.'
    }
  ]

  // Open camera function
  const [isCameraOpen, setCameraOn] = useState('');
  const [isRecording, setRecording] = useState('No');
  const [waitCamera, setWaitCamera] = useState('fake-button-symbol');
  const [textCamera, setTextCamera] = useState('Turn camera on');
  const [textSpacePress, setTextSpacePress] = useState('[Space Press]');
  const [startTour, setStartTour] = useState('false')
  
  const start = async () => {
    if(isCameraOpen !== 'Yes'){
      setWaitCamera('waiting-for-camera');
      setTextCamera('Camera igniting...');
      setTextSpacePress('');
      const recording = await createRecording();
      if (recording) await openCamera(recording.id);
      if (recording) setCameraOn('Yes');
      setTimeout(() => {
        setStartTour('true')
      },[200])
    }
  };

  // Add Convolutional Neural Network detectors
  const [see_hands, setSeeHands] = useState(false);
  const [countupforunseen, setCountUpForUnseen] = useState(0);
  const [countupforseen, setCountUpForseen] = useState(0);
  const [countupforsigns, setCountUpForSigns] = useState(0); 
  const [cnnLoaded, setCNN_Load] = useState(false);
  const [thedate,setTheDate] = useState(new Date());
  const [timeStarters,setTimeStarters] = useState([]);
  const [timeStoppers,setTimeStoppers] = useState([]);

  const onResults = (results) => {
    if (!webcamRef.current?.video || !canvasRef.current) return
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");
    if (canvasCtx == null) throw new Error('Could not get context');
    canvasCtx.save();
    canvasCtx.scale(-1,1);
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    // Only overwrite existing pixels.
    canvasCtx.globalCompositeOperation = 'source-in';
    canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

    // Only overwrite missing pixels.
    canvasCtx.globalCompositeOperation = 'destination-atop';
    canvasCtx.drawImage(
      results.image, 0, 0, canvasElement.width, canvasElement.height);
    
    canvasCtx.fillStyle = '#fffdfdce';
    canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

    canvasCtx.globalCompositeOperation = 'source-over';
    drawConnectors(canvasCtx, results.leftHandLandmarks, HAND_CONNECTIONS,
      {color: '#EFCA77', lineWidth: 1.5});
    drawLandmarks(canvasCtx, results.leftHandLandmarks,
      {color: '#0F4D81', radius: 1});
    drawConnectors(canvasCtx, results.rightHandLandmarks, HAND_CONNECTIONS,
      {color: '#EFCA77', lineWidth: 1.5});
    drawLandmarks(canvasCtx, results.rightHandLandmarks,
      {color: '#0F4D81', radius: 1});

    canvasCtx.restore();
    if(typeof results.rightHandLandmarks !== "undefined" | typeof results.leftHandLandmarks !== "undefined"){
      setCountUpForseen(prev => prev + 1);
      setCountUpForUnseen(0);
    }else{
      setCountUpForseen(0);
      setCountUpForUnseen(prev => prev + 1);
    }

    setCNN_Load(true);
  }
  
  useEffect(() => {
    if(isCameraOpen === 'Yes'){
      const holistic = new Holistic({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`;
        }
      });
      
      holistic.setOptions({
        selfieMode: true,
        modelComplexity: 1,
        smoothLandmarks: true,
        enableSegmentation: true,
        smoothSegmentation: true,
        refineFaceLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
      });
      holistic.onResults(onResults);
  
      if (
        typeof webcamRef.current !== "undefined" &&
        webcamRef.current !== null
      ) {
        if (!webcamRef.current?.video) return
        const camera = new Camera(webcamRef.current.video, {
          onFrame: async () => {
            if (!webcamRef.current?.video) return
            await holistic.send({image: webcamRef.current.video});
          },
        });
        camera.start();
      }
    }
  }, [isCameraOpen]);

  useEffect(() => {
    if(countupforseen > 1 & !see_hands & countupforseen){
      setSeeHands(true);
      setTimeStarters(prevArray => prevArray.length === 10 ? prevArray : [...prevArray, new Date() - thedate]);
    }else if(countupforunseen > 5 & see_hands){
      setSeeHands(false);
      setCountUpForSigns(prev => prev === 10 ? 10 : prev + 1);
      setTimeStoppers(prevArray => prevArray.length === 10 ? prevArray : [...prevArray, new Date() - thedate]);
    }
  },[countupforseen,countupforunseen,see_hands,thedate]);

  useEffect(() => {
    if(countupforsigns === 10 & isRecording === 'Yes'){
      playref.current.click();
    }
  },[countupforsigns,isRecording])
  
  // Timer
  const Ref = useRef(null);
  const [timer, setTimer] = useState("01:00");
  const [timerRecording, setTimerRecording] = useState('gally-video-svg-timer-img')

  const getTimeRemaining = (e) => {
    const total =
        Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor(
        (total / 1000 / 60) % 60
    );
    return {
        total,
        minutes,
        seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } =
        getTimeRemaining(e);
    if (total >= 0) {
        // update the timer
        // check if less than 10 then we need to
        // add '0' at the beginning of the variable
        setTimer(
            (minutes > 9
                ? minutes
                : "0" + minutes) +
            ":" +
            (seconds > 9 ? seconds : "0" + seconds)
        );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("01:00");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
        startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  // Count Down Record
  const countDownRef = useRef(null);
  const [countDownTimer, setCountDownTimer] = useState("3");

  const countDownTimerFunction = (e) => {
    let { total, seconds } =
        getTimeRemaining(e);
    if (total >= 1) {
        // update the timer
        // check if less than 10 then we need to
        // add '0' at the beginning of the variable
        setCountDownTimer(
          (seconds > 9 ? 'wrong!' : seconds)
        );
    }
  };

  const clearMyTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setCountDownTimer("3");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (countDownRef.current) clearInterval(countDownRef.current);
    const id = setInterval(() => {
      countDownTimerFunction(e);
    }, 1000);
    countDownRef.current = id;
  };

  const countDown = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 3);
    return deadline;
  };

  // Recording
  const [noSubmitTwice, setPreventSubmitTwice] = useState('not submitted');
  const [successfulSubmit, setSuccessfulSubmit] = useState('');
  const [waitingForUpload, setWaitingForUpload] = useState('notification-that-submission-is');
  const [beginCountDown, setBeginCountDown] = useState('false');
  const [switchVideo, setSwitchVideo] = useState('real-time');
  const [timeoutID, setTimeoutID] = useState(null);
  const [buttonLabel,setButtonLabel] = useState('Click me to start the video recording.');

  const myRecording = (e) => {
    const cancel = () => {
      setTimeStarters([]);
      setTimeStoppers([]);
      clearTimeout(timeoutID);
      setTimeoutID(null);
      setBeginCountDown('false');
      setButtonLabel('Click me to start the video recording.');
    };

    setSuccessfulSubmit('');
    setWaitingForUpload('notification-that-submission-is');
    if (beginCountDown === "true") {
      cancel();
    }else if(e.status === 'OPEN'){
      setBeginCountDown('true');
      clearMyTimer(countDown());
      setSwitchVideo('real-time');
      setButtonLabel('Click me to stop before the recording starts. Remaining time countdown: ');
      setCountUpForSigns(0);
      setTimeStarters([]);
      setTimeStoppers([]);
      const startTime = setTimeout(() => {
        setCountUpForSigns(0);
        setTimeStarters([]);
        setTimeStoppers([]);
        setTheDate(new Date());
        setBeginCountDown('false');
        setRecording('Yes');
        startRecording(e.id);
        clearTimer(getDeadTime());
        setTimerRecording('gally-video-svg-timer-img-recording');
        setButtonLabel('Click me to end the recording. Otherwise, the remaining time countdown for recording: ');
      },[3000]);

      setTimeoutID(startTime);

    }else if(e.status === 'RECORDING'){
      clearMyTimer();
      let currentTime = new Date();
      localStorage.setItem('timestamp',JSON.stringify({'timestamp': currentTime, 'Ngram': getTexts.EnglishToTranslate[getShuffle]}));
      setRecording('No');
      stopRecording(e.id);
      setPreventSubmitTwice('not submitted')
      clearTimer();
      setTimerRecording('gally-video-svg-timer-img');
      setSwitchVideo('auto-recording');
      setButtonLabel("If you want to upload, don't click me. \nIf you want to redo this recording, click me.")
    }else if(e.status === 'STOPPED'){
      setBeginCountDown('true');
      clearMyTimer(countDown());
      setSwitchVideo('real-time');
      setButtonLabel('Click me to stop before the recording starts. Remaining time countdown: ');
      setCountUpForSigns(0);
      setTimeStarters([]);
      setTimeStoppers([]);
      const restartTime = setTimeout(() => {
        setCountUpForSigns(0);
        setTimeStarters([]);
        setTimeStoppers([]);
        setTheDate(new Date());
        setBeginCountDown('false');
        setRecording('Yes');
        startRecording(e.id);
        clearTimer(getDeadTime());
        setTimerRecording('gally-video-svg-timer-img-recording');
        setButtonLabel('Click me to end the recording. Otherwise, the remaining time countdown for recording: ');
      },[3000]);

      setTimeoutID(restartTime);
    }
  };

  // Shuffle texts for user to select]
  const [getShuffle, setShuffle] = useState(Math.floor(Math.random()*getTexts.EnglishToTranslate.length));

  const shuffleTexts = (recordingStatus) => {
    if(recordingStatus !== 'RECORDING'){
      let i = 0;
      let value = 0;
      while(i === 0){
        value = Math.floor(Math.random()*getTexts.EnglishToTranslate.length);
        if(value !== getShuffle){
          i = 1;
          setShuffle(value);
        };
      };
    }
  };

  // Get localStorage objects merged into one object
  const mergeObject = (starters, stoppers) => {
    const item1 = JSON.parse(localStorage.getItem('user'));
    const item2 = JSON.parse(localStorage.getItem('geolocation'));
    const item3 = JSON.parse(localStorage.getItem('useTouchDevice'));
    const item4 = JSON.parse(localStorage.getItem('demographics'));
    const item5 = JSON.parse(localStorage.getItem('timestamp'));

    const mergedObject = {
      ...item1,
      ...item2,
      ...item3,
      ...item4,
      ...item5,
      starters,
      stoppers
    };
    return JSON.stringify(mergedObject);
  }
  
  // Upload Video
  const saveFile = async (e,recording) => {
    e.preventDefault();
    localStorage.setItem('tour',false);

    if(recording.status === 'STOPPED' & noSubmitTwice === 'not submitted' & countupforsigns === 10){
      setPreventSubmitTwice('submitted');
      setWaitingForUpload('notification-that-submission-is-successful');
      const folderName = createDirectory();
      const this_recording = getRecording(recording.id);
  
      const blob = await fetch(this_recording.objectURL)
        .then(r => r.blob())
  
      var file = new File([blob], folderName+' video.webm', {type: 'video/webm', lastModified: Date.now()});
      const form = new FormData();
      form.append('video',file);
  
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/upload-video`,
        form,
        {
          headers: {'Content-Type': 'multipart/form-data'}
        }
      );
    
      const form1 = new FormData();
      const object = mergeObject(timeStarters,timeStoppers);
      console.log(object)
      var file1 = new File([object], folderName+' data.json', {type: "text/json;charset=utf-8", lastModified: Date.now()});
      form1.append('json',file1);
  
      const result1 = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/upload-json`,
        form1,
      )
  
      if(result.status === 200 & result1.status === 200){
        setSuccessfulSubmit('successful');
        shuffleTexts(recording.status);
        setTimeout(() => {
          setSwitchVideo('real-time');
        }, 1000);
        setTimeout(() => {
          setWaitingForUpload('notification-that-submission-is');
        }, 2000);
        setButtonLabel('Upload is successful. Click me to start another video recording.');
      }
    }else{
      console.log('You did not submit anything.')
    }
  };

  // Key Press Detect
  const playref = useRef(null); // For starting and stopping recording
  
  useEffect(() => {
    const startSpacePress = async () => {
      if(isCameraOpen !== 'Yes'){
        setWaitCamera('waiting-for-camera');
        setTextCamera('Camera igniting...');
        setTextSpacePress('');
        const recording = await createRecording();
        if (recording) await openCamera(recording.id);
        if (recording) setCameraOn('Yes');
      }else if(isCameraOpen === 'Yes'){
        playref.current.click();
      }
    };

    const keyDownHandler = event => {
      if (event.key === ' ') {
        event.preventDefault();
        // 👇️ your logic here
        startSpacePress()
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isCameraOpen,isRecording,openCamera,createRecording]);

  useEffect(() => {
      if(isRecording === 'Yes'){
        const timer = setTimeout(() => {
          playref.current.click();
          setRecording('No');
        }, parameter); //miliseconds
        return () => clearTimeout(timer)
      }
  }, [isRecording,parameter]);

 return (
  <div className = 'gally-background'>
    <div className = 'gally-container-for-video'>
      <p/>
      {
        (() => {
            if(isCameraOpen === 'Yes' & permissionError === null){
              return(
                <div/>
              )
            }else if(permissionError !== null){
              return(
                <>
                  <main>
                    <h1>
                      Shutter stuck in solitude.
                    </h1>
                  </main>
                  <p/>
                  <div className = 'ask-permission-denied'>
                    <div className = 'gally-text-container'>
                      <main className = 'camera-is-blocked'>Camera is blocked</main>
                      <section className = 'camera-is-blocked-section'>The blocked camera may be due to no webcam, accidentally denied access, or another app or tab using the camera.</section>
                    </div>
                    <div className = 'permission-denied'>
                      <svg viewBox = '-1 0 14 10' className = 'permission-denied-icon'>
                        <path d = 'M 0 0 L 3 0 L 4 3 L 2 4 L 5 7 L 4 5 L 6 3 L 5 2 L 5 0 L 9 0 C 9 0 10 0 10 1 L 10 1 L 10 3 L 12 1 C 13 0.5 13 0.5 13 1 L 13 9 C 13 9.5 13 9.5 12 9 L 12 9 L 10 7 L 10 9 C 10 9 10 10 9 10 L 0 10 C 0 10 -1 10 -1 9 L -1 1 C -1 1 -1 0 0 0 Z'/>
                      </svg>
                      <p/>
                    </div>
                  </div>
                </>
              )
            }else{
              return(
                <>
                  <main>
                    <h1>
                      {textCamera}
                    </h1>
                  </main>
                  <p/>
                  <div onClick = {start} 
                    className = 'ask-permission-for-video' 
                    aria-label = 'This button asks you to turn camera on.' 
                    role = 'button'
                    tabIndex='0'
                    title = 'This button contains a blue background with a yellow circle on it, and there is a blue camera icon in the yellow circle. Below this yellow circle, there is a text saying, "[Space Press]" to turn camera on.'
                    alt=""
                  >
                    <div className = 'gally-flex'>
                      <div className = 'gally-row'>
                        <p/>
                        <div 
                          className = {waitCamera} 
                          aria-hidden = "true"
                        >
                          <svg viewBox = '-1 0 14 10' className = 'camera-symbol'>
                            <path d = 'M 0 0 L 9 0 C 9 0 10 0 10 1 L 10 1 L 10 3 L 12 1 C 13 0.5 13 0.5 13 1 L 13 9 C 13 9.5 13 9.5 12 9 L 12 9 L 10 7 L 10 9 C 10 9 10 10 9 10 L 0 10 C 0 10 -1 10 -1 9 L -1 1 C -1 1 -1 0 0 0 Z'/>
                          </svg>
                        </div>
                        <p/>
                        <p/>
                        <label className = 'light-coding-text' id = 'gallyspacepress01'>
                          {textSpacePress}
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )
            }
        })()
      }
      {activeRecordings.map(recording => (
        <>
          <Joyride
            steps = { steps }
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            run = {isTour === 'false' | startTour === 'false' ? false : true}
            styles={{
              options: {
                arrowColor: '#ffffff',
                backgroundColor: '#ffffff',
                beaconSize: 50,
                overlayColor: '#00000083',
                primaryColor: '#0F4D81',
                textColor: '#004a14',
                width: 900,
                zIndex: 1000,
              }
            }}
            beaconComponent={Beacon}
          />
          <div className="devices">
            <div className="device" key = {recording.id}>
              <div className = 'gally-video-container'>
                <div className = 'gally-video'>
                  <div className="gally-count-down"
                    aria-hidden = 'true'
                    disabled = { beginCountDown === 'true' }
                  >
                    <div className='gally-valign'
                      disabled = { beginCountDown === 'true' }
                    >
                      {countDownTimer}
                    </div>
                  </div>
                  <div className = {waitingForUpload}
                    disabled = {successfulSubmit === 'successful'}
                  >
                    {successfulSubmit === 'successful' ? 
                      (
                        <div className="success-checkmark"
                        disabled = {successfulSubmit === 'successful'}
                        >
                          <div className="check-icon"
                            disabled = {successfulSubmit === 'successful'}
                          >
                            <span className="icon-line line-tip"></span>
                            <span className="icon-line line-long"></span>
                            <div className="icon-circle"></div>
                            <div className="icon-fix"></div>
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          <FontAwesomeIcon icon = {faCloudArrowUp} className='uploading-waiting'/>
                        </div>
                      )
                    }
                  </div>
                  <Webcam className = 'gally-video' ref={webcamRef}
                  style = {{display: 'none' }}/>
                  <div className = 'canvas-background'>
                    <div className = 'gally-flex'>
                      <div className = 'gally-row'>
                        <p/>
                        <div 
                          className = 'waiting-for-camera' 
                          aria-hidden = "true"
                        >
                          <svg viewBox="-3 -5.2 24 15.2" className = 'camera-symbol' aria-disabled = {cnnLoaded}>
                            <path d="M 2.5 5 a 2.5 2.5 0 1 1 5 0 a 2.5 2.5 0 1 1 -5 0 M 10.5 5 a 2.5 2.5 0 1 1 5 0 a 2.5 2.5 0 1 1 -5 0 
                              M 17 10 Q 20 10 20 7 L 20 4 Q 20 1 17 1 L 1 1 Q -2 1 -2 4 L -2 7 Q -2 10 1 10 Z
                              M 5 0.8 Q 5 0 6 0 L 12 0 Q 13 0 13 0.8 Z
                              M -2.2 4 Q -3 4 -3 4.5 L -3 6.5 Q -3 7 -2.2 7 Z
                              M 20.2 4 Q 21 4 21 4.5 L 21 6.5 Q 21 7 20.2 7 Z
                              M 9.2 -0.2 L 9.2 -4 L 9.8 -4.5 L 9 -5.2 L 8.2 -4.5 L 8.8 -4 L 8.8 -0.2 Z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <video ref={recording.previewRef} className = 'gally-video' loop autoPlay playsInline muted 
                    style = {{display: switchVideo === 'real-time' ? 'none' : 'unset' }}
                    title='This is the video that you already recorded, and it is being played and replayed in 1 minute or less.'
                  />
                  <video ref={recording.webcamRef} className = 'gally-video' loop autoPlay playsInline muted
                    style = {{display: 'none' }}
                  />
                  <canvas className = 'gally-canvas' ref = {canvasRef}
                  style = {{display: switchVideo === 'real-time' ? 'unset' : 'none'}}/>
                </div>                      
                <div className= 'gally-controls'>
                  <div className = 'gally-white-background-for-control'/>
                  <section className = 'translate-text-to-asl'>
                    {countupforsigns > 0 & recording.status === 'RECORDING' ? "Repeat this sign - " : "Sign to say "}<b>"{getTexts.EnglishToTranslate[getShuffle]}"</b>: <b className = "count-up-b">{recording.status === 'RECORDING' ? countupforsigns : 0}/10</b>
                  </section>
                  <button className = 'video-submit' 
                    id = 'upload-video'
                    aria-disabled={recording.status !== 'STOPPED' | noSubmitTwice !== 'not submitted' | countupforsigns !== 10 ? 'true' : 'false'}
                    aria-live = 'polite'
                    onClick={(e) => [saveFile(e,recording)]}
                    tabIndex = '0'
                    title = {recording.status !== 'STOPPED' | noSubmitTwice !== 'not submitted' ? "The video can't upload right now." : "The video can upload right now."}
                  >
                    <FontAwesomeIcon icon = {faCloudArrowUp} className = 'video-submit-icon'/>
                  </button>
                  <button className='play-stop-button'                 
                    onClick={() => myRecording(recording)}
                    ref={playref}
                    tabIndex = '0'
                    aria-live = 'polite'
                    aria-relevant='additions'
                    title = {buttonLabel+(beginCountDown === 'true' ? countDownTimer : '')+(isRecording === 'Yes' ? timer : '')}
                  >
                    <div className = 'play-stop-button-center'
                      disabled={
                        recording.status === "STOPPED" & noSubmitTwice === 'not submitted'
                      }
                    />
                    <FontAwesomeIcon 
                      icon = {faRotateRight} 
                      className = 'play-stop-button-redo'
                      disabled={
                        recording.status === "STOPPED" & noSubmitTwice === 'not submitted'
                      }
                    >
                    </FontAwesomeIcon>
                  </button>
                  <button className = 'video-submit' 
                    id = 'shuffle-text'
                    disabled={recording.status === 'RECORDING'} 
                    onClick={() => shuffleTexts(recording.status)}
                    tabIndex = '0'
                    title = {'You can change option for translation. Current: '+getTexts.EnglishToTranslate[getShuffle]}
                  >
                    <FontAwesomeIcon icon = {faShuffle} className = 'video-submit-icon'/>
                  </button>
                  <div className = 'gally-video-timer'>
                  <div className = 'gally-video-timer-flex'>
                    <div className = 'gally-video-svg-timer'>
                      <img src={videoSVG} alt = 'video-timer-symbol' className = {timerRecording}/>
                    </div>
                    <div className = 'gally-video-text-timer'>
                      <div className = 'gally-video-text-timer-color'>
                        {timer}
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}
      <Link 
        className = 'gally-left-arrow-button1' 
        to='/' 
        aria-label='Home page' 
        role='button'
      >
        Back
      </Link>
    </div>
  </div>
 );
}